import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {loadNextBookings} from '../../../store/bookingsOverview/bookings';
import {enableCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import {loadDashboardData} from '../../../store/dashboard';
import {bookTeakEntity} from '../../../api';
import {CARD} from '../../../constants/cards';
import {ROUTES} from '../../../constants/routes';
import {TEAK_ENTITY_NAMES} from './../../../constants/teakEntityNames';

function PaymentRedirect() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const data = useSelector((state) => state.cards.data);
	const {startTime, endTime, amount, paymentIntent, entityData} = data;
	const entityId = entityData?._id;
	const name = entityData?.name;
	const meetingName = useSelector((state) => state.entitiesBooking.meetingName) || t('yourBooking.meetingIn', {name});
	const isHybridVirtualMeeting = useSelector((state) => state.entitiesBooking.onlineMeetingEnabled);
	const teakTypeDiscriminator = entityData?.__t;

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	const reserveEntity = () => {
		bookTeakEntity(
			entityId,
			startTime,
			endTime,
			undefined,
			false,
			undefined,
			amount,
			paymentIntent,
			undefined,
			teakTypeDiscriminator === TEAK_ENTITY_NAMES.ROOM ? meetingName : null,
			isHybridVirtualMeeting,
		)
			.then((response) => {
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'Payment succesfull! Booking was created.'));
				dispatch(loadDashboardData(true));
				dispatch(loadNextBookings(true));
				history.push(ROUTES.DEFAULT);
			})
			.catch((error) => {
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'Something went wrong. You were not charged.', error));
			});
	};

	useEffect(async () => {
		if (!entityId || !startTime || !endTime) return;

		reserveEntity();
		window.localStorage.removeItem('paymentIntentEntityID');
		window.localStorage.removeItem('paymentIntentBookingStartTime');
		window.localStorage.removeItem('paymentIntentBookingEndTime');
		window.localStorage.removeItem('paymentAmount');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="payment-intent-card card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="payment-intent-card-content">
				<div className="card-content">
					<div className="placeholder-container">
						<div className="ph-item">
							<div className="ph-row">
								<div className="ph-col-12"></div>
							</div>
							<div className="ph-row ph-section">
								<div className="ph-col-12"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PaymentRedirect;
