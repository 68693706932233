import { DateTime } from 'luxon';

import { updateDataPrivacyDataStorage } from '../../../../api/member';
import { deleteNotification } from '../../../../api/notifications';
import {
	cancelAttendance,
	cancelBooking,
	cancelBookingSeries,
	checkOutNow,
	deleteBooking,
	editTeakBooking,
	deleteAllRecurringBookings,
} from '../../../../api/teakBooking';
import { deletePineInvitation } from '../../../../api/visitorManagement';
import { ROUTES } from '../../../../constants/routes';
import { loadNextBookings, loadPastBookings, newRecentlyDeletedBooking } from '../../../../store/bookingsOverview/bookings';
import { enableCard } from '../../../../store/cards';
import { loadDashboardData } from '../../../../store/dashboard';
import { updateNotificationDetailsData, updateNotificationsList } from '../../../../store/profile';
import { emptyReportProblemStore } from '../../../../store/reportProblem';
import { dataPrivacyBookingDataStorageUpdate, processItemInList } from '../../../../store/settings';
import { loadPineInviationsListNext24Hours, removeVisitorFromNewMeetingList } from '../../../../store/visitorManagement';
import { bookSession } from '../../../../api/event';
import { loadEventSessionsOfUser } from '../../../../store/events';
import {
	changeShowEntityList,
	removeAttendeeFromList,
	showResultsBookingProcess,
	updateAttendeeList,
	updateBookOnBehalfColleague,
	updateBookOnBehalfColleagueSwitchOn,
} from '../../../../store/bookingProcess/entitiesBooking';
import { deleteAgendaItem } from '../../../../api/agenda';
import { updateBadges } from '../../../../store/badges';
import { addNewSharingRules } from '../../../../api/teakEntity';
import { deleteTimeRecord } from '../../../../api/timeTracker';

export const confirmActionsMap = {
	CANCEL_BOOKING_OCCURENCE: 'cancelBookingOccurence',
	CANCEL_REPORT_PROBLEM: 'reportProblemDelete',
	CANCEL_BOOKING: 'cancelBooking',
	DELETE_PINE_INVITATION: 'deletePineInvitation',
	CANCEL_ATTENDANCE: 'cancelAttendance',
	DELETE_NOTIFICATION: 'deleteNotification',
	DELETE_DATA_STORAGE_OPTION: 'deleteDataStorageOption',
	DELETE_ATTENDEE: 'deleteAttendee',
	EDIT_ATTENDEE_FOR_BOOKING: 'editAttendeeForBooking',
	BOOK_SESSION: 'bookSession',
	BOOKING_OUTSIDE_OFFICE_HOURS: 'bookingOutsideOfficeHours',
	DELETE_AGENDA_ITEM: 'deleteAgendaItem',
	DELETE_BOOKING: 'deleteBooking',
	DELETE_RECURRING_BOOKING: 'deleteAllRecurringBookings',
	CHECKOUT: 'checkOut',
	STOP_SHARING_ASSET: 'stopSharingAsset',
	CONFIRM_SHARING_CONFLICTS: 'confirmSharingConflicts',
	DELETE_VISITOR: 'deleteVisitor',
	DELETE_EXISTING_TIME_RECORD: 'deleteExistingTimeRecord'
};
// Confirm Actions map holds all the actions that can be triggered from the confirm action card.
// Each function returns a promise that resolves with the key of the success message to be shown and a callback function to be executed after the success message is shown.
const confirmActionToFunctions = [
	[confirmActionsMap.CANCEL_BOOKING_OCCURENCE, cancelBookingOccurence],
	[confirmActionsMap.CANCEL_REPORT_PROBLEM, cancelReportProblem],
	[confirmActionsMap.CANCEL_BOOKING, confirmCancelBooking],
	[confirmActionsMap.DELETE_PINE_INVITATION, deleteVisitorInvitation],
	[confirmActionsMap.CANCEL_ATTENDANCE, cancelMemberAttendace],
	[confirmActionsMap.DELETE_NOTIFICATION, deleteNotificationOfUser],
	[confirmActionsMap.DELETE_DATA_STORAGE_OPTION, deleteDataStorageOption],
	[confirmActionsMap.DELETE_ATTENDEE, deleteAttendee],
	[confirmActionsMap.EDIT_ATTENDEE_FOR_BOOKING, confirmEditAttendeeBooking],
	[confirmActionsMap.BOOK_SESSION, confirmSessionBooking],
	[confirmActionsMap.BOOKING_OUTSIDE_OFFICE_HOURS, confirmBookingOutsideOfficeHours],
	[confirmActionsMap.DELETE_AGENDA_ITEM, confirmDeleteAgendaItem],
	[confirmActionsMap.DELETE_BOOKING, confirmDeleteBooking],
	[confirmActionsMap.CHECKOUT, checkOut],
	[confirmActionsMap.STOP_SHARING_ASSET, stopSharingAsset],
	[confirmActionsMap.CONFIRM_SHARING_CONFLICTS, confirmSharingConflicts],
	[confirmActionsMap.DELETE_VISITOR, deleteVisitor],
	[confirmActionsMap.DELETE_RECURRING_BOOKING, confirmDeleteAllRecurringBooking],
	[confirmActionsMap.DELETE_EXISTING_TIME_RECORD, deleteExistingTimeRecord]
];
export const confirmActionToFunctionsMap = new Map(confirmActionToFunctions);

function cancelBookingOccurence(dispatch, data) {
	// dispatch(updateConfirmationCard(confirmActionsMap.CANCEL_BOOKING_OCCURENCE, {headerKey: 'eee', infoKey: 'ewewq', confirmButtonKey: 'ok'}));
	return new Promise((resolve, reject) => {
		const booking = data?.booking;
		// make use of .then and .catch to handle the response from the API call because a promise executer should not be async.
		cancelBookingSeries(booking?.seriesId)
			.then((response) => {
				resolve({
					successKey: 'confirmDelete.confirmSuccess',
					callback: () => {
						dispatch(loadNextBookings(true));
						dispatch(loadDashboardData(true));
					},
				});
			})
			.catch((error) => {
				if (error?.response?.status === 409) {
					resolve({
						successKey: 'confirmDelete.confirmSuccess',
						callback: () => {
							dispatch(loadNextBookings(true));
							dispatch(newRecentlyDeletedBooking(booking?._id));
						},
					});
				}

				reject({ ...error, errorTranslationKey: 'errorMessages.general' });
			});
	});
}

function cancelReportProblem(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		resolve({
			successKey: undefined,
			callback: () => {
				const path = window.location.href;
				dispatch(emptyReportProblemStore());
				dispatch(enableCard(false));

				if (path.includes('profile')) {
					history.push(ROUTES.PROFILE_PAGE + ROUTES.REPORT_PROBLEM);
				} else {
					history.goBack();
				}
			},
		});
	});
}

function confirmCancelBooking(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const booking = data?.booking;
		const organizationData = data?.organizationData;
		const paymentEnabled = organizationData?.bacsis?.enabled && booking?.payment?.hourlyCost;
		if (!booking) reject();
		cancelBooking(booking._id)
			.then((response) => {
				resolve({
					successKey: paymentEnabled ? 'confirmDelete.confirmWithRefund' : 'confirmDelete.confirmSuccess',
					callback: () => {
						dispatch(loadNextBookings(true));
						dispatch(loadDashboardData(true));
						if (booking?.pineInvitation) {
							dispatch(loadPineInviationsListNext24Hours(true));
						}
						history.push(ROUTES.BOOKINGS_OVERVIEW);
					},
				});
			})
			.catch((error) => {
				if (error?.response?.status === 409) {
					resolve({ successKey: 'confirmDelete.confirmSuccess', callback: () => dispatch(newRecentlyDeletedBooking(booking._id)) });
				} else {
					reject({ ...error, errorTranslationKey: 'errorMessages.general' });
				}
			});
	});
}

function deleteVisitorInvitation(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const pineInvitationId = data?.pineInvitationId;

		deletePineInvitation(pineInvitationId)
			.then(() => {
				resolve({
					successKey: 'visitorManagement.cancelMeetingSuccessful',
					callback: () => {
						dispatch(loadPineInviationsListNext24Hours(true));
						dispatch(loadNextBookings(true));
						history.push(ROUTES.DEFAULT);
					},
				});
			})
			.catch((error) => {
				reject({ ...error, errorTranslationKey: 'errorMessages.general' });
			});
	});
}

function cancelMemberAttendace(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const bookingId = data?.booking?._id;
		cancelAttendance(bookingId)
			.then((response) => {
				resolve({
					successKey: 'confirmDelete.cancelAttendanceSuccessful',
					callback: () => {
						dispatch(loadNextBookings(true));
						dispatch(loadDashboardData(true));
						history.push(ROUTES.BOOKINGS_OVERVIEW);
					},
				});
			})
			.catch((error) => {
				reject({ ...error, errorTranslationKey: 'errorMessages.general' });
			});
	});
}

function deleteNotificationOfUser(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const notificationIds = data?.notificationIds;
		const userData = data?.userData;
		deleteNotification(notificationIds, userData._id)
			.then((response) => {
				resolve({
					successKey: notificationIds.length > 1 ? 'notifications.notificationsDeleteSuccess' : 'notifications.notificationDeleteSuccess',
					callback: () => {
						dispatch(updateNotificationsList(userData._id));
						dispatch(updateNotificationDetailsData(null));
					},
				});
			})
			.catch((error) => {
				dispatch(updateNotificationDetailsData(null));
				reject({
					...error,
					errorTranslationKey:
						notificationIds.length > 1 ? 'notifications.notificationsDeleteError' : 'notifications.notificationDeleteError',
				});
			});
	});
}

function deleteDataStorageOption(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const userData = data?.userData;
		updateDataPrivacyDataStorage(userData?._id, false)
			.then((response) => {
				resolve({
					successKey: undefined,
					callback: () => {
						dispatch(dataPrivacyBookingDataStorageUpdate());
						dispatch(enableCard(false));
					},
				});
			})
			.catch((error) => {
				dispatch(dataPrivacyBookingDataStorageUpdate());
				dispatch(enableCard(false));
				reject(error);
			});
	});
}

function deleteAttendee(dispatch, data, history) {

	return new Promise((resolve, reject) => {
		const removedAttendee = data;
		resolve({
			successKey: undefined,
			callback: () => {
				dispatch(removeAttendeeFromList(data));
				dispatch(processItemInList(removedAttendee));
				dispatch(enableCard(false));
			},
		});
	});
}

function confirmEditAttendeeBooking(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const { attendeeList, startTime, endTime, booking, meetingName, entity } = data;

		editTeakBooking(
			booking._id,
			DateTime.fromISO(startTime).toUTC().toISO(),
			DateTime.fromISO(endTime).toUTC().toISO(),
			meetingName,
			attendeeList,
			entity?._id,
		)
			.then((response) => {
				resolve({
					successKey: 'successMessages.editBookingSuccessfull',
					callback: () => {
						dispatch(loadNextBookings(true));
						history.goBack();
					},
				});
			})
			.catch((error) => {
				reject({ ...error, errorTranslationKey: 'errorMessages.apiCalls.editBookingFailed' });
			});
	});
}

function confirmSessionBooking(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const { event, _id } = data;

		bookSession(event, _id)
			.then((response) => {
				resolve({
					successKey: 'event.sessionBookingSuccess',
					callback: () => {
						dispatch(loadEventSessionsOfUser(event));
						history.goBack();
					},
				});
			})
			.catch((error) => {
				dispatch(loadEventSessionsOfUser(event));
				reject({ ...error, errorTranslationKey: 'errorMessages.apiCalls.sessionBookingFailed' });
			});
	});
}

function confirmBookingOutsideOfficeHours(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const { bookOnBehalfColleagueSwitchOn, bookOnBehalfColleague } = data;
		resolve({
			successKey: undefined,
			callback: () => {
				if (bookOnBehalfColleagueSwitchOn && !bookOnBehalfColleague) {
					dispatch(updateAttendeeList(null));
					dispatch(updateBookOnBehalfColleagueSwitchOn(!bookOnBehalfColleagueSwitchOn));
					dispatch(updateBookOnBehalfColleague());
				} else {
					dispatch(showResultsBookingProcess());
					dispatch(changeShowEntityList(true));
				}

				dispatch(enableCard(false));
			},
		});
	});
}

function confirmDeleteAgendaItem(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const { agendaItem, userData } = data;
		deleteAgendaItem(userData?._id, agendaItem?._id, agendaItem?.office)
			.then((response) => {
				resolve({
					successKey: 'agenda.successDeleteItem',
				});
			})
			.catch((error) => {
				reject({ ...error, errorTranslationKey: 'errorMessages.general' });
			});
	});
}

function confirmDeleteBooking(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const { booking } = data;
		deleteBooking(booking._id)
			.then((response) => {
				resolve({
					successKey: 'confirmDelete.confirmDeleteSuccess',
					callback: () => {
						dispatch(newRecentlyDeletedBooking(booking._id));
						dispatch(loadDashboardData(true));
					},
				});
			})
			.catch((error) => {
				if (error.response.status === 409) {
					resolve({
						successKey: 'confirmDelete.confirmDeleteSuccess',
						callback: () => {
							dispatch(newRecentlyDeletedBooking(booking._id));
							dispatch(loadDashboardData(true));
						},
					});
				}
				reject({ ...error, errorTranslationKey: 'errorMessages.general' });
			});
	});
}

function confirmDeleteAllRecurringBooking(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const { booking } = data;
		deleteAllRecurringBookings(booking._id, booking.seriesId)
			.then((response) => {
				resolve({
					successKey: 'confirmDelete.confirmDeleteRecurringSuccess',
					callback: () => {
						dispatch(loadNextBookings(true));
						dispatch(loadPastBookings(true));
						dispatch(loadDashboardData(true));
					},
				});
			})
			.catch((error) => {
				if (error.response.status === 409) {
					resolve({
						successKey: 'confirmDelete.confirmDeleteRecurringSuccess',
						callback: () => {
							dispatch(loadNextBookings(true));
							dispatch(loadPastBookings(true));
							dispatch(loadDashboardData(true));
						},
					});
				}
				reject({ ...error, errorTranslationKey: 'errorMessages.general' });
			});
	});
}

function checkOut(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const booking = data?.booking;
		checkOutNow(booking._id)
			.then((response) => {
				resolve({
					successKey: 'checkOutCard.successCheckOutText',
					callback: () => {
						dispatch(loadDashboardData(true));
						dispatch(loadNextBookings(true));
						dispatch(loadPastBookings(true));
						dispatch(updateBadges({ forceUpdate: true }));
					},
				});
			})
			.catch((error) => {
				reject({ ...error, errorTranslationKey: 'errorMessages.general' });
			});
	});
}

function stopSharingAsset(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const { email, teakEntity, organizationData, selectedOffice } = data;
		addNewSharingRules(
			teakEntity?._id,
			DateTime.now().toUTC().toISO(),
			DateTime.now().plus({ years: 9998 }).toUTC().toISO(),
			[email],
			organizationData._id,
			selectedOffice._id,
		)
			.then((response) => {
				resolve({
					successKey: 'ownAssets.stopSharing.successMessage',
				});
				history.go(0);
			})
			.catch((error) => {
				reject({ ...error, errorTranslationKey: 'ownAssets.stopSharing.errorMessage' });
			});
	});
}

function confirmSharingConflicts(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		const { emails, teakEntity, organizationData, selectedOffice, start, end } = data;
		addNewSharingRules(teakEntity?._id, start, end, emails, organizationData._id, selectedOffice._id)
			.then((response) => {
				resolve({
					successKey: 'shareAsset.sharingRuleSuccess',
				});
				history.goBack();
			})
			.catch((error) => {
				if (error?.response?.data?.name === 'noMemberFound') {
					reject({ ...error, errorTranslationKey: 'errorMessages.noSharingMemberFound' });
				} else {
					reject({ ...error, errorTranslationKey: 'errorMessages.sharingRuleError' });
				}
			});
	});
}
function deleteVisitor(dispatch, data, history) {
	return new Promise((resolve, reject) => {
		dispatch(removeVisitorFromNewMeetingList(data));
		resolve({
			successKey: 'visitorManagement.deleteVisitor.success',
		});
	});
}

function deleteExistingTimeRecord(dispatch, { office, timeRecordID }, history) {
	return new Promise((resolve, reject) => {
		deleteTimeRecord({
			timeRecordID,
			office,
		}).then(() => {

			resolve({
				successKey: 'event.timeRecord.edit.deleteSuccess',
			});
			history.goBack();
		}).catch(error => {
			reject({ ...error, errorTranslationKey: 'event.timeRecord.edit.deleteFailure' });
		});
	});
}