import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';
import SecondaryHeader from '../Common/Header/SecondaryHeader';
import {enableBottomMenu} from '../../store/auth';
import {t} from 'i18next';
import Button from '../Common/Button';
import Icon from '../Common/Icon';
import {sumbitNewPineInvitation} from '../../store/visitorManagement';
import {ROUTES} from '../../constants/routes';
import {updateConfirmationCard} from '../../store/cards';
import Chip from '../Common/Chip/Chip';
import FormattedDateTime from '../Common/DateTimePicker/FormattedDateTime';
import {confirmActionsMap} from '../Cards/Common/ConfirmAction/confirmActions';

const attendanceKeys = ['unknown', 'accepted', 'declined'];

const AttendanceInfo = (props) => {
	const attendance = props.attendance;
	let attendanceTranslationKey = `visitorManagement.attendance.${attendanceKeys[attendance]}`;
	return <p className={`attendee-item-attendance attendee-item-attendance-${attendance}`}>{t(attendanceTranslationKey)}</p>;
};

export const VisitorList = (props) => {
	const [limit, setLimit] = useState(4); //how many visitors to show in the list
	const attendees = props.attendees;

	return (
		<div className="visitor-list-wrapper">
			{attendees?.length > 0
				? attendees.slice(0, limit).map((attendee, index) => {
						const visitor = attendee.pineVisitor ?? attendee;
						return (
							<div key={index} className={`attendee-item${attendees?.length === 1 ? '-no-border' : ''}`}>
								<div>
									<p className="attendee-item-name">{visitor.name.first + ' ' + visitor.name.last}</p>
									<p className="attendee-item-email">{visitor.email}</p>
								</div>
								{attendee.attendance !== undefined ? <AttendanceInfo attendance={attendee.attendance} /> : null}
							</div>
						);
				  })
				: null}
			{limit < attendees.length ? (
				<div className="attendee-show-more" onClick={() => setLimit(attendees.length)}>
					{t('visitorManagement.meetingSummary.showMore')}
					<Icon iconName="chevron-down" cssClass={'icon-purple'} />
				</div>
			) : null}
		</div>
	);
};

const MeetingSummary = () => {
	const {t} = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation();
	const meetingDetails = location.state?.meetingDetails;
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const approvalRequired = meetingDetails.entityData?.approvalRequired?.enabled;

	const closePage = () => {
		history.goBack();
	};

	const submitNewMeeting = () => {
		const returnToDashboard = () => history.push(ROUTES.DEFAULT);
		dispatch(
			sumbitNewPineInvitation(
				meetingDetails.summary,
				meetingDetails.description,
				meetingDetails.attendees,
				meetingDetails?.entityData?._id,
				returnToDashboard,
			),
		);
	};

	const cancelMeeting = () => {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.DELETE_PINE_INVITATION,
				{
					headerKey: 'visitorManagement.cancelMeeting',
					infoKey: 'visitorManagement.cancelMeetingInfo',
					confirmButtonKey: 'common.buttons.wantToCancel',
					closeButtonKey: 'common.buttons.takeBack',
				},
				{pineInvitationId: meetingDetails?.pineInvitationId},
			),
		);
	};

	useEffect(() => {
		dispatch(enableBottomMenu(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="meeting-summary-page">
			<div className="meeting-summary-header">
				<SecondaryHeader headerTranslationKey={'visitorManagement.meetingSummary.header'} backButtonClickHandler={closePage} />
			</div>
			<div className="meeting-summary-content">
				<p className="section">{t('visitorManagement.meetingSummary.meetingInfo')}</p>

				{approvalRequired && (
					<div className="status-description">
						<Icon cssClass={'icon-black'} iconName={'exclamation-circle'}></Icon>
						<div>{t('mapView.entityInfoBottomCard.approvalRequired')}</div>
					</div>
				)}

				{meetingDetails?.summary ? (
					<p className="info-item">
						<span className="bold">{t('visitorManagement.meetingSummary.visitReason')}:</span> {meetingDetails.summary}
					</p>
				) : null}
				{selectedOffice?.name ? (
					<p className="info-item">
						<span className="bold">{t('common.office')}:</span> {selectedOffice.name}
					</p>
				) : null}
				{location.state.meetingDetails.entityData ? (
					<p className="info-item">
						<span className="bold">{t('common.types.TeakEntityMeetingRoom.label')}:</span>{' '}
						{location.state.meetingDetails?.entityData?.name}
					</p>
				) : null}
				{location.state.meetingDetails?.entityData?.areaName ? (
					<p className="info-item">
						<span className="bold">{t('common.areaLabel')}:</span> {location.state.meetingDetails?.entityData?.areaName}
					</p>
				) : null}
				{meetingDetails?.start ? (
					<p className="info-item date">
						<span className="bold">{t('common.dateTimePicker.from')}:</span>{' '}
						<FormattedDateTime dateTime={meetingDetails.start} timezone={selectedOffice.timezone} />
					</p>
				) : null}
				{meetingDetails?.end ? (
					<p className="info-item date">
						<span className="bold">{t('common.dateTimePicker.to')}:</span>{' '}
						<FormattedDateTime dateTime={DateTime.fromISO(meetingDetails.end).toISO()} timezone={selectedOffice.timezone} />
					</p>
				) : null}
				{location.state.meetingDetails.entityData?.featureNames?.length > 0 ? (
					<>
						<p className="info-item">
							<span className="bold">{t('common.features')}:</span>
						</p>
						<div className="features-wrapper">
							{location.state.meetingDetails?.entityData?.featureNames?.map((featureName, index) => (
								<Chip key={index} iconName={featureName.icon} name={featureName.name} disabled="true" />
							))}
						</div>
					</>
				) : null}
				<hr></hr>
				<p className="section">{t('visitorManagement.newMeeting.visitors')}</p>
				<VisitorList attendees={meetingDetails.attendees} />

				<hr></hr>
				{meetingDetails?.description ? (
					<>
						<p className="section">{t('visitorManagement.newMeeting.meetingDetails')}</p>
						<p className="info-item info-meeting-details">{meetingDetails.description}</p>
					</>
				) : null}
			</div>
			<div className="button-wrapper">
				{location?.state?.cancelMeeting ? (
					<Button
						cssClass={'btn-cancel'}
						variant={'negative'}
						height={'regular'}
						width={'full'}
						translationKey={'visitorManagement.cancelMeeting'}
						clickHandler={cancelMeeting}
					/>
				) : (
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						translationKey={'common.buttons.confirm'}
						clickHandler={submitNewMeeting}
					/>
				)}
			</div>
		</div>
	);
};

export default MeetingSummary;
