import {useTranslation} from 'react-i18next';
import Icon from '../Icon';

/**
 *
 * @param {*} defaultValue: value of input
 * @param {*} placeholderKey: translation key for placeholder text
 * @param {*} onChange: callback function triggered when input value is changed
 * @param {*} onFocus: callback function triggered when focus is on input
 * @param {*} onBlur: callback function triggered when input is focused out
 * @param {*} disabled: input is disabled or not
 * @param {*} cleanUpSearch: callback function triggered when x button is clicked inside the search input - usually cleans the search
 * @param {*} backButton; show backButton instead of search icon
 * @returns An input that is used for searching in a list
 */

function SearchInput({
	defaultValue = '',
	cleanUpSearch,
	cssClass,
	backButton,
	backButtonHandler,
	onChange,
	disabled,
	placeholderKey,
	onFocus,
	onBlur,
}) {
	const {t} = useTranslation();

	const cleanUpSearchHandler = () => {
		if (cleanUpSearch) cleanUpSearch();
		defaultValue = '';
	};

	return (
		<div className={cssClass ? 'search-input-wrapper ' + cssClass : 'search-input-wrapper'}>
			{backButton ? (
				<div className="search-input-back-icon" onClick={backButtonHandler}>
					<Icon iconName="chevron-left" cssClass="icon-gray" />
				</div>
			) : (
				<Icon cssClass="search-input-icon" iconName={'magnifying-glass'} />
			)}

			{/* Commented because not sure if we should add the arrow, the x button does the same thing.*/}
			<input
				type="text"
				className="input search-input"
				onChange={onChange}
				placeholder={t(placeholderKey)}
				disabled={disabled}
				onFocus={onFocus}
				onBlur={onBlur}
				value={defaultValue}></input>
			{defaultValue ? (
				<div onClick={cleanUpSearchHandler} className="delete-icon">
					<span className="close">&times;</span>
				</div>
			) : null}
		</div>
	);
}

export default SearchInput;
