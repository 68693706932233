import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { findBookingsInRange } from '../../api';
import { getDataFromAPIForOffice } from '../../functions/getDataFromAPIForOffice';
import { newOfficeDataAdded } from '../auth';
import { limit } from '../../constants/limit';
import { bookingStatus } from '../../constants/bookingStatus';

const slice = createSlice({
	name: 'bookings',
	initialState: {
		nextBookings: [],
		pastBookings: [],
		checkins: [],
		filterByOffice: null,
		filterByAsset: null,
		filterByOrder: 'descending',
		lastFetchNextBookings: null,
		lastFetchPastBookings: null,
		descendingNextBookings: true,
		descendingCheckins: true,
		ascendingPastBookings: true,
		repeatBooking: {
			booking: null,
		},
		detailsBookingData: null,
		ui: {
			loadingNextBookingsData: false,
			loadingPastBookingsData: false,
			activeTab: 'next-bookings',
			selectedContentOption: 'bookings',
		},
	},
	reducers: {
		nextBookingsReceived: (bookings, action) => {
			bookings.nextBookings = action.payload;
		},
		pastBookingsReceived: (bookings, action) => {
			bookings.pastBookings = action.payload;
		},
		checkinsReceived: (bookings, action) => {
			bookings.checkins = action.payload;
		},
		newOfficeForFilterReceived: (bookings, action) => {
			bookings.filterByOffice = action.payload;
		},
		newAssetForFilterReceived: (bookings, action) => {
			bookings.filterByAsset = action.payload;
		},
		newOrderForFilterReceived: (bookings, action) => {
			bookings.filterByOrder = action.payload;
		},
		loadingNextBookingsDataReceived: (bookings, action) => {
			bookings.ui.loadingNextBookingsData = action.payload;
		},
		loadingPastBookingsDataReceived: (bookings, action) => {
			bookings.ui.loadingPastBookingsData = action.payload;
		},
		lastFetchNextBookingsReceived: (bookings, action) => {
			bookings.lastFetchNextBookings = action.payload;
		},
		lastFetchPastBookingsReceived: (bookings, action) => {
			bookings.lastFetchPastBookings = action.payload;
		},
		recentlyDeletedBookingReceived: (bookings, action) => {
			bookings.nextBookings = action.payload.nextBookings;
			bookings.pastBookings = action.payload.pastBookings;
		},
		detailsBookingDataReceived: (bookings, action) => {
			bookings.detailsBookingData = action.payload;
		},
		errorReceived: (bookings, action) => {
			bookings.errorMessage = action.payload.message;
		},
		activeTabReceived: (bookings, action) => {
			bookings.ui.activeTab = action.payload;
		},
		selectedContentOptionReceived: (bookings, action) => {
			bookings.ui.selectedContentOption = action.payload;
		},
	},
});

export const {
	nextBookingsReceived,
	pastBookingsReceived,
	checkinsReceived,
	newOfficeForFilterReceived,
	newAssetForFilterReceived,
	newOrderForFilterReceived,
	loadingNextBookingsDataReceived,
	loadingPastBookingsDataReceived,
	lastFetchNextBookingsReceived,
	lastFetchPastBookingsReceived,
	recentlyDeletedBookingReceived,
	detailsBookingDataReceived,
	errorReceived,
	activeTabReceived,
	selectedContentOptionReceived,
} = slice.actions;

export default slice.reducer;

export const loadNextBookings = (force) => async (dispatch, getState) => {
	if (!force) {
		const lastFetchOfData = getState().bookings.lastFetchNextBookings;
		if (lastFetchOfData && DateTime.now().toUTC() < DateTime.fromISO(lastFetchOfData).toUTC().plus({ minutes: 2 })) return;
	}

	dispatch({ type: loadingNextBookingsDataReceived.type, payload: true });
	const authData = getState().auth.data;
	const userData = getState().auth.data.userData;
	let teakTypeArrayFromStore = authData.teakTypeArray?.slice();
	let areasFromStore = authData.areas?.slice();
	let officesFromStore = authData.offices?.slice();
	let teakFeaturesFromStore = authData.teakFeatures?.slice();
	let areasTreeListFromStore = authData.areasTreeList?.slice();
	let aspenTypesFromStore = authData.aspenTypes?.slice();
	let listOfCompleteOfficesIds = officesFromStore.filter((el) => el.hasAllData === true).map((el) => el._id);
	let unclaimedBookings = [];
	let checkins = [];
	let startOfRange = DateTime.now().toUTC().toISO();
	const endOfRange = DateTime.now().plus({ days: limit.bookingsOverviewInAdvance }).toUTC().toISO();

	try {
		const nextBookingsResponse = await findBookingsInRange(startOfRange, endOfRange);

		const nextBookings = nextBookingsResponse.data;

		let officeWithoutData = [];
		nextBookings.forEach((booking) => {
			if (!listOfCompleteOfficesIds.includes(booking.office)) {
				if (!officeWithoutData.includes(booking.office)) {
					officeWithoutData.push(booking.office);
				}
			}
		});

		if (officeWithoutData.length > 0) {
			for (let i = 0; i < officeWithoutData.length; i++) {
				const officeId = officeWithoutData[i];
				const { offices, areas, teakTypeArray, teakFeatures, areasTreeList, aspenTypes } = await getDataFromAPIForOffice(
					dispatch,
					areasFromStore,
					areasTreeListFromStore,
					officesFromStore,
					teakTypeArrayFromStore,
					teakFeaturesFromStore,
					aspenTypesFromStore,
					{ _id: officeId },
				);
				officesFromStore = offices;
				areasFromStore = areas;
				teakTypeArrayFromStore = teakTypeArray;
				teakFeaturesFromStore = teakFeatures;
				areasTreeListFromStore = areasTreeList;
				aspenTypesFromStore = aspenTypes;
				dispatch({
					type: newOfficeDataAdded.type,
					payload: {
						offices,
						areas,
						teakTypeArray,
						teakFeatures,
						areasTreeList,
						aspenTypes,
					},
				});
			}
		}
		// loop through the next bookings and populate feature names and areaName
		nextBookings.forEach((booking) => {
			const areaFromStore = areasFromStore.find((area) => area._id === booking.teakEntity.area);
			booking.teakEntity.areaName = areaFromStore?.name;

			let featureNames = [];

			for (let index = 0; index < booking.teakEntity?.teakFeatures?.length; index++) {
				const featureFromStore = teakFeaturesFromStore.find((feature) => feature.id === booking.teakEntity?.teakFeatures[index]);
				featureNames.push({
					icon: featureFromStore?.iconName,
					name: featureFromStore?.name,
				});
			}

			booking.teakEntity.featureNames = featureNames;

			const officeFromStore = officesFromStore.find((office) => office._id === booking.office);

			const officeName = officeFromStore.name;

			booking.officeName = officeName;
		});

		const pastBookingStatuses = [
			bookingStatus.expired,
			bookingStatus.checkedOut,
			bookingStatus.checkInForgotten,
			bookingStatus.checkOutForgotten,
		];

		unclaimedBookings = nextBookings.filter((booking) => {
			if (pastBookingStatuses.includes(booking.status)) return false;
			if (booking.status === bookingStatus.checkedIn || booking.status === bookingStatus.autoCheckedIn) {
				if (booking?.attendees?.length > 0) {
					return !booking?.attendees?.find((attendee) => attendee.member.id === userData._id && attendee?.checkIn);
				} else {
					return false;
				}
			}
			return true;
		});
		checkins = nextBookings.filter((booking) => {
			if (booking.status !== bookingStatus.checkedIn && booking.status !== bookingStatus.autoCheckedIn) return false;

			if (booking?.attendees?.length > 0) {
				return booking?.attendees?.find((attendee) => attendee.member.id === userData._id && attendee?.checkIn && !attendee?.checkOut);
			} else {
				return true;
			}
		});
	} catch (error) {
		console.log(error);
		dispatch({ type: errorReceived.type, payload: error.message });
	}

	unclaimedBookings.sort((a, b) => DateTime.fromISO(a.start.time).toMillis() - DateTime.fromISO(b.start.time).toMillis());
	checkins.sort((a, b) => DateTime.fromISO(a.start.time).toMillis() - DateTime.fromISO(b.start.time).toMillis());
	dispatch({ type: nextBookingsReceived.type, payload: unclaimedBookings });
	dispatch({ type: checkinsReceived.type, payload: checkins });
	dispatch({
		type: lastFetchNextBookingsReceived.type,
		payload: DateTime.now().toISO(),
	});

	dispatch({ type: loadingNextBookingsDataReceived.type, payload: false });
};

export const loadPastBookings = (force) => async (dispatch, getState) => {
	if (!force) {
		const lastFetchOfData = getState().bookings.lastFetchPastBookings;
		if (lastFetchOfData && DateTime.now().toUTC() < DateTime.fromISO(lastFetchOfData).toUTC().plus({ minutes: 2 })) return;
	}

	dispatch({ type: loadingPastBookingsDataReceived.type, payload: true });

	const authData = getState().auth.data;
	let teakTypeArrayFromStore = authData.teakTypeArray?.slice();
	let areasFromStore = authData.areas?.slice();
	let officesFromStore = authData.offices?.slice();
	let teakFeaturesFromStore = authData.teakFeatures?.slice();
	let areasTreeListFromStore = authData.areasTreeList?.slice();
	let aspenTypesFromStore = authData.aspenTypes?.slice();
	let listOfCompleteOfficesIds = officesFromStore.filter((el) => el.hasAllData === true).map((el) => el._id);
	let startOfRange = DateTime.now().minus({ days: limit.bookingsOverviewInAdvance }).toUTC().toISO();
	let endOfRange = DateTime.now().toUTC().toISO();
	let unclaimedBookings = [];

	try {
		const pastBookingsResponse = await findBookingsInRange(startOfRange, endOfRange);
		const pastBookings = pastBookingsResponse.data;

		unclaimedBookings = pastBookings.slice().filter((booking) => booking.teakEntity !== null && booking.status !== 'checkedIn');

		let officeWithoutData = [];
		unclaimedBookings.forEach((booking) => {
			if (!listOfCompleteOfficesIds.includes(booking.office)) {
				if (!officeWithoutData.includes(booking.office)) {
					officeWithoutData.push(booking.office);
				}
			}
		});

		if (officeWithoutData?.length > 0) {
			for (let i = 0; i < officeWithoutData.length; i++) {
				const officeId = officeWithoutData[i];
				const { offices, areas, teakTypeArray, teakFeatures, areasTreeList, aspenTypes } = await getDataFromAPIForOffice(
					dispatch,
					areasFromStore,
					areasTreeListFromStore,
					officesFromStore,
					teakTypeArrayFromStore,
					teakFeaturesFromStore,
					aspenTypesFromStore,
					{ _id: officeId },
				);
				officesFromStore = offices;
				areasFromStore = areas;
				teakTypeArrayFromStore = teakTypeArray;
				teakFeaturesFromStore = teakFeatures;
				areasTreeListFromStore = areasTreeList;
				aspenTypesFromStore = aspenTypes;
				dispatch({
					type: newOfficeDataAdded.type,
					payload: {
						offices,
						areas,
						teakTypeArray,
						teakFeatures,
						areasTreeList,
						aspenTypes,
					},
				});
			}
		}

		unclaimedBookings.forEach((booking) => {
			const areaFromStore = areasFromStore?.find((area) => area?._id === booking?.teakEntity?.area);
			booking.teakEntity.areaName = areaFromStore?.name;

			let featureNames = [];

			for (let index = 0; index < booking.teakEntity?.teakFeatures?.length; index++) {
				const featureFromStore = teakFeaturesFromStore.find((feature) => feature.id === booking.teakEntity?.teakFeatures[index]);
				featureNames.push({
					icon: featureFromStore?.iconName,
					name: featureFromStore?.name,
				});
			}

			booking.teakEntity.featureNames = featureNames;

			const officeFromStore = officesFromStore.find((office) => office._id === booking.office);

			booking.officeName = officeFromStore.name;
		});
	} catch (error) {
		console.log(error);
		dispatch({ type: errorReceived.type, payload: error.message });
	}

	unclaimedBookings.sort((a, b) => DateTime.fromISO(b.start.time).toMillis() - DateTime.fromISO(a.start.time).toMillis());
	dispatch({ type: pastBookingsReceived.type, payload: unclaimedBookings });
	dispatch({
		type: lastFetchPastBookingsReceived.type,
		payload: DateTime.now().toISO(),
	});

	dispatch({ type: loadingPastBookingsDataReceived.type, payload: false });
};

export const changeFilterOffice = (officeId) => async (dispatch, getState) => {
	if (getState().bookings.filterByOffice === officeId) return;

	dispatch({ type: newOfficeForFilterReceived.type, payload: officeId });
};

export const enableLoadingNextBookingsData = (enable) => (dispatch, getState) => {
	if (getState().bookings.ui.loadingNextBookingsData === enable) return;

	dispatch({ type: loadingNextBookingsDataReceived.type, payload: enable });
};

export const changeAsset = (asset) => async (dispatch, getState) => {
	if (getState().bookings.filterByAsset === asset) return;

	dispatch({ type: newAssetForFilterReceived.type, payload: asset });
};

export const enableLoadingPastBookingsData = (enable) => (dispatch, getState) => {
	if (getState().bookings.ui.loadingPastBookingsData === enable) return;

	dispatch({ type: loadingPastBookingsDataReceived.type, payload: enable });
};

export const newRecentlyDeletedBooking = (bookingId) => (dispatch, getState) => {
	const currentNextBookings = getState().bookings.nextBookings;
	const currentPastBookings = getState().bookings.pastBookings;
	const newNextBookingsArray = currentNextBookings.filter((booking) => booking._id !== bookingId);
	const newPastBookingsArray = currentPastBookings.filter((booking) => booking._id !== bookingId);

	dispatch({
		type: recentlyDeletedBookingReceived.type,
		payload: {
			nextBookings: newNextBookingsArray,
			pastBookings: newPastBookingsArray,
		},
	});
};

export const changeFilterByOrder = (value) => (dispatch, getState) => {
	if (getState().bookings.filterByOrder === value) return;

	dispatch({ type: newOrderForFilterReceived.type, payload: value });
};

export const updateDetailsBookingData = (data) => (dispatch, getState) => {
	if (getState().bookings.detailsBookingData === data) return;

	dispatch({ type: detailsBookingDataReceived.type, payload: data });
};

export const changeActiveTab = (newTab) => (dispatch, getState) => {
	dispatch({ type: activeTabReceived.type, payload: newTab });
};

export const changeSelectedContentOption = (newContentOption) => (dispatch, getState) => {
	dispatch({ type: selectedContentOptionReceived.type, payload: newContentOption });
};
//selectors
