/**
 *
 * @param {*} id = id of input
 * @param {*} isOn = if the switch is on
 * @param {*} handleToggle = callback trigger when switch is changed
 * @param {*} onColor = color shown when switch is on
 * @returns An input that looks like a switch
 */
const Switch = ({id, isOn, handleToggle, onColor}) => {
	return (
		<>
			<input checked={isOn} onChange={handleToggle} className="switch-checkbox" id={id} type={'checkbox'} />
			<label style={{background: isOn && onColor}} className="switch-label" htmlFor={id}>
				<span className="switch-button" />
			</label>
		</>
	);
};

export default Switch;
