import {useEffect} from 'react';
import {validateBadgeVerificationCode, getMember, getMemberPhoto} from '../../api/index';
import {useSelector, useDispatch} from 'react-redux';
import {enableCard, showMemberCard, updateCard, updateErrorCard} from '../../store/cards';
import {CARD} from '../../constants/cards';
import {colleaguePlaceholderPhoto} from '../../constants/images';

function MemberBadgeModal() {
	const dispatch = useDispatch();
	const show = useSelector((state) => state.dashboard.ui.showBadgeModal);
	const verificationCode = useSelector((state) => state.dashboard.data.badgeCode);

	useEffect(() => {
		if (verificationCode) {
			dispatch(enableCard(true));
			dispatch(updateCard(CARD.MEMBER_BADGE_PLACEHOLDER));
			validateBadgeVerificationCode(verificationCode)
				.then((response) => {
					const member = response.data.member;
					getMember(member).then((response) => {
						const memberData = response.data;
						getMemberPhoto(member, true)
							.then((response) => {
								if (response.status === 204) throw new Error('no member photo');

								memberData.photoURL = window.URL.createObjectURL(new Blob([response.data]));
								dispatch(showMemberCard(memberData));
							})
							.catch((error) => {
								memberData.photoURL = colleaguePlaceholderPhoto;
								dispatch(showMemberCard(memberData));
								dispatch(updateErrorCard(CARD.MAIN_ERROR, 'badgeCard.badgeModal.notFoundError', error));
							});
					});
				})
				.catch((error) => {
					console.log(error);
					dispatch(updateErrorCard(CARD.MAIN_ERROR, 'badgeCard.badgeModal.notFoundError', error));
				});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [show]);

	return null;
}

export default MemberBadgeModal;
