export const TEAK_TYPE_NAMES = {
	DESK: 'TeakTypeWorkplace',
	ROOM: 'TeakTypeMeetingRoom',
	PARKING: 'TeakTypeParkingSpace',
};

export const TEAK_TYPE_TO_ENTITY = {
	TeakTypeWorkplace: 'TeakEntityWorkplace',
	TeakTypeMeetingRoom: 'TeakEntityMeetingRoom',
	TeakTypeParkingSpace: 'TeakEntityParkingSpace',
};

export const TEAK_ENTITY_TO_TYPE = {
	TeakEntityWorkplace: 'TeakTypeWorkplace',
	TeakEntityMeetingRoom: 'TeakTypeMeetingRoom',
	TeakEntityParkingSpace: 'TeakTypeParkingSpace',
};

export const DEFAULT_TEAK_TYPES = ['TeakTypeWorkplace', 'TeakTypeMeetingRoom', 'TeakTypeParkingSpace'];
export const DEFAULT_TEAK_ENTITY_TYPES = ['TeakEntityWorkplace', 'TeakEntityMeetingRoom', 'TeakEntityParkingSpace'];
