import {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import {Decoder} from '@nuintun/qrcode';
import {addDataToCard, enableCard, updateCard, updateErrorCard} from '../../store/cards';
import {CARD} from '../../constants/cards';
import {enableBottomMenu} from '../../store/auth';
import {ROUTES} from '../../constants/routes';
import Button from './Button';
import heic2any from "heic2any";

const qrcode = new Decoder();
function AddCertificate(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const inputRef = useRef();

	const scanHandler = () => {
		history.push(ROUTES.QR_SCANNER);
	};

	const openUpload = () => {
		inputRef.current.click();
	};

	const uploadHandler = async () => {
		dispatch(enableCard(true));
		dispatch(updateCard(CARD.PLACEHOLDER));
		let fileInput = inputRef.current;
		let image = fileInput?.files[0];
	
		if (!image) return;
		let imageUrl = window.URL.createObjectURL(image);

		//check if image is .heic file
		if(image.name.includes('.heic')){
			// convert "fetch" the new blob url
			let blobRes = await fetch(imageUrl);

			// convert response to blob
			let blob = await blobRes.blob();

			// convert to JPG - response is blob
			let conversionResult = await heic2any({
			blob,
			toType: "image/jpg",
			quality: 1
			});

			let imageUrl = URL.createObjectURL(conversionResult);
		}
		

		qrcode
			.scan(imageUrl)
			.then((result) => {
				dispatch(addDataToCard(result?.data));
				if (props.redirectToDashboard) history.push(ROUTES.DEFAULT);
				dispatch(updateCard(CARD.CEYLON_PROCESS));
			})
			.catch((error) => {
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'ceylon.errorCard.imageTypeFail'));
				console.log(error);
			});
	};

	useEffect(() => {
		dispatch(enableBottomMenu(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="add-certificate-wrapper">
			<p className="covid-status-page-info-heading">{t('profile.headers.covidCertificate') + '*'}</p>
			<input className="hidden" type="file" onChange={uploadHandler} accept="image/*" ref={inputRef} />

			<div className="buttons-wrapper">
				<Button
					variant={'primary'}
					height={'regular'}
					width={'full'}
					clickHandler={scanHandler}
					translationKey={'profile.buttons.scanQR'}
					iconName={'qrcode'}
					iconPlacement={'left'}
				/>

				<Button
					variant={'secondary'}
					height={'regular'}
					width={'full'}
					clickHandler={openUpload}
					translationKey={'profile.buttons.import'}
					iconName={'file-upload'}
					iconPlacement={'left'}
				/>
			</div>
		</div>
	);
}

export default AddCertificate;
