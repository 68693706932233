/**
 *
 * @param {function} clickHandler = function triggered when user clicks on section
 * @param {string} infoText = text shown inside component for information
 * @returns Simple React component that holds the add people component.
 */
function AddPeopleSection({clickHandler, infoText}) {
	return (
		<div className="add-people-wrapper" onClick={clickHandler}>
			<div className="add-colleagues-button">
				<span>+</span>
			</div>
			<p>{infoText}</p>
		</div>
	);
}

export default AddPeopleSection;
