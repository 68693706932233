import {createSlice} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';
import {getBadgesData} from '../api';

const slice = createSlice({
	name: 'badges',
	initialState: {
		badgesData: [],
		eventBadgesData: [],
		lastUpdated: null,
	},
	reducers: {
		badgesChanged: (badges, action) => {
			badges.badgesData = action.payload;
			badges.lastUpdated = DateTime.now().toUTC().toISO();
		},
		eventBadgesDataChanged: (badges, action) => {
			badges.eventBadgesData = action.payload;
		},
	},
});

export const {badgesChanged, eventBadgesDataChanged} = slice.actions;

export default slice.reducer;

export const updateBadges = (payload, callback) => async (dispatch, getState) => {
	try {
		if (payload?.data) {
			dispatch({type: badgesChanged.type, payload: payload?.data});

			if (callback) callback();
			return;
		}

		const lastUpdated = getState().badges.lastUpdated;
		const lastFetch = DateTime.fromISO(lastUpdated);
		if (!payload?.forceUpdate && lastUpdated && lastFetch.plus({minutes: 1}) > DateTime.now()) return;

		let loadedData = (await getBadgesData()).data;
		dispatch({type: badgesChanged.type, payload: loadedData});
		if (callback) callback();
	} catch (error) {
		console.log(error);
	}
};

export const updateEventBadges = (wengeAttendee, wengeEvent, payload, callback) => async (dispatch, getState) => {
	try {
		if (payload?.data) {
			dispatch({type: eventBadgesDataChanged.type, payload: payload?.data});

			if (callback) callback();
			return;
		}

		let loadedData = (await getBadgesData(wengeAttendee, wengeEvent)).data;
		dispatch({type: eventBadgesDataChanged.type, payload: loadedData});
		if (callback) callback();
	} catch (error) {
		console.log(error);
	}
};
