import {useSelector} from 'react-redux';
import {DateTime} from 'luxon';

/**
 * Component that retrieves a formatted date time string used in the app. It holds functionality for 12/24 hours format. It changes day names based on user language
 *
 * @param {iso} time = date time in iso format
 * @param {string} timezone = timezone of the shown date time string
 * @returns
 */
function FormattedDateTime({dateTime, timezone}) {
	const enable12HourFormat = useSelector((state) => state.auth.data.enable12HourFormat);
	const dateFormat = useSelector((state) => state.auth.data.dateFormat);
	const userData = useSelector((state) => state.auth.data.userData);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const timeFormat = enable12HourFormat ? 'hh:mm a' : 'HH:mm';
	let locale = userData?.language;
	if(locale === 'ru'){ locale = 'fr'; };
	const formattedDateTime = `
    ${DateTime.fromISO(dateTime)
		.setZone(timezone ? timezone : selectedOffice?.timezone)
		.setLocale(locale)
		.toFormat(`ccc ${dateFormat}`)} |
${DateTime.fromISO(dateTime)
	.setZone(timezone ? timezone : selectedOffice?.timezone)
	.toFormat(timeFormat)}`;

	return formattedDateTime;
}

export default FormattedDateTime;
