import { useTranslation } from 'react-i18next';

const BookingStatusLabel = ({ bookingStatusName }) => {
	const { t } = useTranslation();

	return (
		<div className={'booking-item-status status-' + bookingStatusName}>
			<p>{t(`bookingStatus.display.${bookingStatusName}`)}</p>
		</div>
	);
};

export default BookingStatusLabel;
