import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import DayItem from './DayItem';
import { useSwipeable } from 'react-swipeable';
import Icon from '../Icon';
import { useSelector } from 'react-redux';

/**
 *
 * @param {DateTime} props.activeDate = active Date shown initially - Date Time object
 * @param {function} props.setActiveDate = callback to be triggerent when active date is changed
 * @returns
 */

function DaySelection({ activeDate, setActiveDate }) {
	const { t } = useTranslation();
	const now = DateTime.now();
	const dateFormat = useSelector((state) => state.auth.data.dateFormat);
	const [dayItems, setDayItems] = useState([]);

	useEffect(() => {
		// create dayItems for the entire week
		let dayItemsArray = [];
		const startOfWeek = activeDate.startOf('week');
		const endOfWeek = activeDate.endOf('week');
		let dayOfItem = startOfWeek;
		while (dayOfItem <= endOfWeek) {
			dayItemsArray.push(
				<DayItem
					key={dayOfItem.day}
					date={dayOfItem}
					active={activeDate?.toFormat(dateFormat) === dayOfItem?.toFormat(dateFormat) ? 'active' : ''}
					onClick={(value) => setActiveDate(value)}
				/>,
			);
			dayOfItem = dayOfItem.plus({ days: 1 });
		}

		if (dayItemsArray.length > 0) setDayItems(dayItemsArray);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeDate]);

	const updateWeek = (moveToNextWeek) => {
		const newActiveDate = moveToNextWeek ? activeDate.plus({ weeks: 1 }) : activeDate.minus({ weeks: 1 });
		setActiveDate(newActiveDate);
	};

	const swipeHandle = useSwipeable({
		onSwipedLeft: () => updateWeek(true),
		onSwipedRight: () => updateWeek(),
	});

	return (
		<div className={'day-selection-wrapper'} {...swipeHandle}>
			<div className="month-selection">
				<p className="selected-day">
					{activeDate?.toFormat(dateFormat) === now?.toFormat(dateFormat) ? t('common.today') : activeDate?.toFormat(dateFormat)}
				</p>
				<p className="selected-month">{activeDate ? activeDate?.toFormat('MMMM y') : null}</p>
			</div>
			<div className="day-selection">
				<div className="action-icon" onClick={() => updateWeek()}>
					<Icon iconName={'chevron-left'} cssClass={'icon-gray'} />
				</div>
				{dayItems}
				<div className="action-icon" onClick={() => updateWeek(true)}>
					<Icon iconName={'chevron-right'} cssClass={'icon-gray'} />
				</div>
			</div>
		</div>
	);
}

export default DaySelection;
