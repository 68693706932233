import colorBasedOnTheme from '../functions/colorBasedOnTheme';

let colorDot = '#9FA1AC';

const colors = colorBasedOnTheme();

export const selectStyleOptions = {
	indicatorContainer: (styles) => ({
		...styles,
		padding: '8px 12px',
	}),

	container: (styles) => ({
		...styles,
		maxWidth: '100%',
	}),

	control: (styles) => ({
		...styles,
		borderRadius: '5px',
		backgroundColor: 'white',
		':hover': {
			...styles[':hover'],
			outline: '0!important',
			borderColor: 'black',
			boxShadow: 'none',
		},
		':focus': {
			...styles[':focus'],
			outline: '0!important',
			borderColor: 'black',
			boxShadow: 'none',
		},
		':active': {
			...styles[':active'],
			outline: '0!important',
			borderColor: 'black',
			boxShadow: 'none',
		},
	}),

	menu: (provided, state) => ({
		...provided,
		padding: 0,
		borderRadius: '10px',
		zIndex: '10',
		width: '100%',
	}),

	menuList: (provided, state) => ({
		...provided,
		padding: 0,
		marginTop: 0,
		marginBottom: 0,
		maxHeight: '220px',
		overflowY: 'auto',
		borderRadius: '10px',
	}),

	option: (styles, {isDisabled, isFocused, isSelected}) => {
		const whiteColor = '#fff';
		const themeColor = 'var(--color-main)';
		const blackColor = '#000';
		const greyColor = colors.colorNeutral50;
		let color = colors.colorNeutral50;
		let bgColor = '#fff';

		if (isSelected) {
			color = themeColor;
			bgColor = themeColor;
		}

		return {
			...styles,
			paddingLeft: '37px',
			paddingBottom: '0',
			paddingTop: '0',
			fontSize: '14px',
			lineHeight: '38px',

			backgroundColor: isDisabled ? null : isSelected ? whiteColor : isFocused ? whiteColor : null,

			color: isDisabled ? greyColor : isSelected ? blackColor : greyColor,

			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled && (isSelected ? whiteColor : whiteColor),
				...dot(themeColor, themeColor),
			},

			':hover': {
				...styles[':hover'],
				backgroundColor: !isDisabled && (isSelected ? colors.colorNeutral95 : colors.colorNeutral95),
				...dot(themeColor, themeColor),
			},

			...dot(color, bgColor),
		};
	},
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: 'none',
	}),
};

// this styles imitate radio-button looking
const dot = (color = colorDot, bgColor = 'white') => ({
	alignItems: 'center',
	display: 'flex',

	':before': {
		// outside circle
		content: '""',
		display: 'inline-block',
		verticalAlign: 'bottom',
		position: 'absolute',
		left: '8px',
		width: '20px',
		height: '20px',
		marginRight: '0.3rem',
		borderRadius: '50%',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: color,
	},

	':after': {
		// inside circle
		content: '""',
		backgroundColor: bgColor,
		display: 'inline-block',
		verticalAlign: 'bottom',
		position: 'absolute',
		left: '13px',
		width: '10px',
		height: '10px',
		marginRight: '0.3rem',
		borderRadius: '50%',
		borderStyle: 'solid',
		borderWidth: '1px',
		borderColor: bgColor,
	},
});
