import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {createTheme} from '@material-ui/core';
import MomentUtils from '@date-io/moment';
import {ThemeProvider} from '@material-ui/styles';
import {DateTime} from 'luxon';
import TimePicker from 'rc-time-picker';
import 'moment/min/locales'; 
import moment from 'moment';
import Icon from '../Icon';

/**
 * Your component props and logic remain the same.
 */
function SingleDateTimePicker({
	dateTime,
	changeTime,
	timeFormat,
	timeFormat12hrs,
	disabled,
	disableFuture,
	disablePast = false,
	minDate,
	maxDate = false,
	minuteStep = 15,
	timezone,
	handleFocus,
	customTimePickerPlacement,
	inputLabelKey,
	disabledHours,
	disabledMinutes,
	onlyDate = false,
	datePickerCss = '',
	disabledTimes = false,
}) {
	const { t, i18n } = useTranslation();
	const [focusedInput, setFocusedInput] = useState(false);
	const dateFormat = useSelector((state) => state.auth.data.dateFormat);

	useEffect(() => {
		const locale = i18n.language !== 'ru' ? i18n.language : 'fr';
		moment.locale(locale);
	}, [i18n.language]);

	const materialTheme = createTheme({
		overrides: {
			textPrimary: {
				color: 'var(--color-main)',
			},
			MuiPickersToolbar: {
				toolbar: {
					backgroundColor: 'var(--color-main)',
				},
			},
			MuiPickersDay: {
				daySelected: {
					backgroundColor: 'var(--color-main)',
					'&:hover': {
						backgroundColor: 'var(--color-main)',
					},
				},
			},
			MuiButton: {
				textPrimary: {
					color: 'var(--color-main)',
				},
			},
		},
	});

	let formatString = dateFormat === 'dd/LL/yyyy' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

	return (
		<div className={`date-time-input-wrapper ${disabled ? 'date-time-input-wrapper-disabled' : ''}`}>
			<label className="date-time-input-label">{t(inputLabelKey)}</label>
			<div className="date-time-input-content">
				<div className={`datepicker-wrapper ${datePickerCss} ${onlyDate ? 'only-date' : ''}`}>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<ThemeProvider theme={materialTheme}>
							<DatePicker
								value={dateTime}
								onChange={(value) => changeTime(DateTime.fromISO(value.format()).setZone(timezone).toISO())}
								format={formatString}
								disabled={disabled}
								disablePast={disablePast}
								error={false}
								helperText={null}
								minDate={minDate}
								maxDate={maxDate ? maxDate : undefined}
								disableFuture={disableFuture}
								onOpen={handleFocus}
							/>
						</ThemeProvider>
						<Icon iconName="calendar-alt" />
					</MuiPickersUtilsProvider>
				</div>
				{!onlyDate && (
					<div className={`timepicker-wrapper ${focusedInput ? 'focused-input' : ''} ${(disabled || disabledTimes) ? 'disabled-grey-out' : ''}`}>
						<TimePicker
							showSecond={false}
							value={dateTime}
							onChange={(e) => {
								changeTime(DateTime.fromISO(e.toISOString()).setZone(timezone).toISO());
							}}
							disabledHours={disabledHours}
							disabledMinutes={disabledMinutes}
							format={timeFormat}
							use12Hours={timeFormat12hrs}
							inputReadOnly
							allowEmpty={false}
							getPopupContainer={(node) => node}
							minuteStep={minuteStep}
							disabled={disabled || disabledTimes}
							onFocus={handleFocus}
							placement={customTimePickerPlacement}
							onOpen={() => setFocusedInput(true)}
							onClose={() => setFocusedInput(false)}
						/>
						<Icon iconName={focusedInput ? 'angle-up' : 'angle-down'} />
					</div>
				)}
			</div>
		</div>
	);
}

export default SingleDateTimePicker;
