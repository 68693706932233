import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Icon from '../Icon';

/**
 *
 * @param {*} defaultValue = value shown in the input
 * @param {*} cssClass = className added to the input wrapper
 * @param {*} required = if input is required
 * @param {*} labelKey = label shown on top of input
 * @param {*} maximumCharacterLimit = a maximum limit for how many chars a user can input
 * @param {*} clickHandler = callback when input is updated
 * @param {*} disabled = if input is disabled
 * @param {*} placeholderKey = placeholder text shown in the input
 * @param {*} onKeyPress = callback when user types
 * @param {*} id = id of input
 * @param {*} type = type of input
 * @param {*} inputCheck = function to check the input value after user added some chars
 * @param {*} errorMessage = errorMessage that shows if the input check fails
 * @param {*} hasError = if input should show an error
 * @param {*} handleBlur = callback used when input is focused
 * @param {*} handleFocus = callback used when focus is moved from input
 * @returns A input used for user input
 */
function PrimaryInput({
	defaultValue = '',
	cssClass = '',
	required,
	labelKey,
	maximumCharacterLimit,
	clickHandler,
	disabled,
	placeholderKey,
	onKeyPress,
	id,
	type = 'text',
	inputCheck,
	errorMessage,
	hasError,
	handleBlur,
	handleFocus,
}) {
	const {t} = useTranslation();

	const [error, setError] = useState({
		errorCss: '',
		inputErrorCss: '',
		icon: null,
		errorMessage: '',
	});

	let requiredMark = required ? '*' : '';

	if (disabled) cssClass += ' disabled';

	useEffect(() => {
		if (inputCheck) {
			if (hasError) {
				addError();
			} else {
				removeError();
			}
		}
	}, [hasError]);

	const removeError = () => {
		setError({
			errorCss: '',
			inputErrorCss: '',
			icon: !defaultValue ? null : <Icon cssClass={'icon-green'} iconName={'check-circle'} />,
			errorMessage: '',
		});
	};

	const addError = (errorMessage) => {
		setError({
			errorCss: ' error ',
			inputErrorCss: ' input-error',
			icon: <Icon cssClass={'icon-error'} iconName={'exclamation-circle'}></Icon>,
			errorMessage: errorMessage,
		});
	};

	const handleChange = (newValue) => {
		if (error.errorMessage !== '') {
			checkValue(newValue);
		}

		if (maximumCharacterLimit && newValue.length > maximumCharacterLimit) return;

		clickHandler(newValue);
		defaultValue = newValue;
	};

	const checkValue = (value) => {
		if (inputCheck) {
			const checkResult = inputCheck(value);
			if (inputCheck(value) === true) {
				removeError();
			} else if (checkResult === 'empty' || value.length === 0) {
				setError({
					errorCss: '',
					inputErrorCss: '',
					icon: null,
					errorMessage: '',
				});
			} else {
				addError(errorMessage);
			}
		}
	};

	const handleBlurHandler = (value, e) => {
		if (handleBlur) {
			handleBlur(value, e);
		}
		checkValue(value);
	};

	const handleFocusHandler = (id, e) => {
		if (handleFocus) {
			handleFocus(id, e);
		}
	};

	const handleKeyPress = (key, input) => {
		if (onKeyPress) {
			onKeyPress(key, input);
		}
	};

	return (
		<div id={id} className={'primary-input ' + error.errorCss + cssClass}>
			{labelKey ? <label className="primary-input-label">{t(labelKey) + requiredMark}</label> : null}
			<div className="input-wrapper">
				<input
					type={type}
					id={id}
					value={defaultValue}
					className={'input' + error.inputErrorCss}
					placeholder={t(placeholderKey)}
					onChange={(e) => handleChange(e.target.value)}
					onBlur={(e) => handleBlurHandler(e.target.value, e)}
					disabled={disabled}
					onFocus={(e) => handleFocusHandler(labelKey, e)}
					onKeyPress={(e) => handleKeyPress(e.key, e.target.value)}
				/>
				{error.icon}
			</div>
			{maximumCharacterLimit ? <p className="primary-input-hint-text">{`${defaultValue?.length ?? 0}/${maximumCharacterLimit}`}</p> : null}
			{error?.errorMessage ? <p className="primary-input-error-message">{t(error.errorMessage)}</p> : null}
		</div>
	);
}

export default PrimaryInput;
