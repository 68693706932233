import {createSlice} from '@reduxjs/toolkit';
import {fetchBoothsForEvent, getAttendee, getEventMapOutline, getEventBanner, getEvents, getSessionsInEventWhereUserIsAttendee} from '../api/event';
import {
	backgroundImageUpdated,
	enableBottomCard,
	mapLoadingReceived,
	updateBottomCardData,
	updateHighlightEntityData,
} from './bookingProcess/mapView';
import {availableEntitiesLoadingUpdated} from './bookingProcess/entitiesBooking';

const slice = createSlice({
	name: 'events',
	initialState: {
		eventList: null,
		sessions: null,
		loadingData: false,
		userCheckedIntoEvent: false,
		agendaPosition: null,
		selectedAttendeeFilters: [],
	},
	reducers: {
		eventsUpdated: (events, action) => {
			events.eventList = action.payload;
		},
		selectedEventUpdated: (events, action) => {
			events.selectedEvent = action.payload;
		},
		sessionsUpdated: (events, action) => {
			events.sessions = action.payload;
			events.loadingData = false;
		},
		loadingDataChanged: (events, action) => {
			events.loadingData = action.payload;
		},
		userCheckedIntoEventChanged: (events, action) => {
			events.userCheckedIntoEvent = action.payload;
		},
		agendaPositionChanged: (events, action) => {
			events.agendaPosition = action.payload;
		},
		selectedAttendeeFiltersChanged: (events, action) => {
			events.selectedAttendeeFilters = action.payload;
		},
		boothsListUpdated: (events, action) => {
			events.booths = action.payload;
		},
		attendeeDataUpdated: (events, action) => {
			events.attendeeData = action.payload;
		},
	},
});

export const {
	eventsUpdated,
	selectedEventUpdated,
	sessionsUpdated,
	loadingDataChanged,
	userCheckedIntoEventChanged,
	agendaPositionChanged,
	selectedAttendeeFiltersChanged,
	boothsListUpdated,
	attendeeDataUpdated,
} = slice.actions;

export default slice.reducer;

export const loadEventList = () => async (dispatch, getState) => {
	try {
		const eventsResponse = await getEvents();
		const biroEvents = eventsResponse.data.filter(event => event?.biro?.enabled);
		dispatch({type: eventsUpdated.type, payload: biroEvents});
	} catch (error) {
		console.log(error);
	}
};

export const setSelectedEvent = (event) => async (dispatch, getState) => {
	try {
		dispatch({type: selectedEventUpdated.type, payload: event});
		dispatch(setEventAttendee(event));
		if (!event?.banner) {
			getEventBanner(event._id)
				.then((response) => {
					if (response.status === 204) {
						throw Error('not found');
					}
					const banner = window.URL.createObjectURL(new Blob([response.data]));
					const copyObj = {...event};
					copyObj.banner = banner;
					dispatch({type: selectedEventUpdated.type, payload: copyObj});
				})
				.catch((error) => {
					console.log(error);
				});
		}
	} catch (error) {
		console.log(error);
	}
};

export const loadEventSessionsOfUser = (eventId) => async (dispatch, getState) => {
	if (!eventId) return;
	dispatch({type: loadingDataChanged.type, payload: true});
	try {
		const sessionsResponse = await getSessionsInEventWhereUserIsAttendee(eventId);
		dispatch({type: sessionsUpdated.type, payload: sessionsResponse.data});
	} catch (error) {
		console.log(error);
	}
};

export const checkIfUserIsCheckedIntoEvent = (eventId) => async (dispatch, getState) => {
	if (!eventId) return;
	try {
		const attendeeResponse = await getAttendee(eventId);
		console.log(attendeeResponse);
		if (attendeeResponse?.data?.checkedIn) {
			dispatch({type: userCheckedIntoEventChanged.type, payload: true});
		} else {
			dispatch({type: userCheckedIntoEventChanged.type, payload: false});
		}
	} catch (error) {
		console.log(error);
		dispatch({type: userCheckedIntoEventChanged.type, payload: false});
	}
};

export const setAgendaPosition = (position) => async (dispatch, getState) => {
	try {
		dispatch({type: agendaPositionChanged.type, payload: position});
	} catch (error) {
		console.log(error);
	}
};

export const updateSelectedAttendeeFilters = (selectedFilters) => async (dispatch, getState) => {
	try {
		dispatch({type: selectedAttendeeFiltersChanged.type, payload: selectedFilters});
	} catch (error) {
		console.log(error);
	}
};

export const loadEventBackgroundImage = (eventId) => async (dispatch, getState) => {
	dispatch({type: mapLoadingReceived.type, payload: true});

	const selectedEvent = getState().events.selectedEvent;
	let mapUrl = 'no-map';
	const finishLoadMap = () => {
		dispatch({type: backgroundImageUpdated.type, payload: mapUrl});
		dispatch({type: mapLoadingReceived.type, payload: false});
	};

	if (!selectedEvent?.mapUrl) {
		getEventMapOutline(selectedEvent._id)
			.then((response) => {
				if (response.status === 204) {
					throw Error('not found');
				}
				mapUrl = window.URL.createObjectURL(new Blob([response.data]));
				finishLoadMap();
			})
			.catch((error) => {
				mapUrl = 'no-map';
				finishLoadMap();
			});
	} else if (selectedEvent?.mapUrl && selectedEvent?.mapUrl !== 'no-map') {
		mapUrl = selectedEvent?.mapUrl;
		finishLoadMap();
	} else if (selectedEvent?.mapUrl === 'no-map') {
		finishLoadMap();
	}
};

export const showBoothOnMap = (booth, bottomCardType) => async (dispatch, getState) => {
	const selectedEvent = getState().events.selectedEvent;
	dispatch(addBoothsForSelectedEvent(selectedEvent._id));
	dispatch(updateHighlightEntityData({entity: booth}));

	dispatch(enableBottomCard(bottomCardType));
	dispatch(updateBottomCardData({entity: booth}));
};

export const addBoothsForSelectedEvent = (eventId) => async (dispatch, getState) => {
	dispatch({type: availableEntitiesLoadingUpdated.type, payload: true});
	dispatch(loadEventBackgroundImage(eventId));
	dispatch(getBoothsForEvent(eventId));
	dispatch({type: availableEntitiesLoadingUpdated.type, payload: false});
};

export const getBoothsForEvent = (eventId) => async (dispatch, getState) => {
	try {
		const booths = await fetchBoothsForEvent(eventId);
		dispatch({type: boothsListUpdated.type, payload: booths.data});
	} catch (error) {
		console.log(error);
	}
};

export const updateEventMapUrl = (mapUrl) => async (dispatch, getState) => {
	const selectedEvent = getState().events.selectedEvent;
	const {...copyObject} = selectedEvent;
	copyObject.mapUrl = mapUrl;
	dispatch({type: selectedEventUpdated.type, payload: copyObject});
};

export const loadEventBanner = (eventId) => async (dispatch, getState) => {
	const eventList = [...getState().events.eventList];
	const eventIndex = eventList.findIndex((event) => event._id === eventId);
	if (!eventList[eventIndex]?.banner) {
		getEventBanner(eventList[eventIndex]._id)
			.then((response) => {
				if (response.status === 204) {
					throw Error('not found');
				}
				const banner = window.URL.createObjectURL(new Blob([response.data]));
				const copyObj = {...eventList[eventIndex]};
				copyObj.banner = banner;
				eventList[eventIndex] = copyObj;
				dispatch({type: eventsUpdated.type, payload: eventList});
			})
			.catch((error) => {
				console.log(error);
			});
	}
};

export const setEventAttendee = (event) => async (dispatch, getState) => {
	const attendeeResponse = await getAttendee(event._id);
	dispatch({type: attendeeDataUpdated.type, payload: attendeeResponse.data});
};
