import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BOTTOM_MAP_CARDS } from '../../constants/bottomCardTypes';
import { ROUTES } from '../../constants/routes';
import { CARD } from '../../constants/cards';

import { showEntityOnMap, updateSelectedEntity } from '../../store/bookingProcess/entitiesBooking';
import { enableCard, updateCardAndData } from '../../store/cards';

const NewBookingMenu = (props) => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.auth.data.actionSheetData);
	const attendeeListFromStore = useSelector((state) => state.entitiesBooking.attendeeList);
	const attendeeListLength = attendeeListFromStore?.length ?? 0;

	const approvalRequired = data?.entity?.approvalRequired?.enabled;
	const history = useHistory();
	const redirectToBookingConfirmation = (e) => {
		e.stopPropagation();
		props.closeActionSheet();
		if (data?.entity?.maxOccupancy < attendeeListLength + 1) {
			dispatch(enableCard(true));
			dispatch(updateCardAndData(CARD.HANDLE_NOT_FITTING_CAPACITY, data));
		} else {
			dispatch(updateSelectedEntity(data.entity));
			history.push(ROUTES.BOOKING_CONFIRM);
		}
	};

	const handleShowOnMap = () => {
		if (data?.entity) {
			history.push(ROUTES.BOOKING_PAGE);
			dispatch(showEntityOnMap(data?.entity, BOTTOM_MAP_CARDS.entityData));
			props.closeActionSheet();
		}
	};

	const getSelectedArea = (areaId, areaList) => {
		let selectedArea;
		areaList.forEach((area) => {
			if (area.children.length > 0) {
				const result = getSelectedArea(areaId, area.children);
				if (result) selectedArea = result;
			}
			if (areaId === area?._id) {
				selectedArea = area;
			}
		});
		return selectedArea;
	};

	function redirectToEntityDetails() {
		history.push(ROUTES.ENTITY_DETAILS, data.entity);
	}

	return (
		<>
			<div className="action-sheet-option" onClick={redirectToBookingConfirmation}>
				{approvalRequired ? t('common.requestBooking') : t('common.bookNow')}
			</div>
			<div className="action-sheet-option" onClick={handleShowOnMap}>
				{t('common.showOnMap')}
			</div>
			{data.entity?.numberOfPhotos > 0 && (
				<div className="action-sheet-option" onClick={redirectToEntityDetails}>
					{t('common.seeDetails')}
				</div>
			)}
		</>
	);
};

export default NewBookingMenu;
