import Icon from '../Icon';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';

/**
 *
 * @param {*} props.cssClass = css class added to the chip element
 * @param {*} props.name = name displayed in chip
 * @param {*} props.active = boolean to activate or not the chip
 * @param {*} props.disabled = boolean to disable the chip
 * @param {*} props.hasXMark = boolean to add xMark or not
 * @param {*} props.iconCss = css class added to the icon
 * @param {*} props.iconName = icon that needs to be displayed
 * @param {*} props.onClick = onClick callback
 * @returns A chip that holds a text and icon. It has different colors when active and can be disabled. Usually used for displaying features of entities.
 */

function Chip({disabled, active, onClick, iconName, iconCss, name, hasXmark}) {
	const colors = colorBasedOnTheme();
	return (
		<div className={'chip ' + (disabled ? 'disabled-chip' : active ? 'active-chip' : 'inactive-chip')} onClick={onClick}>
			{iconName ? (
				<div className="chip-icon-wrapper">
					<Icon iconName={iconName} cssClass={iconCss} iconColor={active ? colors.colorMainTheme : colors.colorNeutral60} />
				</div>
			) : null}
			<span> {name} </span>
			{hasXmark ? <Icon iconName="xmark" cssClass="icon-active" /> : null}
		</div>
	);
}

export default Chip;
