import { useSelector } from 'react-redux';
import CardPlaceholder from './Common/CardPlaceholder';
import UserReview from './Common/UserReview';
import SuccessCard from './Common/SuccessCard';
import ErrorCard from './Common/ErrorCard';
import ReportProblem from './Common/ReportProblem';
import CheckInCard from './Common/CheckInCard';
import SummaryReportFacilityProblem from './ReportProblem/SummaryReportFacilityProblem';
import SummaryAppReportProblem from './ReportProblem/SummaryAppReportProblem';
import SummaryReportProblem from './ReportProblem/SummaryReportProblem';
import MemberCard from './MemberBadge/MemberCard';
import MemberCardPlaceholder from './MemberBadge/MemberCardPlaceholder';
import ConfirmBookingConflicts from './Common/RecurringBooking/ConfirmBookingConflicts';
import QuickTourInfo from './Common/QuickTourInfo';
import CeylonInfo from './Ceylon/CeylonInfo';
import CeylonProcess from './Ceylon/CeylonProcess';
import CeylonFailedValidation from './Ceylon/CeylonFailedValidation';
import ConfirmCheckin from './Common/ConfirmAction/ConfirmCheckin';
import ExtendTime from './Common/ExtendTime';
import BroadcastNotificationCard from './Common/BroadcastNotificationCard';
import NotFoundCard from './Common/NotFoundCard';
import EmergencyCard from './Common/EmergencyCard';
import PaymentIntent from './Payment/PaymentIntent';
import PaymentRedirect from './Payment/PaymentRedirect';
import UnlockNukiCard from './Common/UnlockNukiCard';
import EventFeedback from './Common/EventFeedback';
import HandleNotFittingCapacity from './Common/HandleNotFittingCapacity';
import ViewOfficeHours from './Common/ViewOfficeHours';
import CheckinDisabled from './Common/CheckinDisabled';
import ConfirmActionCard from './Common/ConfirmAction/ConfirmActionCard';
import BoothAlreadyScannedCard from './Common/BoothAlreadyScannedCard';
import TimeTrackingCard from './Cedar/TimeTrackingCard';
import TimeTrackingChangeRequestCard from './Common/TimeTracking/TimeTrackingChangeRequestCard';
import TimeTrackingOfficeChangeCard from './Common/TimeTracking/TimeTrackingOfficeChangeCard';
import TimeTrackingWorkingSummaryCard from './Common/TimeTracking/TimeTrackingWorkingSummaryCard';
import UserImage from './Common/UserImage';
import HandleAcceptDecisionInvite from './Common/HandleAcceptDecisionInvite';


export const cardMap = {
	reportProblem: ReportProblem,
	cardPlaceholder: CardPlaceholder,
	checkInCard: CheckInCard,
	memberBadgePlaceholder: MemberCardPlaceholder,
	memberBadge: MemberCard,
	mainSuccess: SuccessCard,
	mainError: ErrorCard,
	summaryReportProblem: SummaryReportProblem,
	summaryAppReportProblem: SummaryAppReportProblem,
	summaryReportFacilityProblem: SummaryReportFacilityProblem,
	userReview: UserReview,
	confirmRecurringConflicts: ConfirmBookingConflicts,
	quickTourInfo: QuickTourInfo,
	ceylonInfo: CeylonInfo,
	ceylonProcess: CeylonProcess,
	ceylonFailedValidation: CeylonFailedValidation,
	confirmCheckin: ConfirmCheckin,
	extendTime: ExtendTime,
	broadcastNotificationCard: BroadcastNotificationCard,
	notFoundCard: NotFoundCard,
	emergencyCard: EmergencyCard,
	paymentIntent: PaymentIntent,
	paymentRedirect: PaymentRedirect,
	unlockNukiCard: UnlockNukiCard,
	eventFeedback: EventFeedback,
	viewOfficeHours: ViewOfficeHours,
	handleNotFittingCapacity: HandleNotFittingCapacity,
	checkinDisabled: CheckinDisabled,
	confirmAction: ConfirmActionCard,
	boothAlreadyScanned: BoothAlreadyScannedCard,
	timeTrackingChangeRequest: TimeTrackingChangeRequestCard,
	timeTrackingCard: TimeTrackingCard,
	timeTrackingOfficeChange: TimeTrackingOfficeChangeCard,
	timeTrackingWorkingSummary: TimeTrackingWorkingSummaryCard,
	userImage: UserImage,
	handleAcceptDecisionInvite: HandleAcceptDecisionInvite
};

const Card = () => {
	const cardToShow = useSelector((state) => state.cards.cardToShow);
	const show = useSelector((state) => state.cards.showCard);

	let showCard = show ? 'modal-card show' : 'modal-card';
	let CardComponent = cardMap[cardToShow];

	return (
		<div className={showCard}>
			<CardComponent />
		</div>
	);
};

export default Card;
