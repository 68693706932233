import {useTranslation} from 'react-i18next';
import Icon from '../../Common/Icon';

function SettingsItem(props) {
	const {t} = useTranslation();

	return (
		<div className="settings-item">
			<div className="settings-item-card" onClick={props.onClick}>
				<div className="settings-item-card-text">
					{props.iconName ? (
						<Icon cssClass={'settings-item-left-icon icon-purple ' + props.iconCssClass} iconName={props.iconName} />
					) : null}

					<p className="settings-item-heading">{t(props.heading)}</p>
				</div>
				<Icon cssClass={'settings-item-right-icon'} iconName={'chevron-right'} />
			</div>
		</div>
	);
}

export default SettingsItem;
