import {DateTime} from 'luxon';
import {getTeakEntity} from '../api';
import {CARD} from '../constants/cards';
import {enableCard, updateErrorCard} from '../store/cards';
import {addNewOfficeData} from '../store/auth';
import {updateActionSheet, updateActionSheetData} from '../store/auth';
import {ACTIONSHEET} from '../constants/actionSheets';

function processEntityQRScanned(dispatch, identifier, officesInStore, userData, history) {
	dispatch(updateActionSheet(ACTIONSHEET.PLACEHOLDER));
	if (officesInStore?.length === 0) return;

	getTeakEntity(undefined, identifier)
		.then((response) => {
			const office = officesInStore.find((office) => office._id === response.data?.teakEntityDoc.office._id);
			if (!office?.hasAllData) {
				dispatch(addNewOfficeData(office, false));
			}
			const teakEntityDoc = response.data?.teakEntityDoc;
			const teakBookingsForEntity = response.data?.teakBookingDocs || [];
			if (teakBookingsForEntity)
				teakBookingsForEntity.sort((a, b) => DateTime.fromISO(a?.start.time).toMillis() - DateTime.fromISO(b?.start.time).toMillis());
			if (!office) {
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'qrScanner.officeNotInStore', null, 'exclamation-circle', 'icon-red', 'common.okButton'));
				dispatch(enableCard(true));
				return;
			}
			//there are any booking for member if the booking member is the user or if the user is an attendee and did not checkout or cancelled.
			const bookingOfMembers = teakBookingsForEntity.filter(
				(booking) =>
					booking?.member === userData?.id ||
					booking?.attendees?.some((attendee) => attendee.member === userData._id && !attendee.checkOut && !attendee?.cancelled),
			);
			dispatch(
				updateActionSheetData({
					nextBookingOfMember: bookingOfMembers[0] ? bookingOfMembers[0] : null,
					entity: teakEntityDoc,
					teakBookingsForEntity,
				}),
			);
			dispatch(updateActionSheet(ACTIONSHEET.QR_CODE_SCANNER_MENU));
		})
		.catch((error) => {
			console.log(error);
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'qrScanner.deskNotFound', error));
			dispatch(enableCard(true));
		});
}

export default processEntityQRScanned;
