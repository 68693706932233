import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {enableCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import {checkInNow} from '../../../api';
import {CARD} from '../../../constants/cards';
import {loadDashboardData} from '../../../store/dashboard';
import {loadNextBookings} from '../../../store/bookingsOverview/bookings';
import {ROUTES} from '../../../constants/routes';

function CheckInCard(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const data = useSelector((state) => state.cards.data);
	const validCeylon = useSelector((state) => state.dashboard.data.validCeylon);
	const officesFromStore = useSelector((state) => state.auth.data.offices);
	const officeId = data.entity?.office?._id ? data.entity.office._id : data.entity.office;
	let officeFromData = officesFromStore.find((office) => office._id === officeId);

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	const checkInIntoBooking = () => {
		if (officeFromData?.ceylon?.enabled && !validCeylon && officeFromData?.ceylon?.rule !== 'none') {
			history.push(ROUTES.NO_CERTIFICATE_FOR_CHECKIN);
			dispatch(enableCard(false));
			return;
		}

		checkInNow(data.booking._id)
			.then((response) => {
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'checkOutCard.successText'));
				dispatch(loadDashboardData(true));
				dispatch(loadNextBookings(true));
			})
			.catch((error) => {
				console.log(error);
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'errorMessages.general', error));
			});
	};

	useEffect(() => {
		checkInIntoBooking();
	});

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="card-content">
				<div className="placeholder-container">
					<div className="ph-item">
						<div className="ph-row">
							<div className="ph-col-12"></div>
						</div>
						<div className="ph-row ph-section">
							<div className="ph-col-12"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CheckInCard;
