import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { getPineInvitationsInRange, postPineInvitation } from '../api/visitorManagement';
import { CARD } from '../constants/cards';
import { roundTimeQuarterHour } from '../functions/roundTimeToNextQuarter';
import { loadNextBookings } from './bookingsOverview/bookings';
import { enableCard, updateErrorCard, updateSuccessCard } from './cards';

const slice = createSlice({
	name: 'visitorManagement',
	initialState: {
		pineInvitationsList24Hrs: [],
		lastFetchOfPineInvitations24Hrs: null,
		searchValue: null,
		newMeetingStartTime: roundTimeQuarterHour(DateTime.now().plus({ minutes: 10 }).toISO()),
		newMeetingEndTime: roundTimeQuarterHour(DateTime.now().plus({ hours: 1, minutes: 10 }).toISO()),
		newMeetingDetails: null,
		newMeetingVisitorList: null,
		isRoomBookingOn: false,
		ui: {
			loadingPineInvitationsListNext24Hrs: false,
			showVisitorDetails: true,
			showBookingPageContent: false,
		},
	},
	reducers: {
		pineInvitationsList24HrsReceived: (visitorManagement, action) => {
			visitorManagement.pineInvitationsList24Hrs = action.payload.pineInviations;
		},
		lastFetchOfPineInvitations24HrsReceived: (visitorManagement, action) => {
			visitorManagement.lastFetchOfPineInvitations24Hrs = action.payload;
		},
		newMeetingStartTimeChanged: (visitorManagement, action) => {
			visitorManagement.newMeetingStartTime = action.payload;
		},
		newMeetingEndTimeChanged: (visitorManagement, action) => {
			visitorManagement.newMeetingEndTime = action.payload;
		},
		showVisitorDetailsReceived: (visitorManagement, action) => {
			visitorManagement.ui.showVisitorDetails = action.payload;
		},
		loadingPineInvitationsNext24HrsReceived: (visitorManagement, action) => {
			visitorManagement.ui.loadingPineInvitationsListNext24Hrs = action.payload;
		},
		visitorListUpdated: (visitorManagement, action) => {
			visitorManagement.newMeetingVisitorList = action.payload;
		},
		newMeetingDetailsUpdated: (visitorManagement, action) => {
			visitorManagement.newMeetingDetails = action.payload;
		},
		visitorShowBookingPageUpdated: (visitorManagement, action) => {
			visitorManagement.ui.showBookingPageContent = action.payload;
		},
		isRoomBookingOnUpdated: (visitorManagement, action) => {
			visitorManagement.isRoomBookingOn = action.payload;
		},
	},
});

export const {
	pineInvitationsList24HrsReceived,
	lastFetchOfPineInvitations24HrsReceived,
	reservationsReceived,
	showVisitorDetailsReceived,
	loadingPineInvitationsNext24HrsReceived,
	visitorListUpdated,
	newMeetingStartTimeChanged,
	newMeetingEndTimeChanged,
	newMeetingDetailsUpdated,
	visitorShowBookingPageUpdated,
	isRoomBookingOnUpdated,
} = slice.actions;

export default slice.reducer;

export const loadPineInviationsListNext24Hours = (forceReload, officeId) => async (dispatch, getState) => {
	if (!forceReload) {
		const lastFetchOfData = getState().visitorManagement.lastFetchOfPineInvitations24Hrs;
		if (lastFetchOfData && DateTime.now().toUTC() < DateTime.fromISO(lastFetchOfData).toUTC().plus({ minutes: 10 })) return;
	}

	dispatch({
		type: loadingPineInvitationsNext24HrsReceived.type,
		payload: true,
	});

	try {
		const selectedOffice = getState().auth.data.selectedOffice;
		const startTime = DateTime.now().setZone(selectedOffice.timezone).startOf('day').toUTC().toISO();
		const endTime = DateTime.now().setZone(selectedOffice.timezone).endOf('day').toUTC().toISO();

		const pineInvitationsInRangeResponse = await getPineInvitationsInRange(officeId ? officeId : selectedOffice.id, startTime, endTime);
		const pineInvitationsInRange = pineInvitationsInRangeResponse.data;

		const filteredPineInvitationsInRange = pineInvitationsInRange.sort((a, b) => {
			return DateTime.fromISO(a.start) - DateTime.fromISO(b.start);
		});

		dispatch({
			type: pineInvitationsList24HrsReceived.type,
			payload: { pineInviations: filteredPineInvitationsInRange },
		});
		dispatch({
			type: lastFetchOfPineInvitations24HrsReceived.type,
			payload: DateTime.now().toISO(),
		});
		dispatch({
			type: loadingPineInvitationsNext24HrsReceived.type,
			payload: false,
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: pineInvitationsList24HrsReceived.type,
			payload: [],
		});
		dispatch({
			type: loadingPineInvitationsNext24HrsReceived.type,
			payload: false,
		});
	}
};

export const changeNewMeetingStartTime = (startTime) => async (dispatch, getState) => {
	if (getState().visitorManagement.newMeetingStartTime === startTime) return;

	dispatch({ type: newMeetingStartTimeChanged.type, payload: startTime });
};

export const updateVisitorShowBookingPage = (value) => async (dispatch, getState) => {
	if (getState().visitorManagement.ui.showBookingPageContent === value) return;

	dispatch({ type: visitorShowBookingPageUpdated.type, payload: value });
};

export const changeIsRoomBookingOn = (value) => async (dispatch, getState) => {
	if (getState().visitorManagement.isRoomBookingOn === value) return;

	dispatch({ type: isRoomBookingOnUpdated.type, payload: value });
};

export const changeNewMeetingEndTime = (endTime) => async (dispatch, getState) => {
	if (getState().visitorManagement.newMeetingEndTime === endTime) return;

	dispatch({ type: newMeetingEndTimeChanged.type, payload: endTime });
};

export const enableShowVisitorDetails = (show) => async (dispatch, getState) => {
	if (getState().visitorManagement.ui.showVisitorDetails === show) return;

	dispatch({ type: showVisitorDetailsReceived.type, payload: show });
};

export const addVisitorInNewMeetingList = (visitor) => async (dispatch, getState) => {
	const currentVisitorList = getState().visitorManagement.newMeetingVisitorList ?? [];
	dispatch({
		type: visitorListUpdated.type,
		payload: [...currentVisitorList, visitor],
	});
};

export const removeVisitorFromNewMeetingList = (visitor) => async (dispatch, getState) => {
	let currentVisitorList = getState().visitorManagement.newMeetingVisitorList.slice();

	// if there is a visitor with the same email in the list, remove it
	let indexOfRemovedVisitor = currentVisitorList.findIndex((currVisitor) => currVisitor.email === visitor.email);
	if (indexOfRemovedVisitor > -1) {
		currentVisitorList.splice(indexOfRemovedVisitor, 1);
		dispatch({
			type: visitorListUpdated.type,
			payload: currentVisitorList,
		});
	}
};

export const updateVisitorInNewMeetingList = (visitor) => async (dispatch, getState) => {
	let currentVisitorList = getState().visitorManagement.newMeetingVisitorList.slice();
	let indexOfEditedVisitor = currentVisitorList.findIndex((currVisitor) => currVisitor.email === visitor.email);
	if (indexOfEditedVisitor > -1) {
		currentVisitorList[indexOfEditedVisitor] = visitor;
		dispatch({
			type: visitorListUpdated.type,
			payload: currentVisitorList,
		});
	}
};

export const editVisitorList = (list) => async (dispatch, getState) => {
	dispatch({ type: visitorListUpdated.type, payload: list });
}

export const sumbitNewPineInvitation = (summary, description, attendees, teakEntityId, returnToDashboard) => async (dispatch, getState) => {
	dispatch(enableCard(true));
	const selectedOffice = getState().auth.data.selectedOffice;
	const selectedStartTime = DateTime.fromISO(getState().visitorManagement.newMeetingStartTime).toUTC().toISO();
	const selectedEndTime = DateTime.fromISO(getState().visitorManagement.newMeetingEndTime).toUTC().toISO();

	postPineInvitation(summary, selectedStartTime, selectedEndTime, selectedOffice._id, attendees, description, teakEntityId)
		.then((response) => {
			dispatch(loadPineInviationsListNext24Hours(true));
			dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'visitorManagement.newMeeting.meetingCreatedSuccess'));
			returnToDashboard();
			dispatch(loadNextBookings(true));
		})
		.catch((error) => {
			console.log(error);
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'errorMessages.general', error));
		});
};

export const updateMeetingDetails = (values) => async (dispatch, getState) => {
	const currentMeetingDetails = getState().visitorManagement.newMeetingDetails;
	if (currentMeetingDetails === values) return;

	dispatch({ type: newMeetingDetailsUpdated.type, payload: values });
};

export const emptyVisitorMeetingDetails = () => async (dispatch, getState) => {
	dispatch({ type: newMeetingDetailsUpdated.type, payload: null });
	dispatch({ type: isRoomBookingOnUpdated.type, payload: false });
	dispatch({ type: visitorListUpdated.type, payload: [] });
	dispatch({ type: visitorShowBookingPageUpdated.type, payload: false });
};
//selectors
