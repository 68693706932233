import {ButtonBase, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import Icon from '../../Common/Icon';
import {useDispatch, useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import FormattedTime from '../../Common/DateTimePicker/FormattedTime';
import FormattedDate from '../../Common/DateTimePicker/FormattedDate';
import {ACTIONSHEET} from '../../../constants/actionSheets';
import {updateActionSheet, updateActionSheetData} from '../../../store/auth';

function ChangeRequest({changeRequest}) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const selectedOfficeTimezone = selectedOffice.timezone;

	const getIconDetails = () => {
		switch (changeRequest.status) {
			case 'pending':
				return {cssClass: 'tt-cr-icon tt-cr-icon-pending', iconName: 'fa-regular fa-clock'};
			case 'declined':
				return {cssClass: 'tt-cr-icon tt-cr-icon-declined', iconName: 'fa-regular fa-circle-xmark'};
			case 'accepted':
			default:
				return {cssClass: 'tt-cr-icon tt-cr-icon-accepted', iconName: 'fa-regular fa-circle-check'};
		}
	};

	const showChangeRequestActionCard = () => {
		dispatch(updateActionSheet(ACTIONSHEET.CHANGE_REQUEST_MENU));
		dispatch(updateActionSheetData(changeRequest));
	};

	const {cssClass, iconName} = getIconDetails();

	if (changeRequest?.hidden) {
		return <></>;
	}

	return (
		<ButtonBase component="div" className="tt-cr-card" onClick={() => showChangeRequestActionCard(changeRequest)}>
			<Icon cssClass={cssClass} iconName={iconName} size="lg" />
			<div className="tt-cr-content">
				<Typography variant="h6" component="div" className="tt-cr-title">
					<b>
						{changeRequest.action === 'delete' ? t('timeTracker.changeRequest.deletionRequest') :
							changeRequest.action === 'add' ? t('timeTracker.changeRequest.additionRequest') :
								t('timeTracker.changeRequest.name')}
					</b>
				</Typography>
				<Box className="tt-cr-details">
					<Typography variant="body1" color="textSecondary">
						<>
							{changeRequest.action === "add" ? (
								<>
									<b>{t('timeTracker.changeRequest.for')}: </b>
									<FormattedDate date={changeRequest?.update?.start?.time} timezone={selectedOfficeTimezone} />
									<FormattedTime time={changeRequest?.update?.start?.time} timezone={selectedOfficeTimezone} />
								</>
							) : (
								<>
									<b>{t('timeTracker.changeRequest.from')}: </b>
									<FormattedDate date={changeRequest?.cedarRecord?.start?.time} timezone={selectedOfficeTimezone} />
									<FormattedTime time={changeRequest?.cedarRecord?.start?.time} timezone={selectedOfficeTimezone} />
								</>
							)}
						</>
					</Typography>
				</Box>
			</div>
			<div className="action-wrapper">
				<Icon iconName={'chevron-right'} cssClass={'icon-grey'} />
			</div>
		</ButtonBase>
	);
}

export default ChangeRequest;
