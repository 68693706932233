import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROUTES } from '../../constants/routes';
import { updateConfirmationCard } from '../../store/cards';
import { confirmActionsMap } from '../Cards/Common/ConfirmAction/confirmActions';

const VisitorMeetingMenu = (props) => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.auth.data.actionSheetData);
	const history = useHistory();

	const redirectToMeetingDetails = (e) => {
		e.stopPropagation();
		props.closeActionSheet();
		history.push({
			pathname: ROUTES.VISITOR.MEETING_SUMMARY,
			state: {
				meetingDetails: {
					summary: data?.summary,
					start: data?.start,
					end: data?.end,
					attendees: data?.attendees?.map((attende) => attende.pineVisitor),
					description: data?.description,
					pineInvitationId: data?._id,
					entityData: data?.teakBooking?.teakEntity,
				},
				cancelMeeting: true,
			},
		});
	};

	const editMeeting = (e) => {
		e.stopPropagation();
		props.closeActionSheet();

		const booking = {
			...data,
			...data?.teakBooking,
			pineInvitationId: data._id,
			meetingName: data.summary,
			start: {
				time: data?.start
			},
			end: {
				time: data?.end
			},
		};

		const visitorList = booking?.attendees.map((attendee) => attendee?.pineVisitor) ?? [];

		history.push(ROUTES.EDIT_BOOKING, { booking: booking, bookingType: 'visitorBooking', visitorList: visitorList });
	}

	const cancelMeeting = (e) => {
		e.stopPropagation();
		props.closeActionSheet();
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.DELETE_PINE_INVITATION,
				{
					headerKey: 'visitorManagement.cancelMeeting',
					infoKey: 'visitorManagement.cancelMeetingInfo',
					confirmButtonKey: 'common.buttons.wantToCancel',
					closeButtonKey: 'common.buttons.takeBack',
				},
				{ pineInvitationId: data?._id },
			),
		);
	};

	return (
		<>
			<div className="action-sheet-option" onClick={redirectToMeetingDetails}>
				{t('visitorManagement.checkMeetingDetails')}
			</div>
			<div className="action-sheet-option" onClick={editMeeting}>
				{t('visitorManagement.editMeeting')}
			</div>
			<div className="action-sheet-option" onClick={cancelMeeting}>
				{t('visitorManagement.cancelMeeting')}
			</div>
		</>
	);
};

export default VisitorMeetingMenu;
