

/**
 *
 * @param {*} onChange = callback when checkbox was changed
 * @param {*} id = id of input
 * @param {*} checked = if checkbox is checked or not
 * @returns  a custom checkbox component that shows a checmark inside of it if its enabled
 */
function CustomCheckbox({onChange, id, checked}) {
	return (
		<div className="rounded-checkbox-wrapper" onClick={onChange}>
			<input
				type="checkbox"
				className="rounded-checkbox"
				id={id}
				checked={checked}
				readOnly={true} // read only because onChange is added to the wrapper div
			/>
			<i className="fas fa-check"></i>
		</div>
	);
}

export default CustomCheckbox;
