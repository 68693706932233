import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { enableCard } from '../../../store/cards';
import Button from '../../Common/Button';

function UserImage(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth.data);
  const cardsData = useSelector((state) => state.cards.data);
  const booking = cardsData.bottomCardData.booking;
  const entity = cardsData.bottomCardData.entity;
  console.log("CARDS DATA: ", cardsData);
  const closeCard = async () => {
    dispatch(enableCard(false));
  };

  return (
    <div className="user-image-card-backdrop" onClick={closeCard}>
      <div className="user-image-card-content centered-absolute">
        <img className="colleague-image" src={booking?.profilePhoto} alt="member or colleague" />

        <Button
          variant={'primary'}
          height={'regular'}
          width={'variable'}
          translationKey={'timeTracker.workSummary.close'}
          clickHandler={closeCard}
        />
      </div>
    </div>
  );
}

export default UserImage;
