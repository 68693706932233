import { t } from 'i18next';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { checkinIntoSession } from '../../api/event';
import { CARD } from '../../constants/cards';
import { ROUTES } from '../../constants/routes';
import { updateActionSheet } from '../../store/auth';
import { enableCard, updateCardAndData, updateErrorCard, updateSuccessCard } from '../../store/cards';
import { loadEventSessionsOfUser, showBoothOnMap } from '../../store/events';
import { BOTTOM_MAP_CARDS } from '../../constants/bottomCardTypes';

const SessionMenu = () => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.auth.data.actionSheetData);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const isUserCheckedIntoEvent = useSelector((state) => state.events.userCheckedIntoEvent);
	const timezone = selectedOffice?.timezone;
	const history = useHistory();
	const rateSessionAfterEnd = data?.rateSessionAfterEnd ? Math.abs(data?.rateSessionAfterEnd / 60000 / 60) : 8; //show rate your session after end of event -> amount in hrs
	const rateSessionBeforeEnd = data?.rateSessionBeforeEnd ? Math.abs(data?.rateSessionBeforeEnd / 60000) : 15; //show rate your session if end time is in X mins -> amount in mins
	const checkInAfterEnd = data?.checkInAfterEnd ? Math.abs(data?.checkInAfterEnd / 60000) : 30; //show check in after end time is in X min -> amount in mins
	const checkInBeforeStart = data?.checkInBeforeStart ? Math.abs(data?.checkInBeforeStart / 60000) : 30; //show check in if start time is in X min -> amount in mins
	const enableQandA = data?.enableQandA;
	const enableCheckins = data?.enableCheckins;
	const enableRatings = data?.enableRatings;
	const sessionStartTime = DateTime.fromISO(data?.start).setZone(timezone);
	const sessionEndTime = DateTime.fromISO(data?.end).setZone(timezone);
	const currentTime = DateTime.now().setZone(timezone);

	function redirectToSessionDetails() {
		history.push(ROUTES.SESSION_DETAILS, { session: data });
	}

	function redirectToAskSessionDetails() {
		history.push(ROUTES.SESSION_DETAILS, { askQuestion: true, session: data });
	}

	async function checkinHandler(e) {
		e.stopPropagation();
		if (data?.userCheckedIn) return;
		dispatch(enableCard(true));
		dispatch(updateActionSheet(false));
		try {
			await checkinIntoSession(data?.wengeEvent, data?._id);
			dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'successMessages.eventCheckedInSuccessfully', 'event.successCheckinSession'));
			dispatch(loadEventSessionsOfUser(data?.wengeEvent));
		} catch (error) {
			console.log(error);
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'event.errorCheckinSession'));
		}
	}

	function openInAppSessionFeedback(e) {
		e.stopPropagation();
		if (data?.userAlreadyRated) return;
		dispatch(enableCard(true));
		dispatch(updateCardAndData(CARD.EVENT_FEEDBACK, { wengeEvent: data?.wengeEvent, wengeSession: data }));
	}

	function isRatingSessionOpen() {
		if (!enableRatings) return false;

		if (sessionStartTime.toLocaleString(DateTime.DATE_SHORT) !== currentTime.toLocaleString(DateTime.DATE_SHORT)) {
			return false;
		}
		if (sessionStartTime > currentTime) {
			return false;
		}

		if (sessionEndTime.minus({ minutes: rateSessionBeforeEnd }) > currentTime || sessionEndTime.plus({ hours: rateSessionAfterEnd }) < currentTime) {
			return false;
		}

		return true;
	}

	function isCheckInOpen() {
		if (sessionStartTime.toLocaleString(DateTime.DATE_SHORT) !== currentTime.toLocaleString(DateTime.DATE_SHORT)) {
			return false;
		}

		if (sessionStartTime.minus({ minutes: checkInBeforeStart }) > currentTime || sessionEndTime.plus({ minutes: checkInAfterEnd }) < currentTime) {
			return false;
		}

		return true;
	}

	function isLivePoolOpen() {
		if (sessionStartTime.toLocaleString(DateTime.DATE_SHORT) !== currentTime.toLocaleString(DateTime.DATE_SHORT)) {
			return false;
		}
		if (sessionStartTime > currentTime) {
			return false;
		}
		return true;
	}

	function showSessionLocationOnMap() {
		dispatch(showBoothOnMap(data?.wengeBooth, BOTTOM_MAP_CARDS.boothData));
		history.push({
			pathname: ROUTES.MAP_VIEW,
		});
	}

	return (
		<>
			<div className="action-sheet-option" onClick={redirectToSessionDetails}>
				{t('event.actionMenu.sessionDetails')}
			</div>
			{isCheckInOpen() && enableCheckins && isUserCheckedIntoEvent && (
				<div className={`action-sheet-option ${data?.userCheckedIn ? 'checked-in-view' : ''}`} onClick={checkinHandler}>
					{t(data?.userCheckedIn ? 'event.actionMenu.attendeeCheckedIn' : 'event.actionMenu.checkInNow')}
				</div>
			)}
			{data?.cgiMeterPollUrl && isUserCheckedIntoEvent && isLivePoolOpen() && (
				<a className="action-sheet-option" href={data?.cgiMeterPollUrl} target="_blank">
					{t('event.actionMenu.cgiMeter')}
				</a>
			)}
			{enableQandA && isUserCheckedIntoEvent && (
				<div className="action-sheet-option" onClick={redirectToAskSessionDetails}>
					{t('event.actionMenu.sendQuestion')}
				</div>
			)}

			{isRatingSessionOpen() && isUserCheckedIntoEvent && (
				<div className={`action-sheet-option-main ${data?.userAlreadyRated ? 'checked-in-view' : ''}`} onClick={openInAppSessionFeedback}>
					{t(data?.userAlreadyRated ? 'event.actionMenu.userAlreadyRated' : 'event.actionMenu.feedbackForm')}
				</div>
			)}
			{data?.wengeBooth?.mapRepresentation && (
				<div className={`action-sheet-option`} onClick={showSessionLocationOnMap}>
					{t('event.actionMenu.showLocationOnMap')}
				</div>
			)}
		</>
	);
};

export default SessionMenu;
