import {useTranslation} from 'react-i18next';

/**
 *
 * @param {*} props.className = css class added to the element
 * @param {*} props.message = translation key for the message shown
 * @returns
 */
function NoResults(props) {
	const {t} = useTranslation();

	return (
		<p className={'no-results' + (props.className ? ` ${props.className}` : '')}>
			{t(props.message ? props.message : 'common.noResultsMessage')}
		</p>
	);
}

export default NoResults;
