import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Icon from '../../../Common/Icon';
import {enableCard} from '../../../../store/cards';
import Button from '../../../Common/Button';
import {ROUTES} from '../../../../constants/routes';
import {stopTimeRecord} from '../../../../api/timeTracker';
import {useState} from 'react';
import {useHistory} from 'react-router';

function TimeTrackingOfficeChangeCard(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const [loading, setLoading] = useState(false);

	const closeCard = async () => {
		dispatch(enableCard(false));
	};

	const confirmButtonHandler = async () => {
		setLoading(true);
		try {
			await stopTimeRecord({office: selectedOffice._id});
			dispatch(enableCard(false));
            history.push(ROUTES.OFFICE_SWITCHER);
		} catch (error) {
			console.log(error);
		}
		setLoading(false);

	};

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-data-privacy-icon-wrapper">
				<Icon cssClass={'icon-red'} iconName={'exclamation-circle'} />
			</div>
			<div className="confirmation-wrapper">
				<p className="success-text header">{t('timeTracker.changeOfficeCard.header')}</p>
				<div className="buttons-wrapper">
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						loading={loading}
						clickHandler={confirmButtonHandler}
						translationKey={'common.buttons.confirm'}
					/>
					<Button
						cssClass={'close-button'}
						variant={'tertiary'}
						height={'regular'}
						width={'full'}
						clickHandler={closeCard}
						translationKey={'common.closeButton'}
					/>
				</div>
			</div>
		</div>
	);
}

export default TimeTrackingOfficeChangeCard;
