import {useHistory, useLocation} from 'react-router-dom';
import {ROUTES} from '../constants/routes';

function useCustomNavigation() {
	const history = useHistory();
	const location = useLocation();
	function goBack() {
		if (location.key) {
			history.goBack();
		} else {
			history.push(ROUTES.DEFAULT);
		}
	}

	function push(path, state) {
		history.push(path, state);
	}

	function go(path) {
		history.go(path);
	}
	const value = {goBack, push, go};

	return value;
}

export default useCustomNavigation;
