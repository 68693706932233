import {createSlice} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';
import {changeSelectedTeakType} from './entitiesBooking';

const slice = createSlice({
	name: 'aspen',
	initialState: {
		selectedAspenType: null,
		selectedAspenItem: null,
		selectedDate: DateTime.now().toISO(),
		selectedDeliveryLocation: null,
		ui: {
			showSummaryPage: false,
		},
	},
	reducers: {
		selectedAspenTypeChanged: (aspen, action) => {
			aspen.selectedAspenType = action.payload;
		},
		selectedAspenItemChanged: (aspen, action) => {
			aspen.selectedAspenItem = action.payload;
		},
		showSummaryPageChanged: (aspen, action) => {
			aspen.ui.showSummaryPage = action.payload;
		},
		selectedDateChanged: (aspenRequests, action) => {
			aspenRequests.selectedDate = action.payload;
		},
		selectedDeliveryLocationChanged: (aspenRequests, action) => {
			aspenRequests.selectedDeliveryLocation = action.payload;
		},
	},
});
const {selectedAspenTypeChanged, selectedAspenItemChanged, showSummaryPageChanged, selectedDateChanged, selectedDeliveryLocationChanged} =
	slice.actions;

/**
 * @param {Object} aspenType = aspen type selected from the service type list
 * @returns add selected aspen type to store in order to show the aspen item list
 */
export const updateSelectedAspenType = (aspenType) => (dispatch, getState) => {
	dispatch({type: selectedAspenTypeChanged.type, payload: aspenType});

	if (getState().entitiesBooking.selectedTeakType) {
		dispatch(changeSelectedTeakType(null));
	}

	if (aspenType?.onlyBookableOn) {
		dispatch(changeAspenSelectedDate(aspenType?.onlyBookableOn));
	}
};

/**
 * @param {Object} aspenItem = aspen item selected from the list
 * @returns add aspen item to store
 */
export const updateSelectedAspenItem = (aspenItem) => (dispatch, getState) => {
	dispatch({type: selectedAspenItemChanged.type, payload: aspenItem});
};

/**
 * @param {boolean} enable = show summary page
 * @returns add flag to store to enable/disable summary page for aspen booking
 */
export const enableAspenSummaryPage = (enable) => (dispatch, getState) => {
	dispatch({type: showSummaryPageChanged.type, payload: enable});
};

/**
 * @param {ISO} newDate = new selectedDate from date picker
 * @returns changed the selectedDate from store
 */
export const changeAspenSelectedDate = (newDate) => (dispatch, getState) => {
	if (newDate === getState().aspen.selectedDate) return;
	dispatch({type: selectedDateChanged.type, payload: newDate});
};

/**
 *
 * @param {ISO} newDeliveryLocation = new delivery locaiton for the selected aspen item
 * @returns changed the deliveryLocation from store
 */
export const changeDeliveryLocation = (newDeliveryLocation) => (dispatch, getState) => {
	if (newDeliveryLocation === getState().aspen.selectedDeliveryLocation) return;
	dispatch({type: selectedDeliveryLocationChanged.type, payload: newDeliveryLocation});
};
export default slice.reducer;
