import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Icon from '../../Common/Icon';
import {enableCard} from '../../../store/cards';
import {errors} from '../../../constants/errors';
import Button from '../../Common/Button';

function ErrorCard(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const errorCardProps = useSelector((state) => state.cards.errorCardProps);
	const iconColor = errorCardProps.iconColor ? errorCardProps.iconColor : 'icon-red';
	const iconName = errorCardProps.iconName ? errorCardProps.iconName : 'exclamation-circle';
	const translationBtnKey = errorCardProps.translationBtnKey ? errorCardProps.translationBtnKey : 'common.okButton';
	let icon = <Icon cssClass={iconColor} iconName={iconName} />;
	const errorHeader = errors[errorCardProps?.name]
		? errors[errorCardProps?.name]?.header
			? errors[errorCardProps?.name].header
			: errors[errorCardProps?.name]
		: errorCardProps?.header;
	let errorBody = errors[errorCardProps?.name] ? (errors[errorCardProps?.name]?.body ? errors[errorCardProps?.name].body : null) : null;
	//this if statement is important because it checks if there is any "body" defined beside the Error.
	if(errorBody === null || errorBody === undefined){
		errorBody = errorCardProps?.body;
	}
	const closeCard = () => {
		dispatch(enableCard(false));
	};

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-wrapper">
				<p className="error-text header">{t(errorHeader)}</p>
				{errorBody ? (
					<p className="error-text body">
						<Trans i18nKey={t(errorBody)} components={{br: <br />}} />
					</p>
				) : null}
				{icon}
				<div className="additional-error-info">
					{errorCardProps?.statusCode ? <span className="additional-error-statusCode">{errorCardProps.statusCode}/</span> : null}
					{errorCardProps?.name ? <span className="additional-error-name">{errorCardProps.name}</span> : null}
					{errorCardProps?.traceKey ? <p className="additional-error-traceKey">{errorCardProps.traceKey}</p> : null}
				</div>
				<div className="buttons-wrapper">
					<Button variant={'primary'} height={'regular'} width={'full'} clickHandler={closeCard} translationKey={translationBtnKey} />
				</div>
			</div>
		</div>
	);
}

export default ErrorCard;
