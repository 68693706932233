import axios from 'axios';
import apiUrl from '.';

export async function getPaymentIntent(data) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.teak + apiUrl.path.paymentIntent;

	return axios({
		method: 'post',
		url: requestUrl,
		data: data,
	});
}
