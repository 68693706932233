import {useTranslation} from 'react-i18next';
import ButtonBack from './ButtonBack/ButtonBack';
import ButtonClose from './ButtonClose/ButtonClose';
import {Typography} from '../Typography';
/**
 *
 * @param {boolean} disableBoxShadow = boolean to disabled the box shadow
 * @param {function} backButtonClickHandler = callback function triggered when backButton is clicked -> if there is no handler the button is not displayed
 * @param {string} headerTranslationKey = translation key for middle text -> if there is no text key the header is not displayed
 * @param {function} closeButtonClickHandler = callback function triggered when closebutton is clicked -> if there is no handler the button is not displayed
 * @param {ReactComponent} child = react component to be displayed under secondary header
 * @param {ReactComponent} childUnderHeader = react component to be displayed right under header
 * @returns A Secondary Header element used at the top of a page
 */
// same component as Header.js but the elements are on the same line and the heading has no line underneath.
function SecondaryHeader({disableBoxShadow, backButtonClickHandler, headerTranslationKey, closeButtonClickHandler, child, childUnderHeader}) {
	const {t} = useTranslation();

	return (
		<>
			<div className={'secondary-header-wrapper' + (disableBoxShadow ? ' disable-box-shadow' : '')}>
				<div className="secondary-header-back-button">{backButtonClickHandler && <ButtonBack clickHandler={backButtonClickHandler} />}</div>
				<div className="secondary-header-main-heading">
					{headerTranslationKey && (
						<div className="header-wrapper">
							<Typography className="header" variant={'headline-small'}>
								{t(headerTranslationKey)}
							</Typography>
							{childUnderHeader}
						</div>
					)}
				</div>
				<div className="secondary-header-close-button">
					{closeButtonClickHandler && <ButtonClose clickHandler={closeButtonClickHandler} />}
				</div>
			</div>
			{child}
		</>
	);
}

export default SecondaryHeader;
