import {useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {DateTime} from 'luxon';
import moment from 'moment-timezone';
import {enableCard, updateErrorCard, updateSuccessCard} from '../../../../store/cards';
import {bookTeakEntity} from '../../../../api';
import {CARD} from '../../../../constants/cards';
import {loadDashboardData} from '../../../../store/dashboard';
import {loadNextBookings} from '../../../../store/bookingsOverview/bookings';
import {ROUTES} from '../../../../constants/routes';
import {TEAK_ENTITY_NAMES} from '../../../../constants/teakEntityNames';
import {getTeakTypeForOfficeBasedOnId, getOfficeBasedOnId} from '../../../../store/auth';
import Button from '../../../Common/Button';
import SingleDateTimePicker from '../../../Common/DateTimePicker/SingleDateTimePicker';
import {roundTimeQuarterHour} from '../../../../functions/roundTimeToNextQuarter';
import getTimeInRange from '../../../../functions/getTimeInRange';

function ConfirmCheckin() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const data = useSelector((state) => state.cards.data);
	const validCeylon = useSelector((state) => state.dashboard.data.validCeylon);
	const officesFromStore = useSelector((state) => state.auth.data.offices);
	let officeFromData = officesFromStore.find((office) => office._id === data?.entity?.office);
	const teakTypeName = data?.entity?.__t;
	const officeId = data?.entity?.office?._id || data?.entity?.office;
	const teakTypeForOffice = useSelector(getTeakTypeForOfficeBasedOnId(officeId));
	const teakTypeOfEntity = teakTypeForOffice?.teakTypes?.find((teakType) => teakType._id === data?.entity?.teakType);
	const checkinOffice = useSelector(getOfficeBasedOnId(officeId));
	const maxDuration = teakTypeOfEntity?.options?.bookings?.maxDuration?.time;
	const [loading, setLoading] = useState(false);
	const enable12HourFormat = useSelector((state) => state.auth.data.enable12HourFormat);
	const timezone = useSelector((state) => state.auth.data.selectedOffice.timezone);
	const isHybridVirtualMeeting = useSelector((state) => state.entitiesBooking.onlineMeetingEnabled);
	let format12hrs = enable12HourFormat;
	let format = format12hrs ? 'hh:mm a' : 'HH:mm';
	const entityName = data?.entity?.name;
	const nextBookingForEntity = data?.teakBookingsForEntity?.length > 0 ? data?.teakBookingsForEntity[0] : null;
	const maxEndTime = moment().add(maxDuration, 'milliseconds');
	const [endTime, setEndTime] = useState(getInitialEndTimeAndDate());
	const closeCard = () => {
		dispatch(enableCard(false));
	};

	const checkInIntoBooking = () => {
		setLoading(true);
		if (officeFromData?.ceylon?.enabled && !validCeylon && officeFromData?.ceylon?.rule !== 'none') {
			history.push(ROUTES.NO_CERTIFICATE_FOR_CHECKIN);
			dispatch(enableCard(false));
			return;
		}

		const bookingStartTime = roundTimeQuarterHour(DateTime.now());
		const roundedEndTime = roundTimeQuarterHour(endTime);
		const bookingEndTime = DateTime.fromISO(roundedEndTime).toUTC().toISO();

		bookTeakEntity(
			data.entity._id,
			bookingStartTime,
			bookingEndTime,
			undefined,
			true,
			undefined,
			undefined,
			undefined,
			undefined,
			teakTypeName === TEAK_ENTITY_NAMES.ROOM ? t('yourBooking.adHocMeetingIn', {name: entityName}) : null,
			isHybridVirtualMeeting,
		)
			.then(() => {
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'checkOutCard.successText'));
				dispatch(loadDashboardData(true));
				dispatch(loadNextBookings(true));
			})
			.catch((error) => {
				console.log(error);
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'errorMessages.general', error));
				setLoading(false);
			});
	};

	function getInitialEndTimeAndDate() {
		if (data.nextBookingForEntity) {
			return roundTimeQuarterHour(DateTime.fromISO(nextBookingForEntity?.start?.time).minus({minutes: 14}).toISO());
		} else {
			if (teakTypeName === 'TeakEntityMeetingRoom') {
				return roundTimeQuarterHour(DateTime.now().setZone(checkinOffice.timezone).plus({hours: 2}).toISO());
			}
			return roundTimeQuarterHour(DateTime.now().setZone(checkinOffice.timezone).plus({hours: 8}).toISO());
		}
	}

	/**
	 * calc all the hours that should be disabled
	 * @returns array of numbers(hours) without duplicates
	 */
	function calcDisabledHours() {
		if (!nextBookingForEntity) return [];
		let disabledHours = [];
		const startTimeOfNextBooking = nextBookingForEntity?.start?.time;
		const isSameDay = moment().startOf('day').isSame(moment(endTime).startOf('day'));
		const isMaxEndTimeSameDay = maxEndTime.clone().startOf('day').isSame(moment(endTime).startOf('day'));
		//calc the hours from max end time until end of the day
		if (isMaxEndTimeSameDay) {
			//when start time maxEndTime = 00:00 => 24:00
			const start = maxEndTime.clone().add(59, 'minutes').startOf('hour').hour() || 24;
			disabledHours = [...disabledHours, ...getTimeInRange(start, 24)];
		}
		//calc the hours before nextQuarter(time now) + 15min because the start time is rounded to the next quarter and the end time has to be 15min later
		if (isSameDay) {
			disabledHours = [
				...disabledHours,
				...getTimeInRange(
					0,
					moment(roundTimeQuarterHour(moment().tz(timezone)))
						.add(15, 'minutes')
						.hour(),
				),
			];
		}
		//calc the hours from start of next booking until end of the day. Add 59min to round up to the next hour ex: 15:34 + 59 = 16:33 then apply start of day = 16:00

		disabledHours = [
			...disabledHours,
			...getTimeInRange(moment(startTimeOfNextBooking).tz(timezone).add(59, 'minutes').startOf('hour').hour(), 24),
		];

		return [...new Set(disabledHours)];
	}

	/**
	 * calc all the minutes that should be disabled
	 * @returns array of numbers(minutes) without duplicates
	 */
	function calcDisabledMinutes() {
		if (!nextBookingForEntity) return [];
		let disabledMinutes = [];
		const isSameDay = moment(nextBookingForEntity?.start?.time).startOf('day').isSame(moment(endTime).startOf('day'));
		const isSameStartHour = moment(endTime).hour() === moment().hour();
		const isSameMaxHour = moment(endTime).hour() === maxEndTime.clone().hour();
		const isSameBookingStartHour = moment(endTime).hour() === moment(nextBookingForEntity?.start?.time).hour();

		//calc the minutes from 0 until now + 15min because end time has to be 15min > start time
		if (isSameDay && isSameStartHour) {
			disabledMinutes = [...disabledMinutes, ...getTimeInRange(0, moment().add(15, 'minutes').minute())];
		}
		//calc the hours from start of next booking until end of the hour
		if (isSameDay && isSameBookingStartHour) {
			disabledMinutes = [...disabledMinutes, ...getTimeInRange(moment(nextBookingForEntity?.start?.time).tz(timezone).minute(), 60)];
		}
		//calc the minutes from max end time until end of the hour
		if (!isSameDay && isSameMaxHour) {
			disabledMinutes = [...disabledMinutes, ...getTimeInRange(maxEndTime.clone().startOf('hour').hour(), 60)];
		}

		return [...new Set(disabledMinutes)];
	}

	return (
		<div className="checkin-card card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="card-content">
				<div className="checkin-card-header">
					<div className="checkin-card-header-title">{t('entityQRScanned.header')}</div>
					<div className="checkin-card-header-subtitle">
						<Trans i18nKey="entityQRScanned.selectEndTime">
							Please select an<span> end time</span>
						</Trans>
					</div>
				</div>
				<div className="checkin-time-date">
					<SingleDateTimePicker
						dateTime={moment(endTime).tz(timezone)}
						changeTime={(value) => setEndTime(value)}
						timeFormat={format}
						timeFormat12hrs={format12hrs}
						maxDate={maxEndTime}
						minDate={DateTime.now().toISO()}
						minuteStep={15}
						timezone={timezone}
						inputLabelKey={'common.dateTimePicker.endDateAndTime'}
						customTimePickerPlacement={'bottomLeft'}
						disabledHours={calcDisabledHours}
						disabledMinutes={calcDisabledMinutes}
					/>
				</div>
				<div className="buttons-wrapper">
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						clickHandler={checkInIntoBooking}
						translationKey={'common.buttons.confirm'}
						loading={loading}
					/>
				</div>
			</div>
		</div>
	);
}

export default ConfirmCheckin;
