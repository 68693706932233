import {createSlice} from '@reduxjs/toolkit';
import {getAllNotifications} from '../api/notifications';

const slice = createSlice({
	name: 'profile',
	initialState: {
		notifications: {
			items: null,
			notificationDetailsData: null,
			nbOfUnreadNotifications: 0,
			ui: {
				loading: false,
			},
		},
	},
	reducers: {
		notificationItemsUpdated: (profile, action) => {
			profile.notifications.items = action.payload;
		},
		notificationDetailsDataUpdated: (profile, action) => {
			profile.notifications.notificationDetailsData = action.payload;
		},
		nbOfUnreadNotificationsUpdated: (profile, action) => {
			profile.notifications.nbOfUnreadNotifications = action.payload;
		},
		loadingItemsUpdated: (profile, action) => {
			profile.notifications.ui.loading = action.payload;
		},
	},
});

export const {notificationItemsUpdated, notificationDetailsDataUpdated, nbOfUnreadNotificationsUpdated, loadingItemsUpdated} = slice.actions;

export default slice.reducer;

export const updateNotificationsList = (userId) => async (dispatch, getState) => {
	dispatch({type: loadingItemsUpdated.type, payload: true});
	let newNotifications = 0;

	try {
		const allNotifications = await getAllNotifications(userId);

		if (!allNotifications) {
			return dispatch({
				type: nbOfUnreadNotificationsUpdated.type,
				payload: 0,
			});
		}

		dispatch({
			type: notificationItemsUpdated.type,
			payload: allNotifications.data,
		});

		allNotifications.data.forEach((notification) => {
			if (notification?.receivers?.length <= 0) return;

			const memberInReceiversList = notification?.receivers?.find((receiver) => receiver.receivedBy?.member === userId);
			if (!memberInReceiversList || !memberInReceiversList?.receivedBy?.readAt) newNotifications++;
		});

		dispatch({
			type: nbOfUnreadNotificationsUpdated.type,
			payload: newNotifications,
		});
		dispatch({type: loadingItemsUpdated.type, payload: false});
	} catch (error) {
		console.log(error);
		dispatch({type: notificationItemsUpdated.type, payload: null});
		dispatch({type: loadingItemsUpdated.type, payload: false});
	}
};

export const updateNotificationDetailsData = (data) => (dispatch, getState) => {
	if (data === getState().profile.notifications.notificationDetailsData) return;
	dispatch({type: notificationDetailsDataUpdated.type, payload: data});
};
