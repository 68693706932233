/**
 *
 * @param {array} tabsArray = array of tabs to be shown in tabs
 * tab = {
 *  id: id of tab,
 *  label: text shown in label
 *  onClick: callback function when label is pressed
 * }
 * @param {tab.id} defaultTab = which tab is the default one. If nothing is specified, we default to first tab in tabsArray
 * @param {cssClass} cssClass = If cssClass specified, it will be added additionally as a css class to the top most parent container
 * 
 * @returns
 */

function ContentSwitcher({tabsArray, activeTab, cssClass=''}) {
	if (!tabsArray) return null;

	return (
		<div className={`content-switcher ${cssClass}`}>
			{tabsArray.map((tab, index) => (
				<div key={index} className={'content-switcher-item ' + (tab.id === activeTab ? 'active' : '')} onClick={() => tab.onClick(tab.id)}>
					{tab.label}
				</div>
			))}
		</div>
	);
}

export default ContentSwitcher;
