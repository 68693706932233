import { useSelector } from 'react-redux';
import SingleDateTimePicker from './SingleDateTimePicker';

/**
 *
 * @param {string} props.startTime = start time for the timepicker element
 * @param {string} props.endTime = end time for the timepicker element
 * @param {function} props.changeStartTime = function for changing start time for the timepicker element
 * @param {function} props.changeEndTime = function for changing end time for the timepicker element
 * @param {function} props.handleFocus = function for handling when the focus is on an input
 * @param {boolean} props.disabled = value for disabling the datepicker
 * @param {boolean} props.disableFuture = value for disable future dates
 * @param {boolean} props.disablePast = value for disable past dates
 * @param {string} props.maxDate = max selectable date
 * @param {number} props.minuteStep = enable 1 minute step
 * @param {string} props.timezone = timezone for showing/setting dates/times
 * @param {ReactComponentElement} props.error = react component shown if error is shown
 * @param {boolean} props.onlyStartTime = show only the start time input* 
 * @param {boolean} props.onlyDate = show only the date picker without time picker
 * @param {string} props.disabledTimes = disables only the visual interaction with the start and end date of the time picker
 * @returns
 */

function DateTimePicker({
	changeStartTime,
	changeEndTime,
	disabled,
	disableFuture = false,
	disablePast = false,
	startTime,
	endTime,
	maxDate = false,
	minuteStep = 15,
	timezone,
	cssClass = '',
	wrapperId = '',
	required = false,
	handleFocus,
	customTimePickerPlacement = 'topLeft',
	error = null,
	onlyStartTime = false,
	onlyDate = false,
	disabledTimes=false
}) {
	const enable12HourFormat = useSelector((state) => state.auth.data.enable12HourFormat);

	let format12hrs = enable12HourFormat;
	let format = format12hrs ? 'hh:mm a' : 'HH:mm';

	return (
		<div className="date-time-picker" id={wrapperId}>
			<SingleDateTimePicker
				dateTime={startTime}
				changeTime={changeStartTime}
				timeFormat={format}
				timeFormat12hrs={format12hrs}
				disabled={disabled}
				disableFuture={disableFuture}
				disablePast={disablePast}
				maxDate={maxDate}
				minuteStep={minuteStep}
				timezone={timezone}
				handleFocus={handleFocus}
				inputLabelKey={'common.dateTimePicker.from'}
				customTimePickerPlacement={customTimePickerPlacement}
				onlyDate={onlyDate}
				disabledTimes={disabledTimes}
			/>
			{!onlyStartTime && (
				<>
					<SingleDateTimePicker
						dateTime={endTime}
						changeTime={changeEndTime}
						timeFormat={format}
						timeFormat12hrs={format12hrs}
						disabled={disabled}
						disableFuture={disableFuture}
						disablePast={disablePast}
						maxDate={maxDate}
						minuteStep={minuteStep}
						timezone={timezone}
						handleFocus={handleFocus}
						inputLabelKey={'common.dateTimePicker.to'}
						customTimePickerPlacement={customTimePickerPlacement}
						onlyDate={onlyDate}
						disabledTimes={disabledTimes}
					/>
					{error && <p>{error}</p>}
				</>
			)}
		</div>
	);
}

export default DateTimePicker;
