import {createSlice} from '@reduxjs/toolkit';
import {getCedarChangeRequests, getCedarOptions} from '../api/timeTracker';

const slice = createSlice({
	name: 'cedar',
	initialState: {
		workingState: null,
		options: null,
	},
	reducers: {
		workingStateUpdated: (cedar, action) => {
			cedar.workingState = action.payload;
		},
		cedarOptionsUpdated: (cedar, action) => {
			cedar.options = action.payload;
		},
		changeRequestsUpdated: (cedar, action) => {
			cedar.changeRequests = action.payload;
		}
	},
});

export const {workingStateUpdated, cedarOptionsUpdated, changeRequestsUpdated} = slice.actions;

export default slice.reducer;

export const updateWorkingState = (cedarType) => async (dispatch, getState) => {
	dispatch({type: workingStateUpdated.type, payload: cedarType});
};

export const loadCedarOptions = (office, force) => async (dispatch, getState) => {
	const cedarOptionsState = getState().cedar.options;
	if (cedarOptionsState && !force) {
		return cedarOptionsState;
	}

	try {
		const cedarOptionsResponse = await getCedarOptions(office);
		dispatch({type: cedarOptionsUpdated.type, payload: cedarOptionsResponse.data});
		return cedarOptionsResponse.data;
	} catch (error) {
		console.log(error);
	}
	return null;
};

export const loadChangeRequests = (office) => async (dispatch, getState) => {
	try {
		const cedarChangeRequestsRequest = await getCedarChangeRequests(office);
		dispatch({type: changeRequestsUpdated.type, payload: cedarChangeRequestsRequest.data});
	} catch (error) {
		console.log(error);
	}
}