export const NOTIFICATION_NAMES = {
	'teak.booking.member.forgotCheckIn': 'forgotCheckIn',
	'teak.booking.member.forgotCheckOut': 'forgotCheckOut',
	'teak.booking.member.inviteToRoomBooking': 'inviteToRoomBooking',
	'teak.booking.user.adminCancelledBooking': 'adminCancelledBooking',
	'teak.booking.member.attendeeCancelledRoomBooking': 'attendeeCancelled',
	'teak.booking.user.oakCrisisCancelledBooking': 'oakCrisisCancelledBooking',
	'pine.visitor.member.checkOut': 'pineVisitorForgotCheckOut',
	'pine.invitation.user.cancelledPineInvitationWithRoomBooking': 'cancelledPineInvitationWithRoomBooking',
	'teak.booking.user.areaDeactivateCancelledBooking': 'areaDeactivateCancelledBooking',
	'teak.booking.user.cancelledBooking.assetDeactivation': 'assetDeactivation',
	'teak.booking.user.cancelledBooking.restrictionChange': 'restrictionChange',
	'teak.booking.user.cancelledBooking.onlyCheckInsChange': 'onlyCheckInsChange',
	//TODO: Add BE
	'teak.booking.request.declined': 'declined'
};
