import { useEffect, useRef } from 'react';

/**
 * Custom hook that sets up an interval and provides cleanup
 * @param callback - The function to call at each interval
 * @param delay - The interval delay in milliseconds
 */
const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Store the latest callback in a ref
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

export default useInterval;
