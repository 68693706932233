/**
 * This file contains api calls related to agenda/diary feature.
 *
 */

import axios from 'axios';

const source = axios.CancelToken.source();
let config = {cancelToken: source.token};

const pathUrls = {
	member: '/biro/member',
	agenda: '/agenda',
	findInRange: '/findInRange',
	add: '/add',
	update: '/update',
	delete: '/delete',
};

/**
 *
 * @param {id} memberId = id of member requesting
 * @param {string} name = name of agenda item
 * @param {iso} start = start time of agenda item
 * @param {iso} end = end time of agenda item
 * @param {string} notes = optional notes to be added to the agenda item
 * @param {id} office = office id in which to add agenda item
 * @returns promise with response = creates an agenda item with provided results
 */
export async function addNewAgendaItem(memberId, name, start, end, notes, office) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.member}/${memberId + pathUrls.agenda + pathUrls.add}`;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				name,
				start: start,
				end: end,
				notes,
				office,
			},
		},
		config,
	);
}

/**
 *
 * @param {id} memberId = id of member requesting
 * @param {id} agendaItemId = id of the agenda item that wil be deleted
 * @param {id} office = office id in which to delete agenda item
 * @returns promise with response = deletes an agenda item with this id
 */
export async function deleteAgendaItem(memberId, agendaItemId, office) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.member}/${memberId + pathUrls.agenda}/${agendaItemId + pathUrls.delete}`;

	return axios(
		{
			method: 'delete',
			url: requestUrl,
			data: {
				office,
			},
		},
		config,
	);
}

/**
 *
 * @param {id} memberId = id of member requesting
 * @param {iso} start = start time for range from which to get agenda items
 * @param {iso} end = end time for range from which to get agenda items
 * @param {id} office = office id in which to search for agenda items
 * @returns promise with response = returns agenda items inside a time range
 */
export async function getAgendaItems(memberId, start, end, office) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.member}/${memberId + pathUrls.agenda + pathUrls.findInRange}`;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				start,
				end,
				office,
			},
		},
		config,
	);
}

/**
 *
 * @param {id} agendaItemId = id of the agenda item that wil be updated
 * @param {id} memberId = id of member requesting
 * @param {string} name = new name to update on the agenda item
 * @param {iso} start = new start time to update on the agenda item
 * @param {iso} end = new end time to update on the agenda item
 * @param {string} notes = notes to update on the agenda item
 * @param {id} office = office id in which the agenda item is added
 * @returns promise with response = updates specifiec agenda item
 */
export async function updateAgendaItem(agendaItemId, memberId, name, start, end, notes, office) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = `${hostUrl + pathUrls.member}/${memberId + pathUrls.agenda}/${agendaItemId + pathUrls.update}`;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				name,
				start: {
					time: start,
				},
				end: {
					time: end,
				},
				notes,
				office,
			},
		},
		config,
	);
}
