import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../Common/Icon';
import { enableCard, updateCard, updateErrorCard, updateSuccessCard } from '../../../store/cards';
import { CARD } from '../../../constants/cards';
import { ROUTES } from '../../../constants/routes';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { reportProblemForEntity, reportProblemUploadPhoto } from '../../../api';
import { emptyReportProblemStore, loadIssueReports } from '../../../store/reportProblem';
import { enableBottomMenu } from '../../../store/auth';
import Button from '../../Common/Button';

function SummaryReportProblem() {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { path } = useRouteMatch();
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice.id);
	const selectedAreaName = useSelector((state) => state.reportProblem.selectedArea.areaName);
	const category = useSelector((state) => state.reportProblem.selectedProblemCategory.category);
	const categoryName = useSelector((state) => state.reportProblem.selectedProblemCategory.name);
	const enteredComment = useSelector((state) => state.reportProblem.enteredComment);
	const imageArray = useSelector((state) => state.reportProblem.imageArray);
	const selectedDesk = useSelector((state) => state.reportProblem.selectedDesk.deskName);
	const selectedDeskId = useSelector((state) => state.reportProblem.selectedDesk.deskId);
	const selectedRoom = useSelector((state) => state.reportProblem.selectedRoom.roomName);
	const selectedRoomId = useSelector((state) => state.reportProblem.selectedRoom.roomId);
	const selectedDeskIdOrRoomId = selectedDeskId || selectedRoomId;
	const selectedDeskOrRoom = selectedDesk || selectedRoom;
	let imagesList = [];

	if (imageArray.length > 0) {
		imageArray.forEach((image, index) => {
			imagesList.push(
				<p key={index} className="summary-confirmation-picturename">
					{imageArray[index].name}
				</p>,
			);
		});
	} else {
		imagesList = null;
	}

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	const reportProblem = () => {
		dispatch(updateCard(CARD.PLACEHOLDER));
		reportProblemForEntity(selectedOffice, selectedDeskIdOrRoomId, enteredComment, categoryName)
			.then((response) => {
				if (imageArray.length > 0) {
					let responseData = {};
					imageArray.forEach((image, index) => {
						reportProblemUploadPhoto(response.data._id, imageArray[index].image)
							.then((response) => {
								responseData = response.data;
							})
							.catch((error) => {
								console.log(error);
								dispatch(updateErrorCard(CARD.MAIN_ERROR, 'reportProblem.errorText', error));
							});
					});

					if (responseData) {
						dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'reportProblem.successText'));
						dispatch(loadIssueReports(true));
						dispatch(emptyReportProblemStore());
					} else {
						console.log(responseData);
					}
				} else {
					dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'reportProblem.successText'));
					dispatch(loadIssueReports(true));
					dispatch(emptyReportProblemStore());
				}
			})
			.catch((error) => {
				console.log(error);
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'reportProblem.errorText', error));
			});
		dispatch(enableBottomMenu(true));
		if (path.includes('profile')) {
			history.push(ROUTES.PROFILE_PAGE);
		} else {
			history.push(ROUTES.DEFAULT);
		}
		dispatch(emptyReportProblemStore());
	};

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="summary-confirmation-wrapper">
				<div className="summary-confirmation-header">{t('summaryReportProblemCard.summary')}</div>

				<ul className="summary-confirmation-list">
					<li className="summary-confirmation-item">
						<Icon cssClass={'icon-purple'} iconName={'check-circle'}></Icon>
						<span>{t('summaryReportProblemCard.problemLocation')}</span>
					</li>
					<li className="summary-confirmation-details">
						<span>
							{t('summaryReportProblemCard.area')} {selectedAreaName} / {selectedDeskOrRoom}
						</span>
					</li>
					<li className="summary-confirmation-item">
						<Icon cssClass={'icon-purple'} iconName={'check-circle'}></Icon>
						<span>{t('summaryReportProblemCard.typeOfProblem')}</span>
					</li>
					<li className="summary-confirmation-details">
						<span>{category}</span>
					</li>

					{!!imagesList && (
						<li className="summary-confirmation-pictures">
							<Icon cssClass={'icon-purple'} iconName={'check-circle'}></Icon>
							<span>{t('summaryReportProblemCard.pictures')}</span>
							{imagesList}
						</li>
					)}
				</ul>

				<div className="buttons-wrapper">
					<Button variant={'primary'} height={'regular'} width={'full'} clickHandler={reportProblem} translationKey={'common.okButton'} />
					<Button variant={'primary'} height={'regular'} width={'full'} clickHandler={closeCard} translationKey={'common.backButton'} />
				</div>
			</div>
		</div>
	);
}

export default SummaryReportProblem;
