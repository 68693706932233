import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {DateTime} from 'luxon';
import {useHistory} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';
import {enableBottomMenu, processFavoriteAspenItemObject} from '../../../store/auth';
import SecondaryHeader from '../../Common/Header/SecondaryHeader';
import {getAspenItems} from '../../../api';
import Button from '../../Common/Button';
import {ClipLoader} from 'react-spinners';
import Icon, {isIconValid} from '../../Common/Icon';
import {enableAspenSummaryPage, updateSelectedAspenItem} from '../../../store/bookingProcess/aspen';
import FormattedDateTime from '../../Common/DateTimePicker/FormattedDateTime';
import {enableCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import {CARD} from '../../../constants/cards';
import minutesToHours from '../../../functions/minutesToHours';
import {ROUTES} from '../../../constants/routes';
import {confirmRequestForAspenItem} from '../../../api/aspen';
import AspenAvailabilityModalSheet from './AspenAvailabilityModalSheet';
import NoResults from '../../Common/MicroElements/NoResults';
import FormattedDate from '../../Common/DateTimePicker/FormattedDate';
import PriceTag from '../../Common/PriceTag';
import SectionTitle from '../../Common/Elements/SectionTitle';
import PrimaryInput from '../../Common/Inputs/PrimaryInput';
import {Textarea} from '../../Common/Textareas/Textarea';

function AspenList() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [loadingItems, setLoadingItems] = useState(true);
	const [items, setItems] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const selectedAspenItem = useSelector((state) => state.aspen.selectedAspenItem);
	const selectedDate = useSelector((state) => state.aspen.selectedDate);
	const selectedAspenType = useSelector((state) => state.aspen.selectedAspenType);
	const showSummaryPage = useSelector((state) => state.aspen.ui.showSummaryPage);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const favoriteAspenItems = useSelector((state) => state.auth.data.userData.biro.favoriteAspenItems);

	useEffect(() => {
		dispatch(enableBottomMenu(false));
		if (!selectedAspenType) {
			history.push(ROUTES.BOOKING_PAGE);
			return;
		}
		getAspenItemList();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getAspenItemList() {
		const start = DateTime.fromISO(selectedDate).startOf('day').toUTC().toISO();
		const end = DateTime.fromISO(selectedDate).endOf('day').toUTC().toISO();
		try {
			const itemsListResponse = await getAspenItems(selectedOffice._id, selectedAspenType?._id, {start, end});
			setItems(itemsListResponse.data);
		} catch (error) {
			console.log(error);
		}
		setLoadingItems(false);
	}
	function goBack() {
		selectAspenItem(null);
		history.goBack();
	}

	function confirmSelection() {
		setIsModalOpen(true);
	}

	function selectAspenItem(aspenItem) {
		dispatch(updateSelectedAspenItem(aspenItem));
	}

	// implementation used until we have a summary page that includes entity booking and service booking
	if (showSummaryPage) return <SummaryPage />;
	return (
		<div className="aspen-list-page">
			<div className="aspen-list-header">
				<SecondaryHeader
					headerTranslationKey={selectedAspenType?.name}
					backButtonClickHandler={goBack}
					childUnderHeader={<p className="selected-date">{<FormattedDate date={selectedDate} />}</p>}
				/>
			</div>
			<div className="aspen-list-content">
				{loadingItems ? (
					<div className="loading-wrapper">
						<ClipLoader />
					</div>
				) : (
					<>
						{items.length > 0 ? (
							<>
								{items?.map((item) => (
									<AspenItem
										favorite={favoriteAspenItems && favoriteAspenItems?.some((i) => i?._id === item._id)}
										clickHandler={() => selectAspenItem(item)}
										selectedAspenItem={selectedAspenItem}
										item={item}
									/>
								))}
							</>
						) : (
							<NoResults />
						)}
					</>
				)}
			</div>
			<AspenAvailabilityModalSheet isModalOpen={isModalOpen} aspenItem={selectedAspenItem} closeModal={() => setIsModalOpen(false)} />
			<div className="aspen-list-button button-fixed-at-bottom-static">
				<Button
					variant={'primary'}
					height={'regular'}
					width={'full'}
					clickHandler={confirmSelection}
					translationKey={'common.buttons.confirm'}
					loading={loadingItems}
					disabled={!selectedAspenItem}
				/>
			</div>
		</div>
	);
}

export function AspenItem({item, clickHandler, selectedAspenItem, aspenType, isShownFromFavorite, favorite, favoriteIconClickHandler}) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const selectedAspenType = useSelector((state) => state.aspen.selectedAspenType);
	// * if aspen item is shown inside the favorites list, the aspenType is coming from the item itself.
	const aspenTypeOfItem = aspenType ? aspenType : selectedAspenType;
	const paidByCompany = item?.paidBy === 'company';
	const zeroPrice = <PriceTag item={{price: {amount: 0, currency: item?.price?.currency}}} />;

	function getDuration() {
		//duration is in ms. Divide by 60000 to get mins. The duration can either be minutes or hours.
		const durationInMinutes = Math.floor(item?.duration / 60000);
		return minutesToHours(durationInMinutes, t('common.minutesAbrv'), t('common.hoursAbrv'));
	}

	function getIconName(iconName) {
		if (isIconValid(iconName)) {
			return iconName;
		} else {
			return aspenTypeOfItem?.iconName;
		}
	}

	function handleFavoriteClick(e) {
		e.preventDefault();
		e.stopPropagation();
		dispatch(processFavoriteAspenItemObject(item));
		if (favoriteIconClickHandler) favoriteIconClickHandler();
	}
	return (
		<div className={`aspen-item ${item?._id === selectedAspenItem?._id ? 'aspen-item-active' : ''}`} onClick={clickHandler}>
			<div className="aspen-item-image">
				{item?.iconName ? (
					<div className="icon-wrapper">
						<Icon cssClass="icon-white" color="white" iconName={getIconName(item?.iconName)} />
					</div>
				) : null}
			</div>
			<div className="aspen-item-details">
				<div className="name-action-wrapper">
					<p className="name">{`${isShownFromFavorite ? aspenType?.name + ' - ' + item?.name : item?.name}`}</p>
					<Icon
						iconName={'heartIcon'}
						iconColor={'none'}
						cssClass={favorite ? `icon-active-favorite` : 'icon-inactive-favorite'}
						clickHandler={handleFavoriteClick}
					/>
				</div>
				<p className="price">
					{paidByCompany ? (
						<>
							{zeroPrice}
							<span className="paid-by-company-amount">
								(+
								<PriceTag item={item} />)
							</span>
						</>
					) : (
						<PriceTag item={item} />
					)}
				</p>
				<p className="duration">{getDuration()}</p>
			</div>
		</div>
	);
}

function SummaryPage() {
	const {t} = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const selectedDate = useSelector((state) => state.aspen.selectedDate);
	const selectedAspenType = useSelector((state) => state.aspen.selectedAspenType);
	const selectedAspenItem = useSelector((state) => state.aspen.selectedAspenItem);
	const deliveryLocation = useSelector((state) => state.aspen.selectedDeliveryLocation);
	const [projectNumber, setProjectNumber] = useState();
	const [additionalInfo, setAdditionalInfo] = useState();
	const paidByCompany = selectedAspenItem?.paidBy === 'company';
	const zeroPrice = <PriceTag item={{price: {amount: 0, currency: selectedAspenItem?.price?.currency}}} />;
	const [cssForHiddenDiv, setCssForHiddenDiv] = useState('-hidden'); //the div is used to make the content scrollable when the virtual keyboard is on
	function goBack() {
		dispatch(enableAspenSummaryPage(false));
	}

	async function sendRequest() {
		dispatch(enableCard(true));
		try {
			const startTimeUTC = DateTime.fromISO(selectedDate).toUTC().toISO();
			const requestResponse = await confirmRequestForAspenItem(
				selectedAspenItem?._id,
				startTimeUTC,
				deliveryLocation ? deliveryLocation?.value : undefined,
				projectNumber,
				additionalInfo,
			);
			dispatch(
				updateSuccessCard(
					CARD.MAIN_SUCCESS,
					'successMessages.aspenRequestCompleted',
					paidByCompany ? 'successMessages.aspenRequestCompletedPaidBy' : undefined,
				),
			);
			history.push(ROUTES.DEFAULT);
		} catch (error) {
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'errorMessages.errorAspenRequestCreation'));
		}
	}

	document.addEventListener('focusout', () => {
		setCssForHiddenDiv('-hidden');
	});

	const handleFocus = () => {
		setCssForHiddenDiv('');
	};

	return (
		<div className={`aspen-summary-page`}>
			<div className="aspen-summary-header">
				<SecondaryHeader headerTranslationKey={'serviceBooking.bookingSummary'} backButtonClickHandler={goBack} />
			</div>
			<div className="aspen-summary-content">
				<div className="aspen-summary-content-details">
					<SectionTitle className="aspen-summary-content-header" title={`${t('serviceBooking.header')} - ${selectedAspenType?.name}`} />
					<p className="aspen-summary-content-details-item">
						{t(`common.totalCost`)}:{' '}
						<span>
							{paidByCompany ? (
								<>
									{zeroPrice}
									<span className="paid-by-company-amount">
										(<PriceTag item={selectedAspenItem} />)
									</span>
								</>
							) : (
								<PriceTag item={selectedAspenItem} />
							)}
						</span>
					</p>
					<p className="aspen-summary-content-details-item">
						{t(`serviceBooking.yourOrder`)}: <span>{selectedAspenItem?.name}</span>
					</p>
					{selectedAspenType?.delivery?.location && (
						<p className="aspen-summary-content-details-item">
							{t(`serviceBooking.deliveryLocation`)}:{' '}
							<span>{deliveryLocation ? deliveryLocation?.label : selectedAspenType?.delivery?.location}</span>
						</p>
					)}
					<p className="aspen-summary-content-details-item">
						{t(`common.dateTimePicker.startTime`)}:{' '}
						<span>
							<FormattedDateTime dateTime={selectedDate} />
						</span>
					</p>
					{!selectedAspenType?.onlyBookableOn ? (
						<>
							<hr className="dividing-hr"></hr>
							{paidByCompany ? (
								<>
									<SectionTitle className="aspen-summary-content-header" title={t('serviceBooking.companyPayment')} />
									<SectionTitle className="small-title" title={`${t('serviceBooking.projectNumber')}*`} />
									<p className="company-payment-info">{t('serviceBooking.companyPaymentInfo')}</p>
									<PrimaryInput
										id="projectNumber"
										type="text"
										cssClass="project-number-input"
										defaultValue={projectNumber}
										clickHandler={(value) => {
											setProjectNumber(value);
										}}
										handleFocus={handleFocus}
									/>
								</>
							) : (
								<>
									<SectionTitle className="aspen-summary-content-header" title={t('serviceBooking.paymentInformation')} />
									<p className="company-payment-info">{t('serviceBooking.paymentInformationInfo')}</p>
								</>
							)}
						</>
					) : null}
					<hr className="dividing-hr"></hr>
					<SectionTitle className="aspen-summary-content-header" title={t('common.additionalInfo')} />
					<div className="additional-info-wrapper">
						<Textarea
							cols="32"
							rows="5"
							cssClass="additional-info"
							id="additional-info"
							changeHandler={setAdditionalInfo}
							onFocus={handleFocus}
						/>
					</div>
					<div className={'placeholderForVirtualKeyboard' + cssForHiddenDiv}></div>
				</div>
			</div>

			<div className="aspen-summary-button button-fixed-at-bottom-static">
				<Button
					variant={'primary'}
					height={'regular'}
					width={'full'}
					clickHandler={sendRequest}
					translationKey={'common.buttons.confirm'}
					loading={false}
					disabled={paidByCompany && !projectNumber}
				/>
			</div>
		</div>
	);
}
export default AspenList;
