import {createSlice} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';
import {getManyEntities} from '../../api/index';
import {getAspenItems} from '../../api/aspen';
import {getTeakEntitiesFull} from '../../functions/getTeakEntitiesFull';
import {maxBookingDateChanged} from './entitiesBooking';
import {TEAK_TYPE_NAMES} from '../../constants/teakTypeNames';

const slice = createSlice({
	name: 'favorites',
	initialState: {
		favoritesList: [],
		favoritesAspenList: [],
		ui: {
			favoritesListLoading: true,
		},
	},
	reducers: {
		favoritesListUpdated: (favorites, action) => {
			favorites.favoritesList = action.payload;
		},
		favoritesAspenListUpdated: (favorites, action) => {
			favorites.favoritesAspenList = action.payload;
		},
		favoritesListLoadingReceived: (favorites, action) => {
			favorites.ui.favoritesListLoading = action.payload;
		},
	},
});

export const {favoritesListUpdated, favoritesAspenListUpdated, favoritesListLoadingReceived} = slice.actions;

export default slice.reducer;

export const loadFavoritesList = () => async (dispatch, getState) => {
	dispatch({type: favoritesListLoadingReceived.type, payload: true});

	try {
		const currentSelectedStartTime = getState().entitiesBooking.selectedStartTime;
		const currentSelectedEndTime = getState().entitiesBooking.selectedEndTime;
		const officeId = getState().auth.data.selectedOffice._id;
		const areasFromStore = getState().auth.data.areas;
		const teakFeaturesFromStore = getState().auth.data.teakFeatures;
		const userData = getState().auth.data.userData;
		const featureTranslations = getState().auth.data.featureTranslations;
		const startTime = currentSelectedStartTime;
		const endTime = currentSelectedEndTime;
		const manyTeakEntitiesResponse = await getManyEntities(undefined, startTime, endTime, officeId, undefined, true); //there is no area id or teaktype defined for favorites list
		const teakEntitiesFull = getTeakEntitiesFull(
			areasFromStore,
			featureTranslations,
			userData,
			teakFeaturesFromStore,
			manyTeakEntitiesResponse.data,
		); // populate teakEntities with featureNames and areaName

		dispatch({type: favoritesListUpdated.type, payload: teakEntitiesFull});
		dispatch({type: favoritesListLoadingReceived.type, payload: false});
	} catch (error) {
		console.log(error);
		dispatch({type: favoritesListUpdated.type, payload: []});
		dispatch({type: favoritesListLoadingReceived.type, payload: false});
	}
};

export const loadAspenFavoritesList = () => async (dispatch, getState) => {
	dispatch({type: favoritesListLoadingReceived.type, payload: true});

	try {
		const selectedDate = getState().aspen.selectedDate;
		const officeId = getState().auth.data.selectedOffice._id;
		const start = DateTime.fromISO(selectedDate).startOf('day').toUTC().toISO();
		const end = DateTime.fromISO(selectedDate).endOf('day').toUTC().toISO();
		const favoritesAspenItemsResponse = await getAspenItems(officeId, undefined, {start, end}, true);

		dispatch({type: favoritesAspenListUpdated.type, payload: favoritesAspenItemsResponse.data});
		dispatch({type: favoritesListLoadingReceived.type, payload: false});
	} catch (error) {
		console.log(error);
		dispatch({type: favoritesAspenListUpdated.type, payload: []});
		dispatch({type: favoritesListLoadingReceived.type, payload: false});
	}
};
export const updateFavoritesListLoading = (newValue) => (dispatch, getState) => {
	if (newValue === getState().favorites.ui.favoritesListLoading) return;
	dispatch({type: favoritesListLoadingReceived.type, payload: newValue});
};

export const deleteItemFromFavoritesList = (itemId, teakTypeForSelectedOffice) => (dispatch, getState) => {
	const currentFavoritesList = getState().favorites.favoritesList.slice();
	const index = currentFavoritesList.findIndex((favorite) => favorite._id === itemId);
	const deskTeakType = teakTypeForSelectedOffice?.teakTypes?.find((teakType) => teakType.__t === TEAK_TYPE_NAMES.DESK);
	if (index > -1) {
		currentFavoritesList.splice(index, 1);
	}

	if (deskTeakType && currentFavoritesList.length === 0) {
		dispatch({
			type: maxBookingDateChanged.type,
			payload: DateTime.now()
				.plus({
					milliseconds: deskTeakType?.options?.bookings?.maxInAdvance?.timeAfterNow,
				})
				.toISO(),
		});
	}
	dispatch({
		type: favoritesListUpdated.type,
		payload: currentFavoritesList,
	});
};
