import {useDispatch, useSelector} from 'react-redux';
import {enableLoadingScreen, setWhiteLabeling, showBottomMenuReceived} from '../../store/auth';
import {useEffect, useRef } from 'react';
import doorjames_logo from '../../image/doorjames_logo.svg';
import {ROUTES} from '../../constants/routes';
import {useHistory} from 'react-router-dom';
import {getOrganizationWhiteLabeling} from '../../api';

function ThemeEngine() {
	const dispatch = useDispatch();

	const whiteLabeling = useSelector((state) => state.auth.ui.whiteLabeling);
	const whiteLabelingRef = useRef(whiteLabeling);
	const organizationId = useSelector((state) => state.auth.data.organizationData?._id);

	const history = useHistory();

	useEffect(() => {
		listenColorChangeEvent();
	}, [])

	useEffect(() => {
		whiteLabelingRef.current = whiteLabeling;
	}, [whiteLabeling]);

	useEffect(async () => {
		const lastOrgId = localStorage.getItem('organizationId');

		// fetch OrgData
		if (lastOrgId) {
			try {
				const response = await getOrganizationWhiteLabeling(lastOrgId);
				const bufferToBase64 = (buffer) =>
					'data:image/png;base64,' + btoa(
						new Uint8Array(buffer).reduce((data, byte) => data + String.fromCharCode(byte), '')
					);

				const newWhiteLabeling = {
					primaryColor: response.data.whiteLabeling?.primaryColor,
					layout: response.data.whiteLabeling?.layout,
					logo: response.data.logo?.img?.data?.data ? bufferToBase64(response.data.logo?.img.data.data) : null,
					loginImage: response.data.whiteLabeling?.loginImage?.img?.data?.data ? bufferToBase64(response.data.whiteLabeling.loginImage.img.data.data) : null
				};

				dispatch(setWhiteLabeling(newWhiteLabeling));
				setTheme(newWhiteLabeling);
			} catch (e) {
				console.error(e);
			}
		} else {
			setTheme(whiteLabeling);
		}
	}, [organizationId]);

	// Listen for color change events
	// to call event from browser console
	// window.postMessage({type: 'theme-color', color: '#FF0000'});
	function listenColorChangeEvent() {
		window.addEventListener('message', (event) => {
			const data = event.data;

			if (data) {
				const newWhiteLabeling = {
					layout: data?.layout ?? whiteLabelingRef.current?.layout,
					primaryColor: data?.primaryColor ?? whiteLabelingRef.current?.primaryColor,
					logo: data?.logo ?? whiteLabelingRef.current?.logo,
					loginImage: data?.loginImage ?? whiteLabelingRef.current?.loginImage
				};
				switch (data.type) {
					case 'theme-color':
					case 'theme-layout':
					case 'org-logo':
					case 'login-image':
						dispatch(
							setWhiteLabeling(newWhiteLabeling),
						);
						setTheme(newWhiteLabeling);
						break;
					case 'dark-mode':
						setDarkMode(data.darkMode);
						break;
					case 'show-view':
						dispatch(enableLoadingScreen(false));
						dispatch(showBottomMenuReceived(false));
						if (data.view === 'login') {
							history.push(ROUTES.LOGIN);
						}
						if (data.view === 'dashboard') {
							history.push(ROUTES.DEFAULT);
							dispatch(showBottomMenuReceived(true));
						}
						if (data.view === 'loading') {
							history.push(ROUTES.DEFAULT);
							dispatch(enableLoadingScreen(true));
						}
						break;
					default:
						break;
				}
			}
		});
	}

	async function setTheme(whiteLabeling) {
		updateColorMainVariables(whiteLabeling.primaryColor);

		if (whiteLabeling.layout === 'classic') {
			removeDoorJamesLogo();
		} else {
			addDoorJamesLogo();
		}
	}

	function setDarkMode(data) {
		// Function to apply the theme based on conditions
		function applyTheme(preferredColorScheme) {
			if (data.darkMode === true) {
				// Check if forceDarkMode is enabled and darkMode is true
				if (data.forceDarkMode) {
					// Always apply dark mode if forceDarkMode is true
					document.body.style.setProperty('--color-neutral-light', data.darkModeShade);
					document.body.style.setProperty('--color-neutral-dark', '#fff');
				} else {
					// Respect user's system setting if darkMode is true but forceDarkMode is false
					if (preferredColorScheme === 'dark') {
						document.body.style.setProperty('--color-neutral-light', data.darkModeShade);
						document.body.style.setProperty('--color-neutral-dark', '#fff');
					} else {
						document.body.style.setProperty('--color-neutral-light', '#fff');
						document.body.style.setProperty('--color-neutral-dark', '#000');
					}
				}
			} else {
				// Always apply light mode if darkMode is false
				document.body.style.setProperty('--color-neutral-light', '#fff');
				document.body.style.setProperty('--color-neutral-dark', '#000');
			}
		}

		// Detect the current system preference for dark or light mode
		let preferredColorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
		applyTheme(preferredColorScheme);

		// Add event listeners to detect changes in the system preference
		const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
		darkModeMediaQuery.addEventListener('change', (e) => {
			const newPreference = e.matches ? 'dark' : 'light';
			applyTheme(newPreference);
		});
	}

	function updateColorMainVariables(mainColor) {
		document.body.style.setProperty('--color-main', mainColor);
		document.body.style.setProperty('--color-main-0', lightenDarkenColor(mainColor, -60));
		document.body.style.setProperty('--color-main-10', lightenDarkenColor(mainColor, -50));
		document.body.style.setProperty('--color-main-20', lightenDarkenColor(mainColor, -40));
		document.body.style.setProperty('--color-main-30', lightenDarkenColor(mainColor, -30));
		document.body.style.setProperty('--color-main-40', lightenDarkenColor(mainColor, -20));
		document.body.style.setProperty('--color-main-50', lightenDarkenColor(mainColor, -10));
		document.body.style.setProperty('--color-main-60', lightenColor(mainColor, 65));
		document.body.style.setProperty('--color-main-70', lightenColor(mainColor, 55));
		document.body.style.setProperty('--color-main-80', lightenColor(mainColor, 45));
		document.body.style.setProperty('--color-main-90', lightenColor(mainColor, 35));
		document.body.style.setProperty('--color-main-95', lightenColor(mainColor, 30));
	}

	function lightenDarkenColor(col, amt) {
		let usePound = false;

		if (col?.[0] === '#') {
			col = col.slice(1);
			usePound = true;
		}

		let num = parseInt(col, 16);
		let r = (num >> 16) + amt;
		let g = ((num >> 8) & 0x00ff) + amt;
		let b = (num & 0x0000ff) + amt;

		if (r > 255) r = 255;
		else if (r < 0) r = 0;

		if (g > 255) g = 255;
		else if (g < 0) g = 0;

		if (b > 255) b = 255;
		else if (b < 0) b = 0;

		return (usePound ? '#' : '') + ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0');
	}

	function lightenColor(color, percent) {
		const rgbaColor = transformHexIntoRgba(color, percent);
		return blendWithWhite(rgbaColor);
	}

	function transformHexIntoRgba(color, opacity) {
		const hex = color.replace('#', '');
		const r = parseInt(hex.substring(0, 2), 16);
		const g = parseInt(hex.substring(2, 4), 16);
		const b = parseInt(hex.substring(4, 6), 16);

		return `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;
	}

	/**
	 * Blend the color with white in the background
	 * @param rgbaColor
	 * @private
	 */
	function blendWithWhite(rgbaColor) {
		// Parse the rgbaColor string
		const rgbaMatch = rgbaColor.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/);

		if (!rgbaMatch) {
			console.error('Invalid RGBA format');
			return;
		}

		// Extract the RGBA values
		const r = parseInt(rgbaMatch[1]);
		const g = parseInt(rgbaMatch[2]);
		const b = parseInt(rgbaMatch[3]);
		const a = parseFloat(rgbaMatch[4]);

		// Background color is white (255, 255, 255)
		const backgroundR = 255;
		const backgroundG = 255;
		const backgroundB = 255;

		// Formula to blend the foreground color with the background
		const blendedR = Math.round((1 - a) * backgroundR + a * r);
		const blendedG = Math.round((1 - a) * backgroundG + a * g);
		const blendedB = Math.round((1 - a) * backgroundB + a * b);

		// Convert RGB to Hex
		const toHex = (channel) => {
			const hex = channel.toString(16);
			return hex.length === 1 ? '0' + hex : hex; // Ensure two digits
		};

		// Combine and return the hex value
		return `#${toHex(blendedR)}${toHex(blendedG)}${toHex(blendedB)}`;
	}

	function addDoorJamesLogo() {
		// Add CSS to the HTML head
		const styleElement = document.createElement('style');
		styleElement.setAttribute('id', 'doorJamesLogo');
		styleElement.innerHTML = `
			  @media all and (min-width: 900px) {
				html::after {
				  content: '';
				  background: url(${doorjames_logo}) no-repeat;
				  pointer-events: none;
				  background-size: 100% 100%;
				  position: absolute;
				  width: 120px;
				  height: 120px;
				  left: 60px;
				  bottom: 20px;
				  z-index: -1;
				}
			  }
			`;
		document.head.appendChild(styleElement);
	}

	function removeDoorJamesLogo() {
		// Remove CSS from the HTML head
		const styleElement = document.getElementById('doorJamesLogo');
		if (styleElement) {
			styleElement.remove();
		}
	}

	return <></>;
}

export default ThemeEngine;
