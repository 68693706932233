/**
 * calc the time between start & end
 * @param {number} start
 * @param {number} end
 * @returns	array of range numbers ex. start: 10 & end: 14 result: [10, 11, 12, 13]
 */
export default function getTimeInRange(start, end) {
	let hours = [];
	for (let i = start; i < end; i++) {
		hours.push(i);
	}
	return hours;
}
