export const bookingStatus = {
	checkInOpen: 'checkInOpen',
	checkedIn: 'checkedIn',
	checkedOut: 'checkedOut',
	expired: 'expired',
	confirmed: 'confirmed',
	cancelled: 'cancelled',
	checkInForgotten: 'checkInForgotten',
	checkOutForgotten: 'checkOutForgotten',
	autoCheckedIn: 'autoCheckedIn',
	available: 'available',
	approvalPending: 'approvalPending',
	declined: 'declined'
};
