export const defaultThemeColors = {
	colorMainTheme: '#5236AB',
	colorMain0: '#200A58',
	colorMain10: '#281680',
	colorMain20: '#382291',
	colorMain30: '#41299A',
	colorMain40: '#4B30A4',
	colorMain50: '#5236AB',
	colorMain60: '#6C54B8',
	colorMain70: '#8672C4',
	colorMain80: '#A99BD5',
	colorMain90: '#CBC3E6',
	colorMain95: '#CBC3E6',
	colorNeutral50: '#333333',
	colorNeutral60: '#5a5959',
	colorNeutral80: '#999999',
	colorNeutral95: '#E7E7E7',
	colorYellow50: '#FFAB25',
	mainThemeIconColor: 'icon-purple',
	iconColor: 'icon-rose',
	angleRight: 'icon-white',
	deskListIconArray: 'icon-lightMagenta',
	deskIconStroke: 'icon-desk-stroke',
	deskIconFill: 'icon-desk-fill',
	roomIconStroke: 'icon-room-stroke',
	bookingIconStroke: 'booking-icon-stroke',
	bookingDeskIconStroke: 'icon-booking-desk-stroke',
	bookingDeskIconFill: 'icon-booking-desk-fill',
	nextBookingsDeskIconStroke: 'icon-next-bookings-desk-stroke',
	customIconDefaultStroke: 'icon-default-stroke',
	customIconDefaultFill: 'icon-default-fill',
	favoriteIconColor: 'icon-favorite',
	favoriteIconActiveColor: 'icon-active-favorite',
	dashboardIconColor: 'icon-gray',
	sortingIconFill: 'icon-fill-purple',
};
