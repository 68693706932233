import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {updateSelectedAspenType} from '../../../store/bookingProcess/aspen';
import {getAspenTypesForOffice} from '../../../store/auth';
import Icon from '../../Common/Icon';
import {useEffect} from 'react';

export default function AspenTypes() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const aspenTypes = useSelector(getAspenTypesForOffice);

	useEffect(() => {
		if (aspenTypes?.types?.length > 0) {
			dispatch(updateSelectedAspenType(aspenTypes?.types[0]));
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<div className="aspen-types">
			<h4 className="section-heading">{t('serviceBooking.serviceType')}*</h4>
			<div className="aspen-types-buttons">{aspenTypes?.types?.length > 0 && <AspenTypeTabs aspenTypes={aspenTypes.types} />}</div>
		</div>
	);
}

function AspenTypeTabs({aspenTypes}) {
	const dispatch = useDispatch();
	const selectedAspenType = useSelector((state) => state.aspen.selectedAspenType);
	const cssClass = 'aspen-types-button';

	const changeSelectedAspen = (aspenType) => {
		dispatch(updateSelectedAspenType(aspenType));
	};

	return (
		<>
			{aspenTypes.map((aspenType, index) => (
				<div
					className={selectedAspenType?._id === aspenType?._id ? cssClass + ' aspen-types-button-active' : cssClass}
					key={aspenType._id}
					onClick={() => changeSelectedAspen(aspenType)}>
					<Icon iconName={aspenType.iconName ? aspenType.iconName : 'default'} cssClass={aspenTypes.iconName ? null : 'icon-white'} />
					<p className="aspen-types-label">{aspenType?.name}</p>
				</div>
			))}
		</>
	);
}
