import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Icon from '../../Common/Icon';
import {enableCard} from '../../../store/cards';
import Button from '../../Common/Button';

function CeylonFailedValidation(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const cardData = useSelector((state) => state.cards.data);
	const errorMessageKey = cardData?.errorKey;
	const nameCertificate = cardData?.nameCertificate;
	const nameUser = cardData?.nameUser;

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-wrapper">
				<p className="error-text header">{t(errorMessageKey)}</p>
				<p className="error-text info">{t('ceylon.errorCard.info')}</p>
				<Icon cssClass={'icon-red'} iconName={'exclamation-circle'}></Icon>
				{nameCertificate ? <p className="name-error">{t('ceylon.errorCard.nameOnCertificate') + ': ' + nameCertificate}</p> : null}
				{nameUser ? <p className="name-error">{t('ceylon.errorCard.nameOfUser') + ': ' + nameUser}</p> : null}
				<div className="buttons-wrapper">
					<Button variant={'primary'} height={'regular'} width={'full'} clickHandler={closeCard} translationKey={'common.okButton'} />
				</div>
			</div>
		</div>
	);
}

export default CeylonFailedValidation;
