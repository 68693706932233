import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getPaymentIntent} from '../../../api/payments';
import {CARD} from '../../../constants/cards';
import {enableCard, updateErrorCard} from '../../../store/cards';
import CheckoutForm from './CheckoutForm';

function PaymentIntent() {
	const dispatch = useDispatch();
	const [clientSecret, setClientSecret] = useState(null);
	const data = useSelector((state) => state.cards.data);
	const organizationData = useSelector((state) => state.auth.data.organizationData);
	const accId = organizationData?.bacsis?.stripe?.connect?.accountId;
	const stripePromise = loadStripe('pk_test_51LLrFaKI4s4eEUkurqdylIhGkermuiFSO6RIgYYODKAEMDdDfPx87CyB4e0Pnbm76go6EOhDWUD83YD9DtDBsk1600HLhImxGP', {
		stripeAccount: accId,
	});

	const appearance = {
		theme: 'stripe',
	};
	const options = {
		clientSecret,
		appearance,
	};

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	useEffect(async () => {
		if (clientSecret) return;

		try {
			console.log(data);
			const paymentIntentResponse = await getPaymentIntent(data);

			setClientSecret(paymentIntentResponse.data.clientSecret);
		} catch (error) {
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'payment failed'));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="payment-intent-card card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="payment-intent-card-content">
				{clientSecret ? (
					<Elements options={options} stripe={stripePromise}>
						<CheckoutForm />
					</Elements>
				) : (
					<div className="card-content">
						<div className="placeholder-container">
							<div className="ph-item">
								<div className="ph-row">
									<div className="ph-col-12"></div>
								</div>
								<div className="ph-row ph-section">
									<div className="ph-col-12"></div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default PaymentIntent;
