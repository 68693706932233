import {enableCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import {useDispatch, useSelector} from 'react-redux';
import Icon from '../../Common/Icon';
import Button from '../../Common/Button';
import {Trans} from 'react-i18next';
import Switch from '../../Common/Switcher';
import {useEffect, useState} from 'react';
import {CARD} from '../../../constants/cards';
import {proposalResponse} from '../../../api/teakBooking';
import DateTimePicker from '../../Common/DateTimePicker/DateTimePicker';
import moment from 'moment';
import {DateTime} from 'luxon';
import {t} from 'i18next';
import {loadNextBookings} from '../../../store/bookingsOverview/bookings';
import {loadDashboardData} from '../../../store/dashboard';
import {roundTimeQuarterHour} from '../../../functions/roundTimeToNextQuarter';
import {TEAK_TYPE_NAMES} from '../../../constants/teakTypeNames';
import {changeSelectedTeakType} from '../../../store/bookingProcess/entitiesBooking';

function HandleAcceptDecisionInvite(props) {
	const dispatch = useDispatch();
	const {booking, statusType} = useSelector((state) => state.cards.data);
	const userData = useSelector((state) => state.auth.data.userData);
	const [isSwitchOn, setIsSwitchOn] = useState(false);
	const {timezone} = useSelector((state) => state.auth.data.selectedOffice);
	const now = DateTime.now().setZone(timezone);
	const [startTime, setStartTime] = useState(roundTimeQuarterHour(now.plus({hour: 1})));
	const [endTime, setEndTime] = useState(roundTimeQuarterHour(now.plus({hour: 2})));
	const [timeError, setTimeError] = useState(null);
	const alreadyProposedNewTime = booking?.attendees?.find((a) => a.member?._id === userData?._id)?.proposedNewTime?.start;
	const showSwitch = statusType !== 'accept' && !alreadyProposedNewTime;
	const entitiesBooking = useSelector((state) => state.entitiesBooking);
	const {maxBookingDate} = entitiesBooking;

	useEffect(() => {
		dispatch(changeSelectedTeakType(undefined, undefined, TEAK_TYPE_NAMES.ROOM, true));
	}, [])

	useEffect(() => {
		if (!endTime || !startTime) return;
		if (DateTime.fromISO(startTime).setZone(timezone) < DateTime.now().setZone(timezone)) {
			setTimeError(<p className="error-text">{t('errorMessages.startInThePast')}</p>);
		} else if (DateTime.fromISO(startTime).setZone(timezone) > DateTime.fromISO(endTime).setZone(timezone)) {
			setTimeError(<p className="error-text">{t('errorMessages.startAfterEnd')}</p>);
		} else if (timeError) {
			setTimeError(null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startTime, endTime]);
	const closeCard = () => {
		dispatch(enableCard(false));
	};

	const proceedHandler = (statusType) => {
		dispatch(enableCard(false));

		const handleProposalResponse = (action, successMessage, errorMessage) => {
			console.log('action, success, error, ', action, successMessage, errorMessage);
			proposalResponse(booking?._id, action, isSwitchOn ? {start: startTime, end: endTime} : null)
				.then((response) => {
					const statusCode = response.status;

					if (statusCode === 200) {
						dispatch(enableCard(true));
						dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, successMessage));
						dispatch(loadNextBookings(true));
						dispatch(loadDashboardData(true));
					}
				})
				.catch((error) => {
					console.error(`Error occurred while processing the proposal (${action}):`, error);

					dispatch(enableCard(true));
					dispatch(updateErrorCard(CARD.MAIN_ERROR, errorMessage, error));
				});

			dispatch(enableCard(true));
			dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, successMessage));
		};

		switch (statusType) {
			case 'tentativelyAccept':
				handleProposalResponse('tentativelyAccept', 'successMessages.tentativelyAcceptSuccessful', 'errorMessages.tentativelyAcceptError');
				break;
			case 'decline':
				handleProposalResponse('decline', 'successMessages.declineSuccessful', 'errorMessages.declineError');
				break;
			case 'accept':
				handleProposalResponse('accept', 'successMessages.acceptSuccessful', 'errorMessages.acceptError');
				break;
			default:
				break;
		}
	};

	const changeStartTime = (newStartTime) => {
		setStartTime(newStartTime);
	};

	const changeEndTime = (newEndTime) => {
		setEndTime(newEndTime);
	};
	const handleToggle = () => {
		setIsSwitchOn((prev) => !prev);
	};
	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-data-privacy-icon-wrapper">
				<Icon cssClass={'icon-red'} iconName={'exclamation-circle'}></Icon>
			</div>
			<div className="confirmation-wrapper confirmation-data-privacy">
				<p className="success-text header">
					{statusType === 'tentativelyAccept' && <Trans i18nKey={'proposals.handleDecision.headerTentative'} />}
					{statusType === 'decline' && <Trans i18nKey={'proposals.handleDecision.headerDecline'} />}
					{statusType === 'accept' && <Trans i18nKey={'proposals.handleDecision.headerAccept'} />}
				</p>
				{showSwitch && (
					<div className="select-setting">
						<Trans i18nKey="proposals.handleDecision.infoText" />
						<Switch id="proposal-switch" isOn={isSwitchOn} handleToggle={handleToggle} onColor="var(--color-main)" />
					</div>
				)}
				{isSwitchOn && (
					<div className="booking-page-date-time-picker">
						<Trans className="title" i18nKey="common.dateTimePicker.header" />

						<DateTimePicker
							wrapperId={'date-time-booking-page'}
							startTime={moment(startTime).tz(timezone)}
							endTime={moment(endTime).tz(timezone)}
							changeStartTime={changeStartTime}
							changeEndTime={changeEndTime}
							timezone={timezone}
							disablePast={true}
							required={true}
							customTimePickerPlacement={'bottomLeft'}
							error={timeError}
							maxDate={maxBookingDate}
						/>
					</div>
				)}
				<div className="buttons-wrapper">
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						clickHandler={() => proceedHandler(statusType)}
						translationKey={'common.saveButton'}
						cssClass={'data-privacy-buttons'}
						disabled={timeError}
					/>
					<Button
						variant={'secondary'}
						height={'regular'}
						width={'full'}
						clickHandler={closeCard}
						translationKey={'common.closeButton'}
						cssClass={'data-privacy-buttons'}
					/>
				</div>
			</div>
		</div>
	);
}

export default HandleAcceptDecisionInvite;
