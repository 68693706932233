import {combineReducers} from 'redux';
import authReducer from './auth';
import bookingsReducer from './bookingsOverview/bookings';
import aspenRequests from './bookingsOverview/aspenRequests';
import dashboardReducer from './dashboard';
import entitiesBookingReducer from './bookingProcess/entitiesBooking';
import cardsReducer from './cards';
import reportProblemReducer from './reportProblem';
import visitorManagementReducer from './visitorManagement';
import settingsReducer from './settings';
import favoritesReducer from './bookingProcess/favorites';
import profilePageReducer from './profile';
import aspen from './bookingProcess/aspen';
import mapViewReducer from './bookingProcess/mapView';
import personalizedStats from './personalizedStats';
import badges from './badges';
import eventsReducer from './events';
import cedarReducer from './timeTracking'
export default combineReducers({
	auth: authReducer,
	entitiesBooking: entitiesBookingReducer,
	bookings: bookingsReducer,
	dashboard: dashboardReducer,
	cards: cardsReducer,
	reportProblem: reportProblemReducer,
	visitorManagement: visitorManagementReducer,
	settings: settingsReducer,
	favorites: favoritesReducer,
	profile: profilePageReducer,
	mapView: mapViewReducer,
	personalizedStats: personalizedStats,
	badges: badges,
	aspen: aspen,
	aspenRequests: aspenRequests,
	events: eventsReducer,
	cedar: cedarReducer
});
