import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Chip from './Chip';
import { DEFAULT_TEAK_ENTITY_TYPES } from '../../../constants/teakTypeNames';
import { getFeatureTranslation } from '../../../functions/getFeatureTranslation';

/**
 *
 * @param {*} entityData = data about teak entity
 * @returns A list of chip elements that represent the features of an entity. Chips are all disabled because the list has only display purposes.
 */
export function FeaturesChipList({ entityData }) {
	const { t } = useTranslation();
	const userData = useSelector((state) => state.auth.data.userData);
	const selectedLanguage = userData?.language ? userData?.language?.toUpperCase() : 'EN';
	const featureTranslations = useSelector((state) => state.auth.data.featureTranslations);
	const teakTypeDiscriminator = DEFAULT_TEAK_ENTITY_TYPES.includes(entityData?.__t) ? entityData?.__t : 'CustomTeakEntity';

	if (!entityData?.featureNames?.length) return <div>{t(`common.types.${teakTypeDiscriminator}.noFeaturesLabel`)}</div>;
	return entityData?.featureNames?.map((feature) => (
		<Chip
			key={feature?.name}
			iconName={feature?.icon}
			name={getFeatureTranslation(feature, featureTranslations, selectedLanguage) ?? feature?.name}
			disabled="true"
		/>
	));
}
