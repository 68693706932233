import {wengeBoothScan} from '../api/event';
import {CARD} from '../constants/cards';
import {enableCard, updateCard, updateCardAndData, updateErrorCard, updateSuccessCard} from '../store/cards';

function processBoothScanned(dispatch, identifier, t) {
	dispatch(updateCard(CARD.PLACEHOLDER));
	dispatch(enableCard(true));
	wengeBoothScan(identifier)
		.then((response) => {
			dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, t('qrScanner.boothScanned', {boothName: response?.data?.name})));
		})
		.catch((error) => {
			if (error?.response?.status === 404) {
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'qrScanner.boothNotFound'));
				return;
			} else if (error?.response?.status === 409) {
				dispatch(updateCardAndData(CARD.BOOTH_ALREADY_SCANNED));
				return;
			}
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'qrScanner.boothScanError'));
		});
}

export default processBoothScanned;
