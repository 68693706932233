import {enableCard} from '../../../store/cards';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import Icon from '../../Common/Icon';
import Button from '../../Common/Button';
import {Trans} from 'react-i18next';
import {updateSelectedEntity} from '../../../store/bookingProcess/entitiesBooking';
import {ROUTES} from '../../../constants/routes';

function HandleNotFittingCapacity(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const data = useSelector((state) => state.cards.data);

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	const proceedHandler = () => {
		dispatch(enableCard(false));
		dispatch(updateSelectedEntity(data.entity));
		history.push(ROUTES.BOOKING_CONFIRM);
	};

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-data-privacy-icon-wrapper">
				<Icon cssClass={'icon-yellow'} iconName={'exclamation-triangle'}></Icon>
			</div>
			<div className="confirmation-wrapper confirmation-data-privacy">
				<p className="success-text header">
					<Trans i18nKey="bookingPage.handleNotFittingCapacity.header" values={{maxOccupancy: data?.entity?.maxOccupancy}} />
				</p>
				<p>
					<Trans i18nKey="bookingPage.handleNotFittingCapacity.infoText" values={{maxOccupancy: data?.entity?.maxOccupancy}} />
				</p>
				<div className="buttons-wrapper">
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						clickHandler={closeCard}
						translationKey={'bookingPage.handleNotFittingCapacity.changeButton'}
						cssClass={'data-privacy-buttons'}
					/>
					<Button
						variant={'secondary'}
						height={'regular'}
						width={'full'}
						clickHandler={proceedHandler}
						translationKey={'bookingPage.handleNotFittingCapacity.proceed'}
						cssClass={'data-privacy-buttons'}
					/>
				</div>
			</div>
		</div>
	);
}

export default HandleNotFittingCapacity;
