import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import Icon from '../../Common/Icon';
import {enableCard} from '../../../store/cards';
import Button from '../../Common/Button';

function SuccessCard() {
	const {t} = useTranslation();
	const dispatch = useDispatch();

	const successMessageKey = useSelector((state) => state.cards.successCardProps.successKey);
	const successText = useSelector((state) => state.cards.successCardProps.successText);
	const iconName = useSelector((state) => state.cards.successCardProps.iconName);
	const iconColor = useSelector((state) => state.cards.successCardProps.iconColor);
	let icon = <Icon cssClass={iconColor ? iconColor : 'icon-purple'} iconName={iconName ? iconName : 'check-circle'} />;

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-wrapper">
				{icon}
				<p className="success-text header">{t(successMessageKey)}</p>
				{successText ? <p className="success-text text">{t(successText)}</p> : null}
				<div className="buttons-wrapper">
					<Button variant={'primary'} height={'regular'} width={'full'} clickHandler={closeCard} translationKey={'common.okButton'} />
				</div>
			</div>
		</div>
	);
}

export default SuccessCard;
