import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime, Duration } from 'luxon';
import { useLocation, useHistory } from 'react-router';
import { CARD } from '../../constants/cards';
import { ROUTES } from '../../constants/routes';
import { addDataToCard, enableCard, updateCard, loadBooking, updateErrorCard, updateCardAndData, updateConfirmationCard } from '../../store/cards';
import { updateDetailsBookingData } from '../../store/bookingsOverview/bookings';
import { getAreaNameBasedOnId, getOfficeBasedOnId, getTeakTypeObj, updateActionSheet } from '../../store/auth';
import { changeArea, changeDesk, changeRoom } from '../../store/reportProblem';
import { getTeakEntitiesFull } from '../../functions/getTeakEntitiesFull';
import { bookingStatus } from '../../constants/bookingStatus';
import { addRepeatBookingObject, showBookingOnMap, updateAttendeeList, updateEditBookingView } from '../../store/bookingProcess/entitiesBooking';
import { enableQuestionnairePage } from '../../store/dashboard';
import { limit } from '../../constants/limit';
import { filteredAttendeeList } from '../BookingsOverview/BookingDetails';
import { updateCheckedList } from '../../store/settings';
import { TEAK_TYPE_NAMES } from '../../constants/teakTypeNames';
import { BOTTOM_MAP_CARDS } from '../../constants/bottomCardTypes';
import { confirmActionsMap } from '../Cards/Common/ConfirmAction/confirmActions';

// * Component holding the action menu shown when users select a booking in Bookings Overview page.
const CheckProposal = (props) => {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.auth.data.actionSheetData);
	const history = useHistory();
	const location = useLocation();
	const areaId = data?.entity?.area._id ? data?.entity?.area._id : data?.entity?.area;
	const areaName = useSelector(getAreaNameBasedOnId(areaId)) || data?.entity?.area?.name;
	const teakEntityName = data?.entity?.name;
	const areasFromStore = useSelector((state) => state.auth.data.areas);
	const featureTranslations = useSelector((state) => state.auth.data.featureTranslations);
	const lastCompleted = useSelector((state) => state.dashboard.data.plusStatusLastCompleted);
	const validCeylon = useSelector((state) => state.dashboard.data.validCeylon);
	const userData = useSelector((state) => state.auth.data.userData);
	const teakFeaturesFromStore = useSelector((state) => state.auth.data.teakFeatures);
	const officeId = data?.entity?.office._id ? data?.entity?.office?._id : data?.entity?.office;
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const officeObject = useSelector(getOfficeBasedOnId(officeId));
	const authData = useSelector((state) => state.auth.data);
	const booking = useSelector((state) => state.bookings.detailsBookingData.booking);
	const [isUserCheckedIn, setIsUserCheckedIn] = useState(true);
	const [timeLeftUntilCheckin, setTimeLeftUntilCheckin] = useState(null);
	const [checkinButtonDisabled, setCheckinButtonDisabled] = useState(false);
	const bookingDataStatus = data?.booking?.status;
	const teakTypeForOffice = authData?.teakTypeArray?.find((teakType) => teakType.office === officeId);
	const isMemberHost = data?.booking?.member?.id === userData?._id;
	const teakTypeObj = useSelector(getTeakTypeObj(data?.entity?.teakType));
	const { actionSheetData } = authData;
	const proposedNewTime = actionSheetData?.proposedNewTime;
	const timezone = selectedOffice?.timezone;
	
	// menu item functions
	const redirectToEditBooking = (e) => {
		e.stopPropagation();
		props.closeActionSheet();
		// const filteredAttendees = filteredAttendeeList(data?.booking.attendees, data?.booking, userData);
		// dispatch(updateAttendeeList(filteredAttendees));
		// dispatch(updateDetailsBookingData({ booking: data.booking, entity: data.entity }));
		// console.log("data.booking", data?.booking);
		const bookingWithProposalTime = {
			...booking,
			start: {
				time: DateTime.fromISO(proposedNewTime?.start).setZone(timezone).toISO()
			},
			end: {
				time: DateTime.fromISO(proposedNewTime?.end).setZone(timezone).toISO()
			}
		}
		console.log("proposedNewTime",proposedNewTime);
		console.log("actionsheet:",actionSheetData);
		history.push(ROUTES.EDIT_BOOKING, { booking: bookingWithProposalTime });
	};


	return (
		<div className="action-sheet-option" onClick={redirectToEditBooking}>
			{t('proposals.checkAccept')}
		</div>

	);
};

export default CheckProposal;
