export const teakEntityStatus = {
	blocked: {
		status: 'blocked',
		colorOnMap: '#979797',
	},
	onlyCheckIns: {
		status: 'onlyCheckIns',
		colorOnMap: '#5BACEC',
	},
	available: {
		status: 'available',
		colorOnMap: '#2ac769',
	},
	booked: {
		status: 'booked',
		colorOnMap: '#dd052b',
	},
	checkedIn: {
		status: 'checkedIn',
		colorOnMap: '#dd052b',
	},
	autoCheckedIn: {
		status: 'autoCheckedIn',
		colorOnMap: '#dd052b',
	},
	checkInOpen: {
		status: 'checkInOpen',
		colorOnMap: '#2ac769',
	},
	entityRestricted: {
		status: 'entityRestricted',
		colorOnMap: '#979797',
	},
	areaRestricted: {
		status: 'areaRestricted',
		colorOnMap: '#979797',
	},
	areaDeactivated: {
		status: 'areaDeactivated',
		colorOnMap: '#979797',
	},
};
