import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { ROUTES } from '../../../constants/routes';
import Button from '../../Common/Button';

function CheckoutForm() {
	const stripe = useStripe();
	const elements = useElements();
	const [isLoading, setIsLoading] = useState(false);
	const data = useSelector((state) => state.cards.data);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!stripe || !elements) return;

		setIsLoading(true);

		window.localStorage.setItem('paymentIntentEntityID', data.entityId);
		window.localStorage.setItem('paymentIntentBookingStartTime', data.startTime);
		window.localStorage.setItem('paymentIntentBookingEndTime', data.endTime);
		window.localStorage.setItem('paymentAmount', data.amount);
		const returnUrl = window.location.origin + ROUTES.SUCCESS_PAYMENT_REDIRECT;
		await stripe.confirmPayment({
			elements,
			confirmParams: {
				// Make sure to change this to your payment completion page
				return_url: returnUrl,
			},
		});

		// This point will only be reached if there is an immediate error when
		// confirming the payment. Otherwise, your customer will be redirected to
		// your `return_url`. For some payment methods like iDEAL, your customer will
		// be redirected to an intermediate site first to authorize the payment, then
		// redirected to the `return_url`.
		// if (error.type === 'card_error' || error.type === 'validation_error') {
		// 	setMessage(error.message);
		// } else {
		// 	setMessage('An unexpected error occurred.');
		// }

		setIsLoading(false);
	}
	return (
		<form id="payment-form" onSubmit={handleSubmit}>
			<PaymentElement id="payment-element" />
			<div className="buttons-wrapper">
				<Button
					id="submit"
					cssClass={"button"}
					translationKey={'Pay'}
					loading={isLoading}
					disabled={isLoading || !stripe || !elements}
				/>
			</div>
		</form>
	)
}

export default CheckoutForm;
