import {Trans} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';

import {enableCard} from '../../../store/cards';
import Button from '../../Common/Button';
import Icon from '../../Common/Icon';

function NotFoundCard() {
	const history = useHistory();
	const dispatch = useDispatch();
	const cardData = useSelector((state) => state.cards.data);
	const doorjamesEntity = cardData?.elementName;
	const doorjamesLocation = cardData?.areaName;
	const mapViewEmptyMode = cardData?.mapEmptyMode;

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	const openAreaListPage = () => {
		closeCard();
		history.push({
			pathname: ROUTES.AREA_LIST,
			state: {
				source: mapViewEmptyMode ? 'mapViewEmptyMode' : 'mapView',
				element: cardData.element,
			},
		});
	};

	return (
		<div className="card not-found-card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="data-wrapper">
				<span className="search-icon-wrapper">
					<Icon cssClass="search-icon icon-yellow" iconName="search" />
				</span>
				<p className="header">
					<Trans i18nKey="notFound.text" doorjamesEntity={doorjamesEntity}>
						{{doorjamesEntity}}
					</Trans>
				</p>
				{doorjamesLocation ? (
					<p className="text">
						<Trans i18nKey="notFound.explanation" doorjamesEntity={doorjamesEntity} doorjamesLocation={doorjamesLocation}>
							Sorry, we couldn't find any {{doorjamesEntity}} element in <strong>{{doorjamesLocation}}</strong>. Please switch to
							another area or do another search.
						</Trans>
					</p>
				) : null}
				<div className="buttons-wrapper">
					{doorjamesLocation ? (
						<Button
							variant={'primary'}
							height={'regular'}
							width={'full'}
							clickHandler={openAreaListPage}
							translationKey={'notFound.searchOtherAreas'}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
}

export default NotFoundCard;
