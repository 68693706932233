import {useTranslation} from 'react-i18next';
/**
 *
 * @param {*} props.cssClass = css class added to the button element
 * @param {*} props.disabled = if the button is disabled or not
 * @param {*} props.clickHandler = callback for button click
 * @returns
 */
function ButtonClose(props) {
	const {t} = useTranslation();

	return (
		<div className="close-button" onClick={props.clickHandler}>
			<p>{t('common.closeButton')}</p>
		</div>
	);
}

export default ButtonClose;
