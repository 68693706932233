import {useCallback, useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import {DateTime} from 'luxon';
import SearchInput from '../../Common/Inputs/SearchInput';
import Icon from '../../Common/Icon';
import NotificationItem from './NotificationItem';
import noNotificationsImage from '../../../image/no-notifications.svg';
import NotificationDetails from './NotificationDetails';
import {readNotification} from '../../../api/notifications';
import {enableBottomMenu} from '../../../store/auth';
import {updateNotificationDetailsData, updateNotificationsList} from '../../../store/profile';
import {updateConfirmationCard} from '../../../store/cards';
import {NOTIFICATION_NAMES} from '../../../constants/notificationsNames';
import SecondaryHeader from '../../Common/Header/SecondaryHeader';
import Button from '../../Common/Button';
import {confirmActionsMap} from '../../Cards/Common/ConfirmAction/confirmActions';

const shouldIncludeNotification = (item, searchValue, t) => {
	const trimSearchValue = searchValue.trim().toLowerCase(); // Normalize for case-insensitive search
	const createdAt = DateTime.fromISO(item.createdAt).toLocaleString(); // Formatted date
	const header = t(`notifications.notificationItem.${NOTIFICATION_NAMES[item.name]}.header`).toLowerCase();
	const subHeader = t(`notifications.notificationItem.${NOTIFICATION_NAMES[item.name]}.info`).toLowerCase();

	return createdAt.includes(trimSearchValue) || header.includes(trimSearchValue) || subHeader.includes(trimSearchValue);
};

function Notifications() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const userData = useSelector((state) => state.auth.data.userData);
	const [searchValue, setSearchValue] = useState('');
	const notificationList = useSelector((state) => state.profile.notifications.items);
	const [notificationItems, setNotificationItems] = useState([]);
	const loading = useSelector((state) => state.profile.notifications.ui.loading);
	const [nbOfNewNotifications, setNbOfNewNotifications] = useState(0);
	const notificationDetails = useSelector((state) => state.profile.notifications.notificationDetailsData);
	const [selectedNotifications, setSelectedNotifications] = useState([]);
	const [selectionViewEnabled, setSelectionViewEnabled] = useState(false);

	const goBackToEntryPoint = () => {
		history.goBack();
	};

	const resetSelection = useCallback(() => {
		setSelectedNotifications([]);
		setSelectionViewEnabled(false);
	}, [setSelectedNotifications, setSelectionViewEnabled]);

	const notificationItemRedirect = useCallback(
		(item) => {
			resetSelection();
			const receiversList = item?.receivers;

			if (receiversList) {
				const memberInReceiversList = receiversList.find((receiver) => receiver.receivedBy?.member === userData?._id);

				if (memberInReceiversList && !memberInReceiversList?.receivedBy?.readAt) {
					readNotification(item._id, userData._id).then((response) => {
						dispatch(updateNotificationsList(userData._id));
					});
				}
			}

			dispatch(updateNotificationDetailsData(item));
		},
		[resetSelection, dispatch, userData._id],
	);

	useEffect(() => {
		dispatch(enableBottomMenu(false));
		dispatch(updateNotificationsList(userData._id));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleNotificationSelection = useCallback(
		(notification) => {
			const currentSelectionArray = selectedNotifications.slice();

			if (currentSelectionArray?.length === 0) {
				setSelectedNotifications([notification]);
				return;
			}

			const indexOfNotification = currentSelectionArray.indexOf(notification);

			if (indexOfNotification > -1) {
				currentSelectionArray.splice(indexOfNotification, 1);
			} else {
				currentSelectionArray.push(notification);
			}

			setSelectedNotifications(currentSelectionArray);
		},
		[selectedNotifications, setSelectedNotifications],
	);

	const handleDeleteButton = () => {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.DELETE_NOTIFICATION,
				{
					headerKey: 'notifications.confirmDeleteMultipleNotifications',
					confirmButtonKey: 'notifications.notificationDetails.deleteMultipleNotifications',
				},
				{notificationIds: selectedNotifications, userData},
			),
		);

		resetSelection();
	};

	const handleSelect = () => {
		if (selectionViewEnabled) {
			resetSelection();
		} else {
			setSelectionViewEnabled(true);
		}
	};

	const handleSelectAll = () => {
		const allItemIds = [];
		notificationList.forEach((item) => {
			allItemIds.push(item._id);
		});
		setSelectedNotifications(allItemIds);
	};

	useEffect(() => {
		const processNotifications = () => {
			if (!notificationList) {
				setNotificationItems([]);
				setNbOfNewNotifications(0);
				return;
			}

			const sortedNotificationList = [...notificationList].sort(
				(a, b) => DateTime.fromISO(b?.createdAt).toMillis() - DateTime.fromISO(a?.createdAt).toMillis(),
			);

			let newNotificationCount = 0;
			const items = sortedNotificationList.reduce((accumulator, item) => {
				const notificationType = NOTIFICATION_NAMES[item.name];
				if (!notificationType) return accumulator;

				const isNotificationRead =
					item?.receivers?.some((receiver) => receiver.receivedBy?.member === userData?._id && receiver.receivedBy?.readAt) ?? false;
				if (!isNotificationRead) newNotificationCount++;

				if (shouldIncludeNotification(item, searchValue, t)) {
					accumulator.push(
						<NotificationItem
							read={isNotificationRead}
							clickHandler={() => notificationItemRedirect(item)}
							key={item._id}
							item={item}
							selectionViewEnabled={selectionViewEnabled}
							select={handleNotificationSelection}
							checked={selectedNotifications?.indexOf(item._id) > -1}
						/>,
					);
				}

				return accumulator;
			}, []);

			setNotificationItems(items);
			setNbOfNewNotifications(newNotificationCount);
		};

		processNotifications();
	}, [
		notificationList,
		searchValue,
		selectionViewEnabled,
		selectedNotifications,
		handleNotificationSelection,
		notificationItemRedirect,
		t,
		userData?._id,
	]);

	if (notificationDetails)
		return (
			<NotificationDetails
				closePage={() => {
					dispatch(updateNotificationDetailsData(null));
				}}
				data={notificationDetails}
			/>
		);

	return (
		<div className="notifications-page">
			<SecondaryHeader
				headerTranslationKey={'profile.headers.notifications'}
				backButtonClickHandler={goBackToEntryPoint}
				disableBoxShadow={true}
			/>
			{(notificationItems.length > 0 || searchValue) && (
				<SearchInput
					defaultValue={searchValue}
					placeholderKey={'notifications.searchPlaceholder'}
					onChange={(e) => setSearchValue(e.target.value)}
					cleanUpSearch={() => setSearchValue('')}
				/>
			)}
			{loading ? (
				<div className="loading-wrapper">
					<ClipLoader size={70} />
				</div>
			) : (
				<>
					{notificationItems.length > 0 ? (
						<>
							<div className="notifications-header">
								<div className={selectionViewEnabled ? 'notifications-selectButtons' : ''}>
									{selectionViewEnabled ? (
										<div className="notifications-selectAll" onClick={handleSelectAll}>
											{t('common.selectAllButton')}
										</div>
									) : null}
									<div className="notifications-select" onClick={handleSelect}>
										{t(selectionViewEnabled ? 'common.cancelButton' : 'common.selectButton')}
									</div>
								</div>
								<div className="notifications-amount">
									<div className="icon-wrapper">
										<Icon cssClass="icon-black" type="far" iconName="bell" />
										{nbOfNewNotifications > 0 ? <div className="red-dot"></div> : null}
									</div>
									<Trans i18nKey="notifications.nbOfNewNotifications" nbOfNewNotifications={nbOfNewNotifications}>
										{{nbOfNewNotifications}}
									</Trans>
								</div>
							</div>
							<div className="notifications-wrapper">
								<div className="notifications-list">{notificationItems}</div>
							</div>
							<div className={'delete-btn-container' + (selectedNotifications?.length > 0 ? '' : '-hidden')}>
								<Button
									cssClass={'delete-btn'}
									variant={'negative'}
									height={'regular'}
									width={'full'}
									translationKey={'common.deleteButton'}
									clickHandler={handleDeleteButton}
								/>
							</div>
						</>
					) : (
						<>
							{!searchValue || searchValue === '' ? (
								<div className="no-notification-wrapper">
									<img alt="no-notifications" src={noNotificationsImage} />
									<p className="bold">{t('notifications.noNotifications')}</p>
									<p className="text-info">{t('notifications.noNotificationsInfo')}</p>
								</div>
							) : (
								<p key={0} className="show-all-visitors-info-message">
									{t('visitorManagement.showAllVisitors.noSearchResult')}
								</p>
							)}
						</>
					)}
				</>
			)}
		</div>
	);
}
export default Notifications;
