import {getUpdatedStatistics} from '../api/additionals';
import getProgressFromYesterday from './getProgressFromYesterday';

export async function getStatisticsForOffice(officeId) {
	const newTeakTypeObject = {};
	try {
		const updatedStatsData = (await getUpdatedStatistics(officeId)).data;
		const getTotalEntitiesByType = (type) => updatedStatsData.find((teakTypeStats) => teakTypeStats?.teakType === type)?.totalTeakEntities || 0;
		const getTotalBookedEntitiesTodayByType = (type) =>
			updatedStatsData.find((teakTypeStats) => teakTypeStats?.teakType === type)?.teakBookingStats?.teakBookingStatsToday
				.totalBookedTeakEntities || 0;
		const getTotalBookedEntitiesYesterdayByType = (type) =>
			updatedStatsData.find((teakTypeStats) => teakTypeStats?.teakType === type)?.teakBookingStats?.teakBookingStatsYesterday
				.totalBookedTeakEntities || 0;
		const getActiveEntitiesByType = (type) => updatedStatsData.find((teakTypeStats) => teakTypeStats?.teakType === type)?.activeTeakEntities || 0;


		const createStatsObjectForType = (type) => {
			const totalEntities = getTotalEntitiesByType(type);
			const totalBookedEntitiesToday = getTotalBookedEntitiesTodayByType(type);
			const totalBookedEntitiesYesterday = getTotalBookedEntitiesYesterdayByType(type);
			const activeEntities = getActiveEntitiesByType(type)
			return {totalEntities, totalBookedEntitiesToday, totalBookedEntitiesYesterday, activeEntities};
		};

		const workplace = createStatsObjectForType('TeakTypeWorkplace');
		const meetingRoom = createStatsObjectForType('TeakTypeMeetingRoom');
		const parkingSpace = createStatsObjectForType('TeakTypeParkingSpace');
		const totalEntities = workplace.totalEntities + meetingRoom.totalEntities + parkingSpace.totalEntities;
		const totalBookedEntitiesToday =
			workplace.totalBookedEntitiesToday + meetingRoom.totalBookedEntitiesToday + parkingSpace.totalBookedEntitiesToday;
		const totalBookedEntitiesYesterday =
			workplace.totalBookedEntitiesYesterday + meetingRoom.totalBookedEntitiesYesterday + parkingSpace.totalBookedEntitiesYesterday;
		const totalProgressFromYesterday = getProgressFromYesterday(totalBookedEntitiesYesterday, totalBookedEntitiesToday);
		const totalActiveEntities = workplace.activeEntities + meetingRoom.activeEntities + parkingSpace.activeEntities;
		const total = {totalEntities, totalBookedEntitiesToday, totalBookedEntitiesYesterday, totalProgressFromYesterday, totalActiveEntities};

		const stats = {
			workplace,
			meetingRoom,
			parkingSpace,
			total,
		};

		newTeakTypeObject.stats = stats;
	} catch (error) {
		console.log(error);
	}

	return newTeakTypeObject;
}
