import {createSlice} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';
import {getAspenRequests} from '../../api/aspen';
import {limit} from '../../constants/limit';

const slice = createSlice({
	name: 'aspenRequests',
	initialState: {
		nextAspenRequestsList: [],
		pastAspenRequestsList: [],
		ui: {
			loadingAspenRequests: false,
		},
	},
	reducers: {
		nextAspenRequestsListChanged: (aspenRequests, action) => {
			aspenRequests.nextAspenRequestsList = action.payload;
		},
		pastAspenRequestsListChanged: (aspenRequests, action) => {
			aspenRequests.pastAspenRequestsList = action.payload;
		},
		loadingAspenRequestsChanged: (aspenRequests, action) => {
			aspenRequests.ui.loadingAspenRequests = action.payload;
		},
	},
});

export const {nextAspenRequestsListChanged, pastAspenRequestsListChanged, loadingAspenRequestsChanged} = slice.actions;

export default slice.reducer;

/**
 * @param {boolean} past boolean to check if it should load next or past aspen requests
 * @returns loads either next or past aspen requests into the store
 */
export const loadAspenRequests = (past) => async (dispatch, getState) => {
	dispatch({type: loadingAspenRequestsChanged.type, payload: true});
	const selectedOffice = getState().auth.data.selectedOffice;
	let start = DateTime.now().toUTC().toISO();
	let end = DateTime.now().plus({days: limit.aspenRequestInAdvance}).toUTC().toISO();
	if (past) {
		start = DateTime.now().minus({days: limit.aspenRequestInAdvance}).toUTC().toISO();
		end = DateTime.now().toUTC().toISO();
	}

	try {
		const aspenRequestsResponse = await getAspenRequests(selectedOffice?._id, start, end);
		addAspenRequestsToStore(aspenRequestsResponse.data);
		disableLoading();
	} catch (error) {
		console.log(error);
		disableLoading();
	}

	function addAspenRequestsToStore(aspenRequests) {
		if (past) {
			dispatch({type: pastAspenRequestsListChanged.type, payload: aspenRequests});
		} else {
			dispatch({type: nextAspenRequestsListChanged.type, payload: aspenRequests});
		}
	}
	function disableLoading() {
		dispatch({type: loadingAspenRequestsChanged.type, payload: false});
	}
};
//selectors
