import {useEffect, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {DateTime} from 'luxon';
import {ROUTES} from '../../../constants/routes';
import {CARD} from '../../../constants/cards';
import {NOTIFICATION_NAMES} from '../../../constants/notificationsNames';
import Button from '../../Common/Button';
import {updateDetailsBookingData} from '../../../store/bookingsOverview/bookings';
import {showBookingOnMap} from '../../../store/bookingProcess/entitiesBooking';
import {getOfficeBasedOnId} from '../../../store/auth';
import {enableCard, updateConfirmationCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import {getTeakEntitiesFull} from '../../../functions/getTeakEntitiesFull';
import SecondaryHeader from '../../Common/Header/SecondaryHeader';
import {pineVisitorCheckout} from '../../../api/visitorManagement';
import {updateNotificationsList} from '../../../store/profile';
import {editVisitorList, changeIsRoomBookingOn, updateMeetingDetails} from '../../../store/visitorManagement';
import FormattedDateTime from '../../Common/DateTimePicker/FormattedDateTime';
import {getTeakTypeName} from '../../Booking/BookingPage/AssetTypes';
import {BOTTOM_MAP_CARDS} from '../../../constants/bottomCardTypes';
import {confirmActionsMap} from '../../Cards/Common/ConfirmAction/confirmActions';

const cancelledBookingNotifications = [
	'teak.booking.user.areaDeactivateCancelledBooking',
	'teak.booking.user.cancelledBooking.assetDeactivation',
	'teak.booking.user.cancelledBooking.restrictionChange',
	'teak.booking.user.cancelledBooking.onlyCheckInsChange',
	'teak.booking.user.adminCancelledBooking',
	'teak.booking.user.oakCrisisCancelledBooking',
	'teak.booking.request.declined',
];
function NotificationDetails(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const item = props.data;
	const [notificationType, setNotificationType] = useState(null);
	const [buttonState, setButtonState] = useState(null);
	const [timestamp, setTimestamp] = useState(0);
	const [error] = useState(null);
	const userLocale = useSelector((state) => state.auth.data.locale);
	const userData = useSelector((state) => state.auth.data.userData);
	const areasFromStore = useSelector((state) => state.auth.data.areas);
	const featureTranslations = useSelector((state) => state.auth.data.featureTranslations);
	const teakFeaturesFromStore = useSelector((state) => state.auth.data.teakFeatures);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const dateFormat = useSelector((state) => state.auth.data.dateFormat);
	const teakBooking = item?.metadata?.teakBooking;
	const teakEntity = teakBooking?.teakEntity;
	const officeOfBooking = useSelector(getOfficeBasedOnId(teakBooking?.office?._id));
	const pineVisitData = item?.metadata?.pineVisit;
	const pineInvitationData = item?.metadata?.pineInvitation;
	const isPineVisitorCheckedOut = pineVisitData?.checkOut;
	const pineVisitorName = pineVisitData?.pineVisitor?.name
		? `${pineVisitData?.pineVisitor?.name?.first} ${pineVisitData?.pineVisitor?.name?.last}`
		: '';
	const pineVisitorCheckinTime = pineVisitData?.checkIn?.time ? DateTime.fromISO(pineVisitData?.checkIn?.time) : null;
	const pineVisitorCheckinHour = pineVisitData?.checkIn?.time
		? pineVisitorCheckinTime.setZone(selectedOffice.timezone).setLocale(userLocale).toLocaleString(DateTime.TIME_SIMPLE)
		: null;
	const pineVisitorCheckinDate = pineVisitData?.checkIn?.time ? pineVisitorCheckinTime.setZone(selectedOffice.timezone).toFormat(dateFormat) : null;
	const pineVisitorCheckinDay = pineVisitData?.checkIn?.time ? pineVisitorCheckinTime.setZone(selectedOffice.timezone).toFormat('ccc') : null;

	let hostNameFull = teakBooking?.member?.name?.first + ' ' + teakBooking?.member?.name?.last;
	const colleagueNameFull = item?.metadata?.attendee?.name?.first + ' ' + item?.metadata?.attendee?.name?.last;
	const adminEmail = useSelector((state) => state.auth.data.selectedOffice.email);

	const checkBookingAction = () => {
		const entityFull = getTeakEntitiesFull(areasFromStore, featureTranslations, userData, teakFeaturesFromStore, [
			item?.metadata?.teakBooking?.teakEntity,
		]); // populate feature names and area name
		dispatch(
			updateDetailsBookingData({
				booking: item?.metadata?.teakBooking,
				entity: entityFull[0],
			}),
		);
		history.push(ROUTES.SEE_DETAILS_OF_BOOKING);
	};

	const createNewMeetingHandler = () => {
		dispatch(changeIsRoomBookingOn(true));
		dispatch(updateMeetingDetails({summary: pineInvitationData.summary, description: pineInvitationData.description}));
		dispatch(editVisitorList(pineInvitationData.attendees.map((attendee) => attendee.pineVisitor)));
		history.push({
			pathname: ROUTES.VISITOR.NEW_MEETING,
			state: {
				source: 'notificationDetails',
			},
		});
	};

	const bookAnotherEntity = async () => {
		const booking = {...teakBooking, status: teakBooking?.cancelled?.reason === 'requestDeclined' ? 'declined' : 'cancelled'};
		dispatch(showBookingOnMap(booking, {...teakEntity, teakType: booking?.teakType?._id}, BOTTOM_MAP_CARDS.ownBooking));
		history.push(ROUTES.BOOKING_PAGE);
	};

	const deleteNotificationHandler = () => {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.DELETE_NOTIFICATION,
				{
					headerKey: 'notifications.confirmDeleteNotification',
					confirmButtonKey: 'notifications.notificationDetails.deleteNotification',
				},
				{notificationIds: [item._id], userData},
			),
		);
	};

	const checkVisitorOut = () => {
		dispatch(enableCard(true));
		pineVisitorCheckout(pineVisitData?.pineVisitor?._id)
			.then((response) => {
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'notifications.notificationDetails.pineVisitorForgotCheckOut.successMessageCheckout'));
				dispatch(updateNotificationsList(userData._id));
				props.closePage();
			})
			.catch(() => {
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'errorMessages.general'));
			});
	};

	useEffect(() => {
		const createdAt = DateTime.fromISO(item.createdAt);
		const now = DateTime.now();
		setNotificationType(NOTIFICATION_NAMES[item.name]);

		if (cancelledBookingNotifications.includes(item.name)) {
			if (selectedOffice.id === officeOfBooking?._id) {
				setButtonState({
					translationKey: `common.bookAnother.${teakEntity?.__t ? teakEntity?.__t : 'noType'}`,
					clickHandler: bookAnotherEntity,
				});
			}
		} else {
			// eslint-disable-next-line default-case
			switch (item.name) {
				case 'teak.booking.member.inviteToRoomBooking':
					if (selectedOffice.id === officeOfBooking?._id) {
						setButtonState({
							translationKey: 'common.bookingDetails',
							clickHandler: checkBookingAction,
						});
					}
					break;
				case 'teak.booking.member.attendeeCancelledRoomBooking':
					if (teakBooking && selectedOffice.id === officeOfBooking?._id) {
						setButtonState({
							translationKey: 'common.bookingDetails',
							clickHandler: checkBookingAction,
						});
					}
					break;
				case 'pine.invitation.user.cancelledPineInvitationWithRoomBooking':
					if (selectedOffice.id === officeOfBooking?._id) {
						setButtonState({
							translationKey: `common.buttons.createNewMeeting`,
							clickHandler: createNewMeetingHandler,
						});
					}
					break;
			}
		}

		if (createdAt.startOf('day').toISO() === now.startOf('day').toISO()) {
			setTimestamp(t('common.todayAt') + ' ' + createdAt.setLocale(userLocale).toLocaleString(DateTime.TIME_SIMPLE));
			return;
		} else if (createdAt.plus({days: 1}).startOf('day').toISO() === now.startOf('day').toISO()) {
			setTimestamp(t('common.yesterdayAt') + ' ' + createdAt.setLocale(userLocale).toLocaleString(DateTime.TIME_SIMPLE));
			return;
		}

		setTimestamp(createdAt.setLocale(userLocale).toFormat(dateFormat));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item]);

	let meetingDate;

	if (notificationType === 'cancelledPineInvitationWithRoomBooking') {
		const startTime = item.metadata?.teakBooking?.start?.time ? item.metadata?.teakBooking?.start?.time : item.metadata?.pineInvitation?.start;
		meetingDate = DateTime.fromISO(startTime).setZone(officeOfBooking?.timezone).toFormat('MM MMM');
	}

	return (
		<div className="notification-details-page">
			<SecondaryHeader backButtonClickHandler={props.closePage} headerTranslationKey={t('profile.headers.notificationDetails')} />
			<div className="notification-details-page-content">
				<div className="message-wrapper">
					<div className="message-header">
						<p className="bold">{t('common.message')}</p>
						<p className="timespan">{timestamp}</p>
					</div>
					<p className="bold notification-header">
						<Trans
							i18nKey={`notifications.notificationDetails.${notificationType}.header`}
							values={{visitorName: pineVisitorName, meetingDate: meetingDate}}
						/>
					</p>
					<p>
						{notificationType === 'inviteToRoomBooking' && !hostNameFull && (
							<Trans
								i18nKey={`notifications.notificationDetails.${notificationType}.hostUndefined`}
								colleagueNameFull={colleagueNameFull}
								hostNameFull={hostNameFull}
								meetingDate={meetingDate}>
								{{colleagueNameFull}}
								{{hostNameFull}}
								{{meetingDate}}
							</Trans>
						)}
						{cancelledBookingNotifications.includes(item.name) ? (
							<>
								<div className="cancellation-reason">
									<span className="bold">{t('notifications.notificationDetails.cancellationReason')}</span>
									<span>{teakBooking?.cancelled?.comment}</span>
								</div>
								{t('notifications.notificationDetails.info')}
								&nbsp;
								{adminEmail
									? t('notifications.notificationDetails.infoWithEmail', {adminEmail})
									: t('notifications.notificationDetails.infoWithoutEmail')}
							</>
						) : (
							t(`notifications.notificationDetails.${notificationType}.info`, {
								hostNameFull: hostNameFull,
								colleagueNameFull: colleagueNameFull,
							})
						)}
						{notificationType === 'pineVisitorForgotCheckOut' &&
							pineInvitationData &&
							!isPineVisitorCheckedOut &&
							t('notifications.notificationDetails.pineVisitorForgotCheckOut.checkoutInfo')}
					</p>
				</div>
				{teakBooking ? (
					<div className="notification-details-wrapper">
						<p className="notification-header bold">{t('notifications.notificationDetails.bookingDetails')}</p>

						{teakBooking?.member?.name && (
							<p className="notification-info">
								<span className="bold">{t(`yourBooking.headers.bookedBy`)}</span>:{' '}
								{`${teakBooking?.member?.name?.first} ${teakBooking?.member?.name?.last} ${t('yourBooking.headers.host')}`}
							</p>
						)}
						{pineInvitationData?.summary && (
							<p className="notification-info">
								<span className="bold">{t(`common.meetingName`)}</span>: {pineInvitationData?.summary}
							</p>
						)}
						{teakEntity?.name && (
							<p className="notification-info">
								<span className="bold">{getTeakTypeName(teakBooking?.teakType, t)}</span>: {teakEntity?.name}
							</p>
						)}
						{teakBooking?.office?.name && (
							<p className="notification-info">
								<span className="bold">{t('common.office')}</span>: {teakBooking?.office?.name}
							</p>
						)}
						{teakEntity?.area?.name && (
							<p className="notification-info">
								<span className="bold">{t('common.areaLabel')}</span>: {teakEntity?.area?.name}
							</p>
						)}
						{teakBooking?.start?.time && (
							<p className="notification-info">
								<span className="bold">{t('common.dateTimePicker.from')}</span>:
								<FormattedDateTime dateTime={teakBooking?.start?.time} timezone={officeOfBooking?.timezone} />
							</p>
						)}
						{teakBooking?.end?.time && (
							<p className="notification-info">
								<span className="bold">{t('common.dateTimePicker.to')}</span>:
								<FormattedDateTime dateTime={DateTime.fromISO(teakBooking?.end?.time).toISO()} timezone={officeOfBooking?.timezone} />
							</p>
						)}
					</div>
				) : null}
				{pineVisitData && pineInvitationData && (
					<div className="notification-details-wrapper">
						<p className="notification-info">
							<span className="bold">{t('common.firstName')}</span>: {pineVisitData?.pineVisitor?.name?.first}
						</p>
						<p className="notification-info">
							<span className="bold">{t('common.lastName')}</span>: {pineVisitData?.pineVisitor?.name?.last}
						</p>

						{pineVisitData?.pineVisitor?.email && (
							<p className="notification-info">
								<span className="bold">{t('common.email')}</span>: {pineVisitData?.pineVisitor?.email}
							</p>
						)}

						{pineVisitData?.pineVisitor?.phone && (
							<p className="notification-info">
								<span className="bold">{t('common.phoneNumber')}</span>: {pineVisitData?.pineVisitor?.phone}
							</p>
						)}

						{pineVisitorCheckinTime && (
							<p className="notification-info">
								<span className="bold">{t('common.checkInTime')}</span>:{' '}
								{`${pineVisitorCheckinHour},  ${pineVisitorCheckinDay} ${pineVisitorCheckinDate}`}
							</p>
						)}

						<p className="notification-info">
							<span className="bold">{t('common.reasonOfVisit')}</span>: {pineInvitationData?.summary}
						</p>
					</div>
				)}
			</div>
			<div className="button-fixed-at-bottom">
				{item?.name === 'teak.booking.user.adminCancelledBooking' && selectedOffice.id === officeOfBooking?._id && (
					<p className="book-another-info">
						{t(`notifications.notificationDetails.bookAnotherInfo.${teakEntity?.__t ? teakEntity?.__t : 'noType'}`)}
					</p>
				)}
				{teakBooking && pineInvitationData && selectedOffice.id === officeOfBooking?._id && (
					<p className="book-another-info">
						{t('notifications.notificationDetails.cancelledPineInvitationWithRoomBooking.newMeetingInfo')}
					</p>
				)}
				<div className="buttons-wrapper">
					{error ? <p className="error-small-info"> {error} </p> : null}
					{pineVisitData && pineInvitationData ? (
						<Button
							variant={'primary'}
							height={'regular'}
							width={'variable'}
							translationKey={
								isPineVisitorCheckedOut
									? 'notifications.notificationDetails.pineVisitorForgotCheckOut.alreadyCheckedOut'
									: 'notifications.notificationDetails.pineVisitorForgotCheckOut.checkVisitorOut'
							}
							clickHandler={checkVisitorOut}
							disabled={isPineVisitorCheckedOut}
						/>
					) : (
						<Button
							variant={'secondary'}
							height={'regular'}
							width={'half'}
							translationKey="notifications.notificationDetails.deleteNotification"
							clickHandler={deleteNotificationHandler}
						/>
					)}
					{buttonState && (
						<Button
							variant={'primary'}
							height={'regular'}
							width={'half'}
							translationKey={buttonState.translationKey}
							clickHandler={buttonState?.clickHandler}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
export default NotificationDetails;
