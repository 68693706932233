/**
 * @param {number} minutes
 * @returns {string} 12:30h / 12h / 30min
 */
export default function minutesToHours(minutes, minsAbrv, hoursAbrv) {
	minutes = Number(minutes);
	let hours = Math.floor(minutes / 60);
	minutes = Math.floor(minutes % 60);
	const hourText = hoursAbrv ? hoursAbrv : ' h';
	const minText = minsAbrv ? minsAbrv : ' min';

	hours = hours ? (minutes ? hours + ':' : `${hours} ${hourText}`) : '';
	minutes = minutes ? (hours ? `${minutes} ${hourText}` : `${minutes} ${minText}`) : '';
	return hours + minutes;
}
