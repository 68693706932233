import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {NOTIFICATION_NAMES} from '../../../constants/notificationsNames';
import CustomCheckbox from '../../Common/CustomCheckbox';

function NotificationItem(props) {
	const {t} = useTranslation();
	const item = props.item;
	const userLocale = useSelector((state) => state.auth.data.locale);
	const [notificationType, setNotificationType] = useState(null);
	const dateFormat = useSelector((state) => state.auth.data.dateFormat);
	const [timestamp, setTimestamp] = useState(0);

	useEffect(() => {
		setNotificationType(NOTIFICATION_NAMES[item.name]);
		const createdAt = DateTime.fromISO(item.createdAt);
		const now = DateTime.now();
		if (createdAt.startOf('day').toISO() === now.startOf('day').toISO()) {
			setTimestamp(t('common.todayAt') + ' ' + createdAt.setLocale(userLocale).toLocaleString(DateTime.TIME_SIMPLE));
		} else if (createdAt.plus({days: 1}).startOf('day').toISO() === now.startOf('day').toISO()) {
			setTimestamp(t('common.yesterdayAt') + ' ' + createdAt.setLocale(userLocale).toLocaleString(DateTime.TIME_SIMPLE));
		} else {
			setTimestamp(createdAt.setLocale(userLocale).toFormat(dateFormat));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item]);

	if (!item) return null;

	const handleChange = (id) => {
		props.select(id);
	};

	return (
		<div className="notification-item-wrapper">
			{props.selectionViewEnabled ? <CustomCheckbox onChange={() => handleChange(item._id)} id={item._id} checked={props.checked} /> : null}
			<div className="notification-content" onClick={props.clickHandler}>
				<p className="bold">{t(`notifications.notificationItem.${notificationType}.header`)}</p>
				<p className="text-info">{t(`notifications.notificationItem.${notificationType}.info`)}</p>
				<p className="timestamp">{timestamp}</p>
			</div>
			<div className="notification-status">{!props.read ? <div className="unread-icon"></div> : null}</div>
		</div>
	);
}
export default NotificationItem;
