import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import Icon, {getTeakTypeIconName} from '../../Common/Icon';
import {
	changeArea,
	changeMeetingName,
	changeSelectedTeakType,
	updateBookOnBehalfColleagueSwitchOn,
} from '../../../store/bookingProcess/entitiesBooking';
import {getTeakTypeForOffice} from '../../../store/auth';
import {DEFAULT_TEAK_TYPES, TEAK_TYPE_NAMES, TEAK_TYPE_TO_ENTITY} from '../../../constants/teakTypeNames';
import {useEffect} from 'react';

export default function AssetTypes() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const teakTypeForSelectedOffice = useSelector(getTeakTypeForOffice);
	const selectedTeakType = useSelector((state) => state.entitiesBooking.selectedTeakType);

	useEffect(() => {
		if (!selectedTeakType && teakTypeForSelectedOffice?.teakTypes?.length > 0) {
			const workplaceType = teakTypeForSelectedOffice.teakTypes.find((teakType) => teakType.__t === TEAK_TYPE_NAMES.DESK);
			if (workplaceType) {
				dispatch(changeSelectedTeakType(workplaceType));
			} else {
				dispatch(changeSelectedTeakType(teakTypeForSelectedOffice.teakTypes[0]));
			}
		}
	}, [teakTypeForSelectedOffice]);

	return (
		<div className="asset-types">
			<div className="asset-types-header">{t('bookingPage.assetType')}</div>
			<div className="asset-types-buttons">
				{teakTypeForSelectedOffice.teakTypes?.map((teakType, index) => {
					return (
						<AssetTypesTab
							key={index}
							transLabelString={getTeakTypeName(teakType, t)}
							iconName={getTeakTypeIcon(teakType)}
							teakType={teakType}
						/>
					);
				})}
			</div>
			{selectedTeakType?.description && <div className="asset-types-description"> {selectedTeakType?.description}</div>}
		</div>
	);
}

function AssetTypesTab({transLabelString, iconName, teakType}) {
	const dispatch = useDispatch();
	const selectedTeakType = useSelector((state) => state.entitiesBooking.selectedTeakType);
	const cssClass = 'asset-types-button';
	const {t} = useTranslation();
	const changeSelectedTeakTypeHandler = () => {
		if (selectedTeakType?._id !== teakType?._id) {
			dispatch(changeSelectedTeakType(teakType));
			dispatch(updateBookOnBehalfColleagueSwitchOn(false));
			dispatch(changeMeetingName(null));
		}
	};
	return (
		<div
			className={selectedTeakType?._id === teakType?._id ? cssClass + ' asset-types-button-active' : cssClass}
			key={teakType?._id}
			onClick={changeSelectedTeakTypeHandler}>
			<Icon iconName={getTeakTypeIconName(iconName)} />
			<p className="asset-types-label">{t(transLabelString)}</p>
		</div>
	);
}

export function getTeakTypeName(teakType, t) {
	if (DEFAULT_TEAK_TYPES.includes(teakType?.__t)) {
		return t(`common.types.${TEAK_TYPE_TO_ENTITY[teakType?.__t]}.label`);
	}
	return teakType?.name;
}

export function getTeakTypeIcon(teakType) {
	if (DEFAULT_TEAK_TYPES.includes(teakType?.__t)) {
		return TEAK_TYPE_TO_ENTITY[teakType?.__t];
	}
	return teakType?.iconName;
}
