import {useDispatch, useSelector} from 'react-redux';
import {CARD} from '../../../constants/cards';
import {emergencyStates} from '../../../constants/emergencyStates';
import Icon from '../../Common/Icon';
import {enableCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import {updateEmergencyNotification} from '../../../api/offices';
import Button from '../../Common/Button';

function EmergencyCard() {
	const dispatch = useDispatch();
	const emergencyNotification = useSelector((state) => state.cards.emergencyNotification);
	const userId = useSelector((state) => state.auth.data.userData.id);

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	const updateEmergencyState = (message) => {
		updateEmergencyNotification(userId, emergencyNotification.id, message)
			.then((response) => {
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'emergencyState.successMessage'));
			})
			.catch((error) => {
				console.log(error);
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'emergencyState.errorMessage', error));
			});
	};

	return (
		<div className="card emergency-card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-wrapper">
				<Icon cssClass={'emergency-card-icon'} iconColor={'#D2062B'} iconName={'fireIcon'} />
				<p className="emergency-card-header">{emergencyNotification.cause}</p>
				<p
					className="emergency-card-message"
					dangerouslySetInnerHTML={{
						__html: emergencyNotification.message,
					}}></p>
				<div className="buttons-wrapper">
					<Button
						cssClass={'button green'}
						clickHandler={() => updateEmergencyState(emergencyStates.notInDanger)}
						translationKey={'common.buttons.buttonNotInDanger'}
					/>
					<Button
						cssClass={'button red'}
						clickHandler={() => updateEmergencyState(emergencyStates.inDanger)}
						translationKey={'common.buttons.buttonInDanger'}
					/>
				</div>
			</div>
		</div>
	);
}

export default EmergencyCard;
