import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import {styled} from '@mui/material/styles';
import {submitSessionFeedback} from '../../../api/additionals';
import {CARD} from '../../../constants/cards';
import {isPhoneMode} from '../../../functions/isPhoneMode';
import {enableCard, updateCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import Button from '../../Common/Button';
import Icon from '../../Common/Icon';
import {Textarea} from '../../Common/Textareas/Textarea';
import {loadEventSessionsOfUser} from '../../../store/events';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';

//same as user review, might be changed in the future
function EventFeedback() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const data = useSelector((state) => state.cards.data);
	const userData = useSelector((state) => state.auth.data.userData);
	const displayFrench = userData.language === 'fr';
	const [feedbackText, setFeedbackText] = useState('');
	const [cssForHiddenDiv, setCssForHiddenDiv] = useState('-hidden'); //the div is used to make the content scrollable when the virtual keyboard is on
	const [rating, setRating] = useState(0);

	const submitFeedback = async () => {
		dispatch(updateCard(CARD.PLACEHOLDER));

		try {
			await submitSessionFeedback(Math.abs(rating / 10), feedbackText, data?.wengeSession._id, data?.wengeEvent);
			dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'userReviewCard.successSubmission'));
			dispatch(loadEventSessionsOfUser(data?.wengeEvent));
		} catch (error) {
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'userReviewCard.failedSubmission', error));
		}
	};

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	document.addEventListener('focusout', () => {
		setCssForHiddenDiv('-hidden');
	});

	const handleFocus = () => {
		if (!isPhoneMode()) return;
		setCssForHiddenDiv('');
	};

	function getRatingQuestion() {
		if (displayFrench) {
			if (data?.wengeSession?.ratingQuestion?.fr && data?.wengeSession?.ratingQuestion?.fr !== '') {
				return data?.wengeSession?.ratingQuestion?.fr;
			}
		} else {
			if (data?.wengeSession?.ratingQuestion?.en && data?.wengeSession?.ratingQuestion?.en !== '') {
				return data?.wengeSession?.ratingQuestion?.en;
			}
		}
		return t('event.pleaseRate');
	}
	function getCommentRequestTranslationKey() {
		if (displayFrench) {
			if (data?.wengeSession?.commentRequest?.fr && data?.wengeSession?.commentRequest?.fr !== '') {
				return data?.wengeSession?.commentRequest?.fr;
			}
		} else {
			if (data?.wengeSession?.commentRequest?.en && data?.wengeSession?.commentRequest?.en !== '') {
				return data?.wengeSession?.commentRequest?.en;
			}
		}
		return t('event.feedbackInfo');
	}

	return (
		<div className="card event-feedback-card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="event-feedback-card-content">
				<div className="event-feedback-card-content-icon">
					<Icon iconName={'question'} size={'lg'} cssClass={'icon-active'} />
				</div>
				<p className="header">{getRatingQuestion()}</p>
				<p className="header-info">{getCommentRequestTranslationKey()}</p>
				<div className="stars-wrapper">
					<RatingSlider rating={rating} setRating={setRating} />
				</div>
				<div className="feedback-input">
					<label>{t('common.comments')}</label>
					<Textarea cols="20" rows="10" id="description" changeHandler={setFeedbackText} onFocus={handleFocus} />
				</div>
				<div className="buttons-wrapper">
					{rating && rating !== 0 ? (
						<Button
							variant={'primary'}
							height={'regular'}
							width={'full'}
							clickHandler={submitFeedback}
							translationKey={'userReviewCard.submitButton'}
						/>
					) : null}
				</div>
			</div>
			<div className={'placeholderForVirtualKeyboard' + cssForHiddenDiv}></div>
		</div>
	);
}

function RatingSlider({rating, setRating}) {
	const colors = colorBasedOnTheme();
	function handleSliderChange(e, newRating) {
		setRating(newRating);
	}
	const marks = [
		{
			value: 0,
			label: '0',
		},
		{
			value: 10,
			label: '1',
		},
		{
			value: 20,
			label: '2',
		},
		{
			value: 30,
			label: '3',
		},
		{
			value: 40,
			label: '4',
		},
		{
			value: 50,
			label: '5',
		},
		{
			value: 60,
			label: '6',
		},
		{
			value: 70,
			label: '7',
		},
		{
			value: 80,
			label: '8',
		},
		{
			value: 90,
			label: '9',
		},
		{
			value: 100,
			label: '10',
		},
	];
	const FeedbackSlider = styled(Slider)({
		color: colors.colorMainTheme,
	});

	return (
		<Box sx={{width: 300}}>
			<FeedbackSlider onChangeCommitted={handleSliderChange} defaultValue={rating} step={10} marks={marks} />
		</Box>
	);
}
export default EventFeedback;
