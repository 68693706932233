import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';
import {ROUTES} from '../../../constants/routes';
import {enableBottomMenu} from '../../../store/auth';
import {enableAspenSummaryPage} from '../../../store/bookingProcess/aspen';
import Button from '../../Common/Button';
import SecondaryHeader from '../../Common/Header/SecondaryHeader';
import AspenTypes from './AspenTypes';
import SingleDateTimePicker from '../../Common/DateTimePicker/SingleDateTimePicker';
import {changeAspenSelectedDate} from '../../../store/bookingProcess/aspen';

function AspenBookingPage(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const selectedAspenType = useSelector((state) => state.aspen.selectedAspenType);
	const selectedDate = useSelector((state) => state.aspen.selectedDate);
	const enable12HourFormat = useSelector((state) => state.auth.data.enable12HourFormat);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);

	let format12hrs = enable12HourFormat;
	let format = format12hrs ? 'hh:mm a' : 'HH:mm';

	useEffect(() => {
		dispatch(enableBottomMenu(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function closePage() {
		history.push(ROUTES.DEFAULT);
	}

	function searchResultsHandler() {
		dispatch(enableAspenSummaryPage(false));
		history.push(ROUTES.ASPEN_LIST);
		return;
	}

	function changeDate(newDate) {
		dispatch(changeAspenSelectedDate(newDate));
	}

	return (
		<div className="aspen-booking-page">
			<div className="aspen-booking-header">
				<SecondaryHeader headerTranslationKey={'serviceBooking.header'} backButtonClickHandler={closePage} />
			</div>
			<div className="aspen-booking-content">
				<AspenTypes />
				<hr className="dividing-hr"></hr>
				<h4 className="section-heading">{t('common.date')}*</h4>
				<SingleDateTimePicker
					dateTime={selectedDate}
					changeTime={changeDate}
					timeFormat={format}
					timeFormat12hrs={format12hrs}
					disablePast={true}
					timezone={selectedOffice?.timezone}
					onlyDate={true}
					minDate={selectedAspenType?.onlyBookableOn}
					maxDate={selectedAspenType?.onlyBookableOn}
				/>
			</div>
			<div className="aspen-booking-button button-fixed-at-bottom-static">
				<Button
					variant={'primary'}
					height={'regular'}
					width={'full'}
					clickHandler={searchResultsHandler}
					translationKey={'bookingPage.showResults'}
					disabled={!selectedAspenType}
				/>
			</div>
		</div>
	);
}

export default AspenBookingPage;
