// logging.js

// Originale console.log Methoden speichern
const originalLog = console.log;
const originalError = console.error;
const originalWarn = console.warn;

// Array, um alle Logs zu speichern
const allLogs = [];
const maxAllLogLength = 500;

function pushElement(element) {
    allLogs.push(element);

    // Prüfe, ob die maximale Länge überschritten wird
    if (allLogs.length > maxAllLogLength) {
        allLogs.shift(); // Entfernt das älteste Element
    }
}

// Funktion, um die Logs zu speichern
function saveLog(logType, message) {
    const logData = {
        type: logType,
        message: message,
        timestamp: new Date().toISOString(),
        userAgent: navigator.userAgent,
    };
    pushElement(logData); // Verwende pushElement hier
}

// Überschreibe console.log
console.log = function (...args) {
    saveLog('log', args);
    originalLog.apply(console, args);
};

// Überschreibe console.error
console.error = function (...args) {
    saveLog('error', args);
    originalError.apply(console, args);
};

// Überschreibe console.warn
console.warn = function (...args) {
    saveLog('warn', args);
    originalWarn.apply(console, args);
};

// Funktion zum Starten der Logging-Funktionalität
export function start() {
    console.log('Logging started'); // Beispiel-Logeintrag
    // Globalen Fehler-Handler einrichten
    window.onerror = function (message, source, lineno, colno, error) {
        saveLog('error', {
            message: message,
            source: source,
            lineno: lineno,
            colno: colno,
            stack: error ? error.stack : null,
        });
        return true; // Verhindert die Standardbehandlung
    };

    // Globalen Handler für unhandled promise rejections einrichten
    window.addEventListener('unhandledrejection', function (event) {
        saveLog('error', {
            message: 'Unhandled promise rejection: ' + event.reason,
            stack: event.reason.stack || null,
        });
    });
}

// Exportiere das allLogs Array und eine Funktion zum Abrufen der Logs
export const getLogs = () => allLogs.map(log => JSON.stringify(log)).join(', '); // Logs als lesbares JSON zurückgeben
export const resetLogs = () => { allLogs.length = 0; }; // Funktion zum Zurücksetzen der Logs
