import axios from 'axios';
import apiUrl from './index';

const source = axios.CancelToken.source();
let config = {cancelToken: source.token};

const pathUrls = {
	issueReport: '/biro/issueReport',
	issueReportArea: '/area',
	issueReportGeneral: '/general',
	issueReportEntity: '/teakEntity',
	issueReportUploadPhoto: '/uploadPhoto',
	issueReportCategoriesTranslations: '/translations/issueReportCategories.json',
	issueReportList: '/issueReportsList',
	issueReportUpdate: '/update',
	issueReportRead: '/read',
	issueBugReport: '/biro/bugReport',
};

export async function reportProblemForEntity(officeId, teakEntity, description, category) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.issueReport + pathUrls.issueReportEntity;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				office: officeId,
				teakEntity: teakEntity,
				description: description,
				category: category,
			},
		},
		config,
	);
}

export async function reportBugProblem(data, isEventBug) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.issueBugReport + `?isLCOffice=${isEventBug}`;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: data,
		},
		config,
	);
}

export async function reportOtherProblem(officeId, areaId, location, description, category, wengeEvent) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.issueReport + pathUrls.issueReportGeneral;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				area: areaId,
				office: officeId,
				location,
				description,
				category,
				wengeEvent,
			},
		},
		config,
	);
}

export async function reportProblemUploadPhoto(issueReportId, base64Image) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.issueReport + '/' + issueReportId + pathUrls.issueReportUploadPhoto;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				id: issueReportId,
				base64Image: base64Image,
			},
		},
		config,
	);
}

export async function getReportProblemCategories(officeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.issueReport + apiUrl.path.category;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				office: officeId,
			},
		},
		config,
	);
}

export async function getReportProblemTranslations() {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = apiUrl.staticAssetsURL + pathUrls.issueReportCategoriesTranslations;

	return axios({
		method: 'get',
		url: requestUrl,
		withCredentials: false,
		responseType: 'json',
	});
}

export function getReportsList(office) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.issueReport + pathUrls.issueReportList;
	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			office,
		},
	});
}

export function addCommentToReport(issueReportId, comment) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.issueReport + `/${issueReportId}` + pathUrls.issueReportUpdate;

	return axios({
		method: 'put',
		url: requestUrl,
		data: {
			comment,
		},
	});
}

export function updateReadOfIssueReport(issueReportId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.issueReport + `/${issueReportId}` + pathUrls.issueReportRead;

	return axios({
		method: 'put',
		url: requestUrl,
	});
}
