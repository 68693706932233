import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Icon from '../../Common/Icon';
import Button from '../../Common/Button';
import {enableCard, updateCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import {CARD} from '../../../constants/cards';
import {useHistory} from 'react-router-dom';
import {reportOtherProblem, reportProblemUploadPhoto} from '../../../api';
import {emptyReportProblemStore, loadIssueReports} from '../../../store/reportProblem';
import {enableBottomMenu} from '../../../store/auth';

function SummaryReportFacilityProblem(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice.id);
	const selectedArea = useSelector((state) => state.reportProblem.selectedArea);
	const selectedAreaId = selectedArea?.areaId;
	const selectedAreaName = useSelector((state) => state.reportProblem.selectedArea.areaName);
	const category = useSelector((state) => state.reportProblem.selectedProblemCategory.category);
	const categoryName = useSelector((state) => state.reportProblem.selectedProblemCategory.name);
	const enteredComment = useSelector((state) => state.reportProblem.enteredComment);
	const imageArray = useSelector((state) => state.reportProblem.imageArray);
	const selectedLocation = useSelector((state) => state.reportProblem.selectedLocation);
	const selectedEvent = useSelector((state) => state.events.selectedEvent);

	let imagesList = [];

	if (imageArray.length > 0) {
		imageArray.forEach((image, index) => {
			imagesList.push(
				<p key={index} className="summary-confirmation-picturename">
					{imageArray[index].name}
				</p>,
			);
		});
	} else {
		imagesList = null;
	}

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	async function reportProblem() {
		dispatch(updateCard(CARD.PLACEHOLDER));
		try {
			const wengeEvent = selectedEvent?._id ?? undefined;
			const otherProblemResponse = await reportOtherProblem(
				selectedOffice,
				selectedAreaId,
				selectedLocation,
				enteredComment,
				categoryName,
				wengeEvent,
			);

			if (imageArray.length > 0) {
				imageArray.forEach(async (image, index) => {
					try {
						await reportProblemUploadPhoto(otherProblemResponse.data._id, imageArray[index].image);
						dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'reportProblem.successText'));
						dispatch(loadIssueReports(true));
						dispatch(emptyReportProblemStore());
					} catch (error) {
						console.log(error);
						dispatch(updateErrorCard(CARD.MAIN_ERROR, 'reportProblem.errorText', error));
					}
				});
			} else {
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'reportProblem.successText'));
				dispatch(loadIssueReports(true));
				dispatch(emptyReportProblemStore());
			}
		} catch (error) {
			console.log(error);
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'reportProblem.errorText', error));
		}
		dispatch(enableBottomMenu(true));
		history.goBack();
	}

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="summary-confirmation-wrapper">
				<div className="summary-confirmation-header">{t('summaryReportProblemCard.summary')}</div>

				<ul className="summary-confirmation-list">
					{selectedAreaId && (
						<>
							<li className="summary-confirmation-item">
								<Icon cssClass={'icon-purple'} iconName={'check-circle'} />
								<span>{t('summaryReportProblemCard.problemLocation')}</span>
							</li>
							<li className="summary-confirmation-details">
								<span>
									{t('summaryReportProblemCard.area')} / {selectedAreaName}
								</span>
							</li>
						</>
					)}
					<li className="summary-confirmation-item">
						<Icon cssClass={'icon-purple'} iconName={'check-circle'} />
						<span>{t('summaryReportProblemCard.typeOfProblem')}</span>
					</li>
					<li className="summary-confirmation-details">
						<span>{category}</span>
					</li>

					{imagesList && (
						<li className="summary-confirmation-pictures">
							<Icon cssClass={'icon-purple'} iconName={'check-circle'} />
							<span>{t('summaryReportProblemCard.pictures')}</span>
							{imagesList}
						</li>
					)}
				</ul>

				<div className="buttons-wrapper">
					<Button variant={'primary'} height={'regular'} width={'full'} clickHandler={reportProblem} translationKey={'common.okButton'} />
					<Button variant={'secondary'} height={'regular'} width={'full'} clickHandler={closeCard} translationKey={'common.backButton'} />
				</div>
			</div>
		</div>
	);
}

export default SummaryReportFacilityProblem;
