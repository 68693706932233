import {getMemberPhoto} from '../api';
import {addMemberPhoto} from '../store/auth';
import {colleaguePlaceholderPhoto} from '../constants/images';

export async function retrieveMemberPhoto(memberId, memberPhotos, dispatch) {
	if (memberPhotos) {
		const photoFromStore = memberPhotos.find((item) => item.id === memberId);
		if (photoFromStore) return photoFromStore.photo;
	}
	try {
		const response = await getMemberPhoto(memberId, false);
		if (response.status === 204) throw new Error('no member photo'); // More informative error

		const photoURL = window.URL.createObjectURL(new Blob([response.data]));
		if (dispatch) dispatch(addMemberPhoto(memberId, photoURL));
		return photoURL;
	} catch (error) {
		if (error.message === 'no member photo') {
			if (dispatch) dispatch(addMemberPhoto(memberId, colleaguePlaceholderPhoto));
			return colleaguePlaceholderPhoto;
		} else {
			throw error;
		}
	}
}
