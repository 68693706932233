import axios from 'axios';

import apiUrl from './index';

const source = axios.CancelToken.source();
let config = {cancelToken: source.token};

export async function deletePineInvitation(pineInvitationId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = hostUrl + apiUrl.path.pine + apiUrl.path.invitation + '/' + pineInvitationId;

	return axios(
		{
			method: 'delete',
			url: requestUrl,
		},
		config,
	);
}

export function postPineInvitation(summary, start, end, office, pineVisitors, description, teakEntity) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = hostUrl + apiUrl.path.pine + apiUrl.path.invitation;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			summary,
			start,
			end,
			office,
			pineVisitors,
			description,
			teakEntity,
		},
	});
}

export async function getPineInvitationsInRange(selectedOfficeId, startTime, endTime) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = hostUrl + apiUrl.path.pine + apiUrl.path.invitation + apiUrl.path.findInRange;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				filters: {
					office: selectedOfficeId,
				},
				start: startTime,
				end: endTime,
			},
		},
		config,
	);
}

export function pineVisitorCheckout(pineVisitor) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = hostUrl + apiUrl.path.pine + apiUrl.path.visitor + `/${pineVisitor}/checkOut`;

	return axios({
		method: 'post',
		url: requestUrl,
	});
}

export function editPineInvitation(pineInvitationId, start, end, teakEntity, pineVisitors) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const requestUrl = hostUrl + apiUrl.path.pine + apiUrl.path.invitation + `/${pineInvitationId}/edit`;

	return axios({
		method: 'post',
		url: requestUrl,
		data: {
			start,
			end,
			teakEntity,
			pineVisitors
		},
	});
}