import getSymbolFromCurrency from 'currency-symbol-map';

/**
 *
 * @param {Object} item = item object that holds the price amount and currency (eg. {price.amount, price.currency}).
 * @param {boolean} negative = if should - in front of price or not
 * @returns Simple React component that holds the common price tag comoponent. eg: 15.99 e
 */
function PriceTag({item, negative = false}) {
	return `${negative ? '-' : ''}${Math.abs(item?.price?.amount / 100).toFixed(2)} ${getSymbolFromCurrency(item?.price?.currency)}`;
}

export default PriceTag;
