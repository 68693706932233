/**
 * This file contains api calls related to Services.
 * Aspen = Service
 *
 */

import axios from 'axios';
import apiUrl from './index';

const source = axios.CancelToken.source();
let config = {cancelToken: source.token};

const pathUrls = {
	aspen: '/biro/aspen',
	type: '/type',
	item: '/item',
	request: '/request',
	deliveryLocation: '/deliveryLocation',
	update: '/update',
	read: '/read',
};
/**
 *
 * @param {id} office = id of office to get aspen types from
 * @returns promise with response = array of aspen types for passed office id
 */
export async function getAspenTypes(office) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.aspen + pathUrls.type + apiUrl.path.findMany;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				filters: {
					office,
				},
			},
		},
		config,
	);
}

/**
 *
 * @param {id} office = id of office to get aspen items from
 * @param {id} aspenType = id of aspen type in order to get its items
 * @param {{start, end}} range = object that has start and end times in iso format
 * @param {boolean} getFavorites = if request should get only favorite aspen items
 * @returns promise with response = array of aspen items assigned to the aspen type
 */
export async function getAspenItems(office, aspenType, range, includeFavorites) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.aspen + pathUrls.item + apiUrl.path.findMany;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				filters: {
					office,
					aspenType,
				},
				range,
				includeFavorites,
			},
		},
		config,
	);
}

/**
 *
 * @param {id} aspenItemId = id of aspen item to create request for
 * @param {ISO} start = iso format of date when to create request
 * @param {id} teakEntity = id of teak entity assigned as delivery location for aspen request
 * @param {string} projectNumber = project number assigned to aspen request
 * @param {string} comment = comment that is assigned to aspen request
 * @returns promise with response = if request was added or not
 */
export async function confirmRequestForAspenItem(aspenItemId, start, teakEntity, projectNumber, comment) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.aspen + pathUrls.item + `/${aspenItemId}` + pathUrls.request;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				start,
				teakEntity,
				projectNumber,
				comment,
			},
		},
		config,
	);
}

/**
 *
 * @param {id} office = id of office to get aspen items from
 * @param {ISO} start = iso format of datetime when the request range starts
 * @param {ISO} end = iso format of datetime when the request range ends
 * @returns promise with response = list of aspen requests inside requested range and assign to certain office
 */
export async function getAspenRequests(office, start, end) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.aspen + pathUrls.request + apiUrl.path.findInRange;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				filters: {
					office,
				},
				range: {
					start,
					end,
				},
			},
		},
		config,
	);
}

/**
 *
 * @param {id} aspenItemId = id of aspen item to create request for
 * @param {ISO} startTime = iso format of date when to get the delivery location
 * @returns promise with response = retrieve the delivery locations for this aspen item for this startTime => delivery location means booked teak Entities
 */
export async function getDeliveryLocation(aspenItemId, startTime) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.aspen + pathUrls.item + `/${aspenItemId}` + pathUrls.deliveryLocation;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				start: startTime,
			},
		},
		config,
	);
}

/**
 *
 * @param {id} aspenRequestId = id of aspen request to add comment to
 * @param {string} comment = comment to be added
 * @returns promise with response = add new comment to aspen request
 */
export async function postCommentToAspenRequest(aspenRequestId, comment) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.aspen + pathUrls.request + `/${aspenRequestId}` + pathUrls.update;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				comment,
			},
		},
		config,
	);
}

/**
 *
 * @param {id} aspenRequestId = id of aspen request to read
 * @returns promise with response = read the comments of the aspen request
 */
export function updateReadOfAspenRequest(aspenRequestId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + pathUrls.aspen + pathUrls.request + `/${aspenRequestId}` + pathUrls.read;

	return axios({
		method: 'put',
		url: requestUrl,
	});
}

/**
 *
 * @param {id} aspenItemId = id of aspen item to be edited in favorites -> either added or removed
 * @returns promise with response = add or remove a favorite aspen item for this member
 */
export async function editFavoriteAspenItem(aspenItemId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	const memberId = window.localStorage.getItem('memberID');

	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + apiUrl.path.favoriteAspenItem;

	return axios(
		{
			method: 'put',
			url: requestUrl,
			data: {
				favoriteAspenItem: aspenItemId,
			},
		},
		config,
	);
}
