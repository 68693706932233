import {t} from 'i18next';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {Duration} from 'luxon';
import {useHistory} from 'react-router';
import {bookingStatus} from '../../constants/bookingStatus';
import {changeOffice, getOfficeBasedOnId, getTeakTypeObj, updateActionSheet} from '../../store/auth';
import {limit} from '../../constants/limit';
import {changeArea, changeDesk, changeRoom} from '../../store/reportProblem';
import {
	addDataToCard,
	enableCard,
	getAreaNameBasedOnId,
	loadBooking,
	updateCard,
	updateCardAndData,
	updateConfirmationCard,
	updateErrorCard,
} from '../../store/cards';
import {ROUTES} from '../../constants/routes';
import {updateDetailsBookingData} from '../../store/bookingsOverview/bookings';
import {getTeakEntitiesFull} from '../../functions/getTeakEntitiesFull';
import {changeEntityBookingTimes, changeSelectedTeakType, showBookingOnMap, updateSelectedEntity} from '../../store/bookingProcess/entitiesBooking';
import {CARD} from '../../constants/cards';
import {enableQuestionnairePage} from '../../store/dashboard';
import {BOTTOM_MAP_CARDS} from '../../constants/bottomCardTypes';
import {getTeakTypeName} from '../Booking/BookingPage/AssetTypes';
import {Trans} from 'react-i18next';
import {roundTimeQuarterHour} from '../../functions/roundTimeToNextQuarter';
import {TEAK_TYPE_NAMES} from '../../constants/teakTypeNames';
import {confirmActionsMap} from '../Cards/Common/ConfirmAction/confirmActions';

/**
 * * Component holding the action menu shown when users scan the QR code of an entity.
 * * data is the object that contains information about the scanned entity
 * * data = {
 * *    entity: entity data that was scanned
 * *    booking: booking data of the next booking assigned to this entity - eg. ( entity has bookings from 5:00 - 6:00 and 7:00 - 8:00 => booking will hold data for booking starting at 5). If there are no bookings for this entity, booking is null
 * * }
 * *
 */
const QRCodeScannerMenu = (props) => {
	const currentTime = DateTime.now().toUTC();
	const dispatch = useDispatch();

	const authData = useSelector((state) => state.auth.data);
	const {actionSheetData, userData, areas, featureTranslations, teakFeatures, enable12HourFormat, selectedOffice} = authData;
	const {entity, teakBookingsForEntity, nextBookingOfMember} = actionSheetData;
	const lastCompleted = useSelector((state) => state.dashboard.data.plusStatusLastCompleted);
	const validCeylon = useSelector((state) => state.dashboard.data.validCeylon);
	const teakTypeObj = useSelector(getTeakTypeObj(entity?.teakType));
	const nextBooking = teakBookingsForEntity?.length > 0 ? teakBookingsForEntity[0] : null;
	const nextBookingIsOfMember = nextBooking ? nextBookingOfMember?._id === nextBooking?._id : null;
	const isEntityAvailable = checkIfEntityIsAvailable();
	const timeFormat = enable12HourFormat ? 'hh:mm a' : 'HH:mm';
	const history = useHistory();
	const earlyCheckInTimeBeforeStart = teakTypeObj?.options?.bookings?.earlyCheckIn?.timeBeforeStart ?? 0;
	const officeId = entity?.office._id ? entity?.office?._id : entity?.office;
	const officeObject = useSelector(getOfficeBasedOnId(officeId));
	const teakTypeForOffice = authData?.teakTypeArray?.find((teakType) => teakType.office === officeId);
	const teakEntityName = entity?.name;
	const areaId = entity?.area._id ? entity?.area._id : entity?.area;
	const isMemberHost = nextBooking?.member === userData?._id;
	const onlyCheckIns = entity?.teakSharedOptions?.onlyCheckIns;
	const approvalRequired = entity?.approvalRequired?.enabled;
	const areaName = useSelector(getAreaNameBasedOnId(areaId)) || entity?.area?.name;
	const bookingDataStatus = nextBooking?.status;
	const userIsCheckedIn = isUserCheckedIn();
	function isUserCheckedIn() {
		if (!nextBookingOfMember) return false;
		if (nextBookingOfMember.status !== bookingStatus.checkedIn && nextBookingOfMember.status !== bookingStatus.autoCheckedIn) {
			return false;
		}
		if (nextBookingOfMember?.attendees?.length === 0) {
			return true;
		}
		// if booking status is checkedIn and the booking has attendees, it means that at least one attendee already checked in.
		// We need to check if this user already checked in.
		return nextBookingOfMember?.attendees?.some((attendee) => attendee.member === userData._id && attendee?.checkIn);
	}

	function checkIfEntityIsAvailable() {
		if (!nextBooking) return true;

		if (nextBooking.status === bookingStatus.checkedIn) return false;

		return currentTime < DateTime.fromISO(nextBooking?.start?.time);
	}
	/**
	 *
	 * check what happens after users clicks on checkin.
	 * There are multiple cases:
	 * - questionanire has to be valid if it is enabled
	 * - covid test has to be valid if it is enabled
	 * - if questionnaire was already completed in the last 24 hours we dont need questionnaire completion again.
	 */
	const handleCheckInClick = (e) => {
		// if booking is not for logged in user, we can still let them checkin until the start of the next booking, they will need to select an end time
		if (!nextBookingIsOfMember) {
			dispatch(enableCard(true));
			dispatch(updateCardAndData(CARD.CONFIRM_CHECKIN, actionSheetData));
			return;
		}

		if (officeObject.ceylon?.rule === '2G+') {
			if (!lastCompleted || DateTime.fromISO(lastCompleted).plus({hours: 24}).toUTC() < DateTime.now().toUTC()) {
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'errorMessages.invalidPlusTest'));
				return;
			}
		}

		if (!officeObject?.biro?.healthQuestionnaire?.enabled) {
			if (officeObject?.ceylon?.enabled && !validCeylon && officeObject?.ceylon?.rule !== 'none') {
				history.push(ROUTES.NO_CERTIFICATE_FOR_CHECKIN);
			} else {
				dispatch(loadBooking({...nextBooking, teakEntity: entity}, CARD.CHECKIN));
			}
			return;
		}

		if (shouldOpenQuestionnairePage(officeObject, userData)) {
			dispatch(enableCard(false));
			dispatch(updateActionSheet(false));
			dispatch(enableQuestionnairePage(true, nextBooking, 'qrScanner'));
			history.push(ROUTES.QUESTIONNER_PAGE);
			return;
		}

		dispatch(
			loadBooking(
				{
					...nextBooking,
					teakEntity: entity,
				},
				CARD.CHECKIN,
			),
		);
	};

	const handleShowOnMap = () => {
		props.closeActionSheet();
		history.push(ROUTES.BOOKING_PAGE);
		dispatch(showBookingOnMap(nextBooking, entity, BOTTOM_MAP_CARDS.ownBooking));
	};
	const handleCheckOutClick = () => {
		const data = {...actionSheetData, booking: nextBookingOfMember};
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.CHECKOUT,
				{
					headerKey: 'common.checkout',
					infoKey: `${t('checkOutCard.confirmCheckout', {
						entityName: `<span class="bold">${entity?.name}</span>`,
					})}`,
					confirmButtonKey: 'checkOutCard.confirmCheckoutButton',
				},
				data,
			),
		);
	};
	/**
	 * redirect to report problem page based on the teak type of the entity
	 */
	const redirectToReportProblem = () => {
		dispatch(enableCard(false));

		if (teakTypeObj.__t === TEAK_TYPE_NAMES.DESK) {
			dispatch(changeDesk(entity._id, teakEntityName));
			dispatch(changeArea(areaId, areaName));
			history.push(ROUTES.REPORT_PROBLEM + ROUTES.DESK_PROBLEM_DETAILS);
		} else if (teakTypeObj.__t === TEAK_TYPE_NAMES.ROOM) {
			dispatch(changeRoom(entity._id, teakEntityName));
			dispatch(changeArea(areaId, areaName));
			history.push(ROUTES.REPORT_PROBLEM + ROUTES.MEETING_ROOM_PROBLEM_DETAILS);
		} else {
			dispatch(changeOffice(officeObject._id, officeObject.name));
			dispatch(changeArea(areaId, areaName));
			history.push(ROUTES.REPORT_PROBLEM + ROUTES.OTHER_PROBLEM);
		}
	};

	const redirectToBookingDetails = (e) => {
		e.stopPropagation();
		props.closeActionSheet();
		dispatch(
			updateDetailsBookingData({
				booking: nextBookingOfMember,
				entity: getTeakEntitiesFull(areas, featureTranslations, userData, teakFeatures, [entity])[0],
			}),
		);
		history.push(ROUTES.SEE_DETAILS_OF_BOOKING);
	};

	const redirectToRepeatBooking = (e) => {
		e.stopPropagation();
		props.closeActionSheet();
		const entityWithData = getTeakEntitiesFull(areas, featureTranslations, userData, teakFeatures, [entity])[0];
		const selectedEndTime = nextBooking
			? DateTime.fromISO(nextBooking.start.time).setZone(officeObject?.timezone).toISO()
			: roundTimeQuarterHour(DateTime.now().plus({hours: 1}).setZone(officeObject?.timezone));
		dispatch(updateSelectedEntity(entityWithData));
		dispatch(changeSelectedTeakType(undefined, entity.teakType, undefined, true));
		dispatch(changeEntityBookingTimes(roundTimeQuarterHour(DateTime.now().setZone(officeObject?.timezone)), selectedEndTime));
		history.push(ROUTES.BOOKING_CONFIRM, {showCalendarFirst: true});
	};

	const displayCancelBookingCard = () => {
		const data = {...actionSheetData, booking: nextBookingOfMember};
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.CANCEL_BOOKING,
				{
					headerKey: 'confirmDelete.confirmMessage',
					confirmButtonKey: 'common.cancelBooking',
					infoKey: data?.booking?.pineInvitation ?? 'confirmDelete.confirmDeleteBookingWithPineInvitation',
				},
				{...data, organizationData: authData?.organizationData},
			),
		);
	};

	const displayExtendBookingCard = () => {
		const data = {...actionSheetData, booking: nextBookingOfMember};
		dispatch(addDataToCard(data));
		dispatch(enableCard(true));
		dispatch(updateCard(CARD.EXTEND_TIME));
	};

	function millisecondsToHoursAndMinutes(milliseconds) {
		const diffObj = Duration.fromMillis(milliseconds).shiftTo('hours', 'minutes', 'seconds').toObject();
		return {hours: Math.abs(diffObj.hours), minutes: Math.abs(diffObj.minutes)};
	}

	function renderReportProblemButton() {
		if (officeObject?.issueReporting?.enabled && teakTypeForOffice?.office === officeObject?._id) {
			return (
				<div className="action-sheet-option" onClick={redirectToReportProblem}>
					{t('common.reportProblem')}
				</div>
			);
		}
	}

	const showShowOnMap = () => {
		return (
			isMemberHost &&
			officeId === selectedOffice.id &&
			(bookingDataStatus === bookingStatus.confirmed ||
				bookingDataStatus === bookingStatus.checkInOpen ||
				bookingDataStatus === bookingStatus.checkedIn ||
				bookingDataStatus === bookingStatus.autoCheckedIn)
		);
	};

	const showBook = () => {
		return !onlyCheckIns;
	};
	const showCancelBooking = () => {
		return (bookingDataStatus === bookingStatus.confirmed || bookingDataStatus === bookingStatus.checkInOpen) && isMemberHost;
	};
	const showCheckout = () => {
		return bookingDataStatus === bookingStatus.checkedIn && userIsCheckedIn;
	};

	const showExtendBooking = () => {
		return bookingDataStatus === bookingStatus.checkedIn && userIsCheckedIn && isMemberHost;
	};

	function getAvailableInfo() {
		const midnightTime = DateTime.now().setZone(officeObject?.timezone).endOf('day').plus(1).toFormat(timeFormat);
		if (!nextBooking) {
			return (
				<Trans
					i18nKey="entityQRScanned.availableUntil"
					values={{
						time: midnightTime,
					}}
				/>
			);
		}
		const nextBookingStartTime = DateTime.fromISO(nextBooking?.start?.time);
		const nextBookingEndTime = DateTime.fromISO(nextBooking?.end?.time);
		return (
			<Trans
				i18nKey={isEntityAvailable && !nextBookingIsOfMember ? 'entityQRScanned.availableUntil' : 'entityQRScanned.availableAfter'}
				values={{
					time:
						isEntityAvailable && !nextBookingIsOfMember
							? nextBookingStartTime.setZone(officeObject?.timezone).toFormat(timeFormat)
							: nextBookingEndTime.setZone(officeObject?.timezone).toFormat(timeFormat),
				}}
			/>
		);
	}

	function getCheckinButton() {
		if (nextBookingOfMember) {
			if (approvalRequired && nextBookingOfMember?.request?.status !== 'accepted') {
				return;
			}

			if (isUserCheckedIn()) return;
			const checkinStart = DateTime.fromISO(nextBookingOfMember?.start?.time)
				.toUTC()
				.minus(earlyCheckInTimeBeforeStart ? earlyCheckInTimeBeforeStart : Math.abs(limit.checkinInAdvance * 60000));

			const timeLeftUntilCheckin = Math.abs(currentTime.diff(checkinStart).toObject().milliseconds);
			const shouldWaitForCheckin = currentTime < checkinStart;
			const hoursAndMinutes = millisecondsToHoursAndMinutes(timeLeftUntilCheckin);

			// if the member has a booking and they need to wait for the checkin to start,
			if (!shouldWaitForCheckin) {
				if (isEntityAvailable || nextBookingIsOfMember) {
					return (
						<div className="action-sheet-option" onClick={handleCheckInClick}>
							{t('common.checkin')}
						</div>
					);
				} else {
					return (
						<div className="action-sheet-option-disabled" onClick={(e) => e.stopPropagation()}>
							{t('common.waitForCheckin')}
						</div>
					);
				}
			} else {
				return (
					<div className="action-sheet-option-disabled" onClick={(e) => e.stopPropagation()}>
						{t('common.checkin') +
							' ' +
							t('common.in') +
							' ' +
							(hoursAndMinutes.hours > 9 ? hoursAndMinutes.hours : '0' + hoursAndMinutes.hours) +
							t('common.hoursLetter') +
							':' +
							(hoursAndMinutes.minutes > 9 ? hoursAndMinutes.minutes : '0' + hoursAndMinutes.minutes) +
							t('common.minutesLetter')}
					</div>
				);
			}
		} else {
			// if the member doesnt have a booking, the entity is available and approval is not required, the user can immediately checkin
			if (isEntityAvailable && !approvalRequired) {
				return (
					<div className="action-sheet-option" onClick={handleCheckInClick}>
						{t('entityQRScanned.header')}
					</div>
				);
			} else return;
		}
	}

	return (
		<>
			<div className="action-sheet-option action-sheet-status-of-entity">
				<div className="action-sheet-status-of-entity-status">
					<div className="action-sheet-status-of-entity-status-circle">
						<div
							className={`action-sheet-status-of-entity-status-circle-inner ${
								isEntityAvailable && !nextBookingIsOfMember ? 'status-available' : 'status-unavailable'
							}`}
						/>
					</div>
				</div>
				<div className="action-sheet-status-of-entity-text">
					<p className="name">
						{getTeakTypeName(teakTypeObj, t)} {entity.name}
					</p>
					<p className="available-info">{getAvailableInfo()}</p>
				</div>
			</div>
			{getCheckinButton()}
			{showShowOnMap() && (
				<div className="action-sheet-option" onClick={handleShowOnMap}>
					{t('common.showOnMap')}
				</div>
			)}
			{showCheckout() && (
				<div className="action-sheet-option" onClick={handleCheckOutClick}>
					{t('common.checkout')}
				</div>
			)}

			{nextBookingIsOfMember && (
				<div className="action-sheet-option" onClick={redirectToBookingDetails}>
					{t('common.bookingDetails')}
				</div>
			)}

			{showBook() && (
				<div className="action-sheet-option" onClick={redirectToRepeatBooking}>
					{approvalRequired ? t('common.requestBooking') : nextBookingOfMember ? t('common.bookAgain') : t('common.book')}
				</div>
			)}

			{showExtendBooking() && (
				<div className="action-sheet-option" onClick={displayExtendBookingCard}>
					{t('common.extendBooking')}
				</div>
			)}

			{showCancelBooking() && (
				<div className="action-sheet-option" onClick={displayCancelBookingCard}>
					{t('common.cancelBooking')}
				</div>
			)}
			{renderReportProblemButton()}
		</>
	);
};

export default QRCodeScannerMenu;

/**
 * check the office object and userData object in order to find if user needs to complete the questionnaire or not.
 */
function shouldOpenQuestionnairePage(office, userData) {
	if (!office?.biro?.healthQuestionnaire?.enabled) return false;

	if (office?.biro?.healthQuestionnaire?.promptCompletion === 'prior' || office?.biro?.healthQuestionnaire?.promptCompletion === undefined) {
		const healthQuestionnaireCompletions = userData?.biro?.healthQuestionnaireCompletions;

		if (!healthQuestionnaireCompletions || healthQuestionnaireCompletions?.length === 0) return true;

		let validityOfHQforSelectedOffice = office?.biro?.healthQuestionnaire?.validity;
		if (validityOfHQforSelectedOffice === undefined) validityOfHQforSelectedOffice = 172800000; //if undefined the default is 48 hrs in ms

		const lastHQCompletion = healthQuestionnaireCompletions[healthQuestionnaireCompletions.length - 1];
		if (DateTime.now().toUTC() - DateTime.fromISO(lastHQCompletion).toUTC() >= validityOfHQforSelectedOffice) return true;

		return false;
	} else if (office?.biro?.healthQuestionnaire?.promptCompletion === 'onCheckIn') {
		return true;
	}
}
