import Sheet from 'react-modal-sheet';
import {useTranslation} from 'react-i18next';
import {useSelector, useDispatch} from 'react-redux';
import {getAspenTypesForOffice, showToastBooking} from '../../../store/auth';
import {useHistory} from 'react-router-dom';
import Icon from '../../Common/Icon';
import {ROUTES} from '../../../constants/routes';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';

//bottom card that slides up when starting a booking.
function ToastBooking() {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const open = useSelector((state) => state.auth.ui.toastOpen);
	const favoriteTeakEntities = useSelector((state) => state.auth.data.userData?.biro?.favoriteTeakEntities);
	const favoriteAspenItems = useSelector((state) => state.auth.data.userData?.biro?.favoriteAspenItems);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const aspenTypes = useSelector(getAspenTypesForOffice);
	const visitorManagementEnabled = selectedOffice.pine?.enabled;
	const colors = colorBasedOnTheme();

	function isOfficeHasFavorites() {
		return (
			favoriteTeakEntities?.some((e) => e.office._id === selectedOffice._id) ||
			favoriteTeakEntities?.some((e) => e.office === selectedOffice._id) ||
			favoriteAspenItems?.some((e) => e.office === selectedOffice._id)
		);
	}

	const closeToast = () => {
		dispatch(showToastBooking(false));
	};

	const redirectToFavorites = () => {
		closeToast();
		history.push(ROUTES.FAVORITES_BOOKING_PAGE);
	};

	const redirectToAssets = () => {
		closeToast();
		history.push(ROUTES.BOOKING_PAGE);
	};

	const redirectToVisitorMeeting = () => {
		closeToast();
		history.push(ROUTES.VISITOR.NEW_MEETING);
	};
	const redirectToAspenBooking = () => {
		closeToast();
		history.push(ROUTES.ASPEN_BOOKING);
	};

	return (
		<Sheet isOpen={open} onClose={closeToast} className={`toast-booking-wrapper ${open ? 'toast-booking-wrapper-opened' : ''}`}>
			<Sheet.Container className="toast-booking-container">
				<Sheet.Header>
					<div className="swipe-down-handler">
						<div className="grey-bar"></div>
					</div>
					<div className="close-button">
						<p onClick={closeToast}>{t('common.closeButton')}</p>
					</div>
				</Sheet.Header>
				<Sheet.Content>
					<div className="toast-booking-content" onClick={(e) => e.stopPropagation()}>
						<div className="toast-options">
							<div className="toast-option" onClick={redirectToAssets}>
								<Icon iconName={'calendarToday'} iconColor={colors.colorNeutral80} />
								<p className="bold">{t('bookingPage.assetBooking')}</p>
							</div>
							{selectedOffice?.aspen?.enabled && aspenTypes?.types?.length > 0 && (
								<div className="toast-option" onClick={redirectToAspenBooking}>
									<Icon iconName={'roomService'} iconColor={colors.colorNeutral80} />
									<p className="bold">{t('serviceBooking.header')}</p>
								</div>
							)}
							{visitorManagementEnabled && (
								<div className="toast-option" onClick={redirectToVisitorMeeting}>
									<Icon iconName={'assets'} iconColor={colors.colorNeutral80} />
									<p className="bold">{t('dashboard.visitorMeeting')}</p>
								</div>
							)}
							{isOfficeHasFavorites() && (
								<div className="toast-option" onClick={redirectToFavorites}>
									<Icon iconName={'heart'} cssClass={'icon-disabled'} />
									<p className="bold">{t('favorites.pageHeading')}</p>
								</div>
							)}
						</div>
					</div>
				</Sheet.Content>
			</Sheet.Container>
		</Sheet>
	);
}

export default ToastBooking;
