import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {enableCard} from '../../../store/cards';
import Button from '../../Common/Button';

function ViewOfficeHours(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const schedule = selectedOffice?.openingHours?.office?.schedule;
	const closeCard = () => {
		dispatch(enableCard(false));
	};

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-wrapper">
				<p className="success-text header">{t('bookingPage.outsideOfficeHours.officeHours')}</p>
				<div className="office-hours-list">
					{schedule?.map((daySchedule, index) => (
						<DaySchedule key={index} daySchedule={daySchedule} />
					))}
				</div>
				<div className="buttons-wrapper">
					<Button variant={'primary'} height={'regular'} width={'full'} clickHandler={closeCard} translationKey={'common.okButton'} />
				</div>
			</div>
		</div>
	);
}

function DaySchedule({daySchedule}) {
	const enable12HourFormat = useSelector((state) => state.auth.data.enable12HourFormat);
	const timeFormat = enable12HourFormat ? 'hh:mm a' : 'HH:mm';
	const now = DateTime.now();
	const {t} = useTranslation();

	const weekdayKeys = [
		t('weekdays.monday'),
		t('weekdays.tuesday'),
		t('weekdays.wednesday'),
		t('weekdays.thursday'),
		t('weekdays.friday'),
		t('weekdays.saturday'),
		t('weekdays.sunday'),
	];
	return (
		<div className="day-schedule">
			<p>{weekdayKeys[daySchedule.weekday - 1]}</p>
			{daySchedule.periods.length > 0 ? (
				<div className="period-list">
					{daySchedule?.periods?.map((period, index) => (
						<p key={index} className="period">
							{`${now.startOf('day').plus(period.open).toFormat(timeFormat)} - ${now
								.startOf('day')
								.plus(period.close)
								.toFormat(timeFormat)}`}
						</p>
					))}
				</div>
			) : (
				<p className="period">{t(daySchedule?.open24Hours ? 'officeInfo.open24Hours' : 'officeInfo.closed')}</p>
			)}
		</div>
	);
}
export default ViewOfficeHours;
