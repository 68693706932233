import {useState} from 'react';
import {Trans} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import Icon from '../../../Common/Icon';
import Button from '../../../Common/Button';
import {enableCard, updateErrorCard, updateSuccessCard} from '../../../../store/cards';
import {confirmActionToFunctionsMap} from './confirmActions';
import {CARD} from '../../../../constants/cards';

function ConfirmActionCard() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const data = useSelector((state) => state.cards.data);
	const {headerKey, infoKey, confirmButtonKey, closeButtonKey, confirmAction} = data;

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	const confirmButtonHandler = async () => {
		setLoading(true);
		try {
			const confirmActionFunction = confirmActionToFunctionsMap.get(confirmAction);
			const {successKey, callback} = await confirmActionFunction(dispatch, data, history);
			if (successKey) dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, successKey));
			if (callback) callback();
			setLoading(false);
		} catch (error) {
			console.log(error);
			dispatch(updateErrorCard(CARD.MAIN_ERROR, error.errorTranslationKey, error));
			setLoading(false);
		}
	};
	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="confirmation-wrapper">
				<Icon cssClass={'warning-icon'} iconName={'warning_triangle'} iconColor={'#FFAC25'}></Icon>
				{headerKey && (
					<p className="success-text header">
						<Trans i18nKey={headerKey} />
					</p>
				)}
				{infoKey && (
					<p className="info">
						<Trans i18nKey={infoKey} components={{b: <b />, span: <span />}}/>
					</p>
				)}
				<div className="buttons-wrapper">
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						loading={loading}
						clickHandler={confirmButtonHandler}
						translationKey={confirmButtonKey ?? 'common.buttons.confirm'}
					/>
					<Button
						cssClass={'close-button'}
						variant={'tertiary'}
						height={'regular'}
						width={'full'}
						clickHandler={closeCard}
						translationKey={closeButtonKey ?? 'common.closeButton'}
					/>
				</div>
			</div>
		</div>
	);
}

export default ConfirmActionCard;
