export function compareNames(one, two) {
	one = partializeName(one);
	two = partializeName(two);

	console.log('compareNames first name', one);
	console.log('compareNames second name', two);

	const matches = one.filter((e) => e.some((str) => two.some((e2) => e2.includes(str))));
	console.log('matches:', matches);
	return matches?.length >= 2;

	function partializeName(name) {
		return name
			.toLowerCase()
			.split(/[., ]/)
			.map((str) => {
				const normalized = str
					.normalize('NFD')
					.replace(/\p{Diacritic}/gu, '')
					.replace('<', '-')
					.replace('-', ' ');

				const umlautConverted = str.replace('ä', 'ae').replace('ö', 'oe').replace('ü', 'ue');

				return [normalized, umlautConverted];
			});
	}
}
