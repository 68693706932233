import { DateTime } from 'luxon';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerCertificate } from '../../../api';
import { CARD } from '../../../constants/cards';
import { updateCardAndData, updateSuccessCard } from '../../../store/cards';
import { updateExpiredCeylonState, updateValidCeylon, updateValidCeylonUntil } from '../../../store/dashboard';
import { compareNames } from '../../../functions/compareNames';

function CeylonProcess() {
	const dispatch = useDispatch();
	const data = useSelector((state) => state.cards.data);
	const userData = useSelector((state) => state.auth.data.userData);
	const dateFormat = useSelector((state) => state.auth.data.dateFormat);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const officeTimezone = selectedOffice.timezone;
	let validTestCode = '260415000'; //taken from https://github.com/ehn-dcc-development/ehn-dcc-schema/blob/release/1.3.0/valuesets/test-result.json

	const registerValidCertificate = (validUntil) => {
		registerCertificate(validUntil)
			.then((response) => {
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'ceylon.successCard.header'));
				dispatch(updateValidCeylon(true));
				dispatch(updateValidCeylonUntil(validUntil.setZone(officeTimezone).toFormat(dateFormat)));
			})
			.catch((error) => {
				dispatch(updateValidCeylon(false));
				dispatch(updateValidCeylonUntil(null));
				dispatch(
					updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
						errorKey: 'ceylon.errorCard.header',
					}),
				);
			});
	};

	useEffect(() => {
		if (data) {
			try {
				window
					.EuDgc_parse(data)
					.then((response) => {
						if (response.v) {
							let vaccineExpireDate = DateTime.fromFormat(response?.v[0]?.dt, 'yyyy-MM-dd').toUTC().plus({ months: 9 });
							//   EU/1/20/1525 = mp code for Janssen vaccine, which requires 1 dose
							if (response.v[0]?.dn < 2 && response.v[0]?.mp !== 'EU/1/20/1525') {
								dispatch(updateExpiredCeylonState(true));
								dispatch(
									updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
										errorKey: 'ceylon.errorCard.notValid',
									}),
								);
								return;
							}

							if (compareNames(response.nam?.fnt + ' ' + response.nam?.gnt, userData?.name?.full)) {
								if (DateTime.now().toUTC() < vaccineExpireDate) {
									const validUntil =
										vaccineExpireDate > DateTime.now().toUTC().plus({ weeks: 4 })
											? DateTime.now().toUTC().plus({ weeks: 4 })
											: vaccineExpireDate;
									registerValidCertificate(validUntil);
								} else {
									dispatch(updateExpiredCeylonState(true));
									dispatch(
										updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
											errorKey: 'ceylon.errorCard.notValid',
										}),
									);
								}
							} else {
								dispatch(updateValidCeylon(false));
								dispatch(
									updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
										errorKey: 'ceylon.errorCard.nameFail',
										nameCertificate: response.nam?.fnt + ' ' + response.nam?.gnt,
										nameUser: userData?.name?.full,
									}),
								);
							}
						} else if (response.r) {
							let validUntilDate = DateTime.fromFormat(response?.r[0]?.du, 'yyyy-MM-dd').toUTC();
							if (compareNames(response.nam?.fnt + ' ' + response.nam?.gn, userData?.name?.full)) {
								if (DateTime.now().toUTC() < validUntilDate) {
									const validUntil =
										validUntilDate > DateTime.now().toUTC().plus({ weeks: 4 })
											? DateTime.now().toUTC().plus({ weeks: 4 })
											: validUntilDate;
									registerValidCertificate(validUntil);
								} else {
									dispatch(updateExpiredCeylonState(true));
									dispatch(
										updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
											errorKey: 'ceylon.errorCard.notValid',
										}),
									);
								}
							} else {
								dispatch(updateValidCeylon(false));
								dispatch(
									updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
										errorKey: 'ceylon.errorCard.nameFail',
										nameCertificate: response.nam?.fnt + ' ' + response.nam?.gn,
										nameUser: userData?.name?.full,
									}),
								);
							}
						} else if (response.t && response.t[0]?.tr === validTestCode && selectedOffice?.ceylon?.rule === '3G') {
							let validUntilDate = DateTime.fromFormat(response?.t[0]?.sc, 'yyyy-MM-dd').toUTC().plus({ hours: 24 });
							if (compareNames(response.nam?.fnt + ' ' + response.nam?.gn, userData?.name?.full)) {
								if (DateTime.now().toUTC() < validUntilDate) {
									registerValidCertificate(validUntilDate);
								} else {
									dispatch(updateValidCeylon(false));
									dispatch(
										updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
											errorKey: 'ceylon.errorCard.notValid',
										}),
									);
								}
							} else {
								dispatch(updateValidCeylon(false));
								dispatch(
									updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
										errorKey: 'ceylon.errorCard.nameFail',
										nameCertificate: response.nam?.fnt + ' ' + response.nam?.gn,
										nameUser: userData?.name?.full,
									}),
								);
							}
						} else {
							dispatch(updateValidCeylon(false));
							dispatch(
								updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
									errorKey: 'ceylon.errorCard.nothingFound',
								}),
							);
						}
					})
					.catch((error) => {
						console.log(error);
						console.log('not a eudgc qrcode: ' + error);
						dispatch(updateValidCeylon(false));
						dispatch(
							updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
								errorKey: 'ceylon.errorCard.eudgcParseFail',
							}),
						);
					});
			} catch (error) {
				console.log('not a eudgc qrcode: ' + error);
				dispatch(updateValidCeylon(false));
				dispatch(
					updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
						errorKey: 'ceylon.errorCard.eudgcParseFail',
					}),
				);
			}
		} else {
			dispatch(updateValidCeylon(false));
			dispatch(
				updateCardAndData(CARD.CEYLON_FAILED_VALIDATION, {
					errorKey: 'ceylon.errorCard.noData',
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return null;
}

export default CeylonProcess;
