import {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Trans, useTranslation} from 'react-i18next';
import Sheet from 'react-modal-sheet';
import {useHistory} from 'react-router';
import Icon, {isIconValid} from '../../Common/Icon';
import Button from '../../Common/Button';
import FormattedTime from '../../Common/DateTimePicker/FormattedTime';
import {
	changeAspenSelectedDate,
	changeDeliveryLocation,
	enableAspenSummaryPage,
	updateSelectedAspenItem,
	updateSelectedAspenType,
} from '../../../store/bookingProcess/aspen';
import Dropdown from '../../Common/Dropdown';
import {getDeliveryLocation} from '../../../api/aspen';
import PriceTag from '../../Common/PriceTag';
import {ROUTES} from '../../../constants/routes';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';
import {processFavoriteAspenItemObject} from '../../../store/auth';

function AspenAvailabilityModalSheet({isModalOpen, aspenItem, closeModal, aspenType, isShownFromFavorite}) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
	const enable12HourFormat = useSelector((state) => state.auth.data.enable12HourFormat);
	const selectedAspenType = useSelector((state) => state.aspen.selectedAspenType);
	const favoriteAspenItems = useSelector((state) => state.auth.data.userData.biro.favoriteAspenItems);
	const color = colorBasedOnTheme();
	// * if aspen item is shown inside the favorites list, the aspenType is coming from the item itself.
	const aspenTypeOfItem = isShownFromFavorite ? aspenType : selectedAspenType;
	const deliveryLocationEnabled = aspenTypeOfItem?.delivery?.teakTypes?.length > 0;

	const [defaultLocationOption, setDefaultLocationOption] = useState(null);
	const [locationOption, setLocationOption] = useState(null);
	const [dropdownOptions, setDropdownOptions] = useState(null);

	function openSummaryPage() {
		dispatch(changeAspenSelectedDate(selectedTimeSlot));
		closeModal();
		dispatch(enableAspenSummaryPage(true));
		if (isShownFromFavorite) {
			dispatch(updateSelectedAspenType(aspenType));
			dispatch(updateSelectedAspenItem(aspenItem));
			history.push(ROUTES.ASPEN_LIST);
		}
	}
	useEffect(() => {
		dispatch(changeDeliveryLocation(null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function getIconName(iconName) {
		if (isIconValid(iconName)) {
			return iconName;
		} else {
			return aspenTypeOfItem?.iconName;
		}
	}
	useEffect(async () => {
		if (!deliveryLocationEnabled) return;
		if (!selectedTimeSlot) return;
		try {
			const deliveryLocationResponse = await getDeliveryLocation(aspenItem?._id, selectedTimeSlot);
			if (deliveryLocationResponse?.data?.bookings?.length > 0) {
				setDropdownOptions([
					defaultLocationOption,
					...deliveryLocationResponse?.data?.bookings.map((booking) => ({value: booking.teakEntity._id, label: booking.teakEntity.name})),
				]);
			} else {
				setDropdownOptions([defaultLocationOption]);
			}
		} catch (error) {
			console.log(error);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedTimeSlot, aspenType]);

	useEffect(() => {
		if (aspenTypeOfItem) {
			const defaultLocationOption = {
				value: 'default',
				label: `${aspenTypeOfItem?.delivery?.location ?? ''} (${t('common.default')})`,
			};
			setDefaultLocationOption(defaultLocationOption);
			setLocationOption(defaultLocationOption);
			setDropdownOptions([defaultLocationOption]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [aspenTypeOfItem]);

	function updateDeliveryLocation(newOption) {
		setLocationOption(newOption);
		dispatch(changeDeliveryLocation(newOption.value === defaultLocationOption.value ? null : newOption));
	}

	function onFavoriteClick() {
		dispatch(processFavoriteAspenItemObject(aspenItem));
	}

	return (
		<div className="aspen-item-modal-sheet">
			<Sheet isOpen={isModalOpen} onClose={closeModal} disableDrag={true} className="aspen-item-modal-sheet-wrapper">
				<Sheet.Container className="aspen-item-modal-sheet-container">
					<Sheet.Content className="aspen-item-modal-sheet-content">
						<div className="aspen-item-modal-sheet-content-header">
							<Sheet.Header onClick={closeModal}>
								<div className="modal-header-wrapper">
									<p>{t('common.closeButton')}</p>
								</div>
							</Sheet.Header>
							<div className="icon-wrapper">
								<Icon cssClass="icon-grey" iconName={getIconName(aspenItem?.iconName)} />
							</div>
							<div className="order-header">
								<h4 className="header">{t('serviceBooking.yourOrder')}</h4>
							</div>
						</div>
						<div className="aspen-item-modal-sheet-content-content">
							<div className="top-information">
								<AspenTotalPrice aspenItem={aspenItem} />
								<Icon
									iconName={'heartIcon'}
									iconColor={'none'}
									cssClass={
										favoriteAspenItems?.some((a) => a?._id === aspenItem?._id)
											? 'icon-active-favorite ' + color.favoriteIconColor
											: color.favoriteIconColor
									}
									clickHandler={(e) => onFavoriteClick()}
								/>
							</div>
							<Dropdown
								options={dropdownOptions}
								value={locationOption}
								labelKey={'serviceBooking.deliveryLocation'}
								onChange={(value) => updateDeliveryLocation(value)}
								disabled={!deliveryLocationEnabled}
								required={true}
							/>
							{deliveryLocationEnabled && (
								<div className="location-info-wrapper">
									<div className="location-info-wrapper-icon">
										<Icon iconName="info" cssClass="icon-black" />
									</div>
									<p>
										<Trans i18nKey={t('serviceBooking.chooseLocation')} components={{span: <span />}} />
									</p>
								</div>
							)}
							<h5 className="start-time-header">{t('common.dateTimePicker.startTime')}*</h5>
							<div className="time-slots-wrapper">
								<div className="time-slots-container">
									{aspenItem?.availableTimeSlots.map((timeSlot) => (
										<div
											className={`time-slot ${timeSlot === selectedTimeSlot ? 'time-slot-selected' : ''} ${
												enable12HourFormat && 'time-slot-small-padding'
											}`}
											onClick={() => setSelectedTimeSlot(timeSlot)}>
											<FormattedTime time={timeSlot} />
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="aspen-item-modal-sheet-content-button button-fixed-at-bottom-static">
							<Button
								variant={'primary'}
								height={'regular'}
								width={'full'}
								clickHandler={openSummaryPage}
								translationKey={'common.bookNow'}
								loading={false}
								disabled={!selectedTimeSlot}
							/>
						</div>
					</Sheet.Content>
				</Sheet.Container>
			</Sheet>
		</div>
	);
}

function AspenTotalPrice({aspenItem}) {
	const {t} = useTranslation();
	const paidByCompany = aspenItem?.paidBy === 'company';
	const zeroPrice = <PriceTag item={{price: {amount: 0, currency: aspenItem?.price?.currency}}} />;

	return (
		<div className="pricing-information">
			<div className="item-paid">
				{' '}
				<span>{aspenItem.name}</span>{' '}
				<span>
					<PriceTag item={aspenItem} />
				</span>
			</div>
			<div className="item-paid">
				{' '}
				<span>{t('serviceBooking.paidByCompany')}</span>{' '}
				<span>
					{paidByCompany ? (
						<>
							<PriceTag negative={true} item={aspenItem} />
						</>
					) : (
						zeroPrice
					)}
				</span>
			</div>
			<hr className="dividing-hr"></hr>
			<div className="items-total">
				<span>{t('common.total')}</span>
				<span className="total">{paidByCompany ? zeroPrice : <PriceTag item={aspenItem} />}</span>
			</div>
		</div>
	);
}
export default AspenAvailabilityModalSheet;
