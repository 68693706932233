import {useSelector} from 'react-redux';
import {DateTime} from 'luxon';

/**
 * Component that retrieves a formatted time used in the app. It holds functionality for 12/24 hours format
 *
 * @param {iso} time = date time in iso format
 * @param {string} timezone = timezone of the shown time
 * @returns
 */
function FormattedTime({time, timezone}) {
	const enable12HourFormat = useSelector((state) => state.auth.data.enable12HourFormat);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const timeFormat = enable12HourFormat ? 'hh:mm a' : 'HH:mm';
	const formattedTime = `
${DateTime.fromISO(time)
	.setZone(timezone ? timezone : selectedOffice?.timezone)
	.toFormat(timeFormat)}`;
	return formattedTime;
}

export default FormattedTime;
