import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { enableCard, updateCardAndData} from '../../store/cards';
import { CARD } from '../../constants/cards';
import { ROUTES } from '../../constants/routes';
import { updateCheckedList } from '../../store/settings';
import { useHistory } from 'react-router';

const BookingOptionsMenuForAttendee = (props) => {
	const dispatch = useDispatch();
	const authData = useSelector((state) => state.auth.data);
	const { actionSheetData } = authData;
	const booking = actionSheetData?.booking;
	const history = useHistory();

	const handleStatus = (statusType) => {
		console.log("STATUSTYPE: ",statusType);
		const data = {
			booking,
			statusType
		}
		dispatch(enableCard(true));
		dispatch(updateCardAndData(CARD.HANDLE_ACCEPT_DECISION_INVITE, data));
	}

	const handleForwardClick = () => {
		dispatch(updateCheckedList([]));
		history.push(ROUTES.PROFILE_PAGE + ROUTES.COLLEAGUE_LIST, { addAttendee: true, forwardMeetingId: booking?._id });
	};

	return (
		<>
			<div className="action-sheet-option" onClick={handleForwardClick}>
				{t('common.forward')}
			</div>
			<div className="action-sheet-option" onClick={()=>handleStatus('accept')}>
				{t('common.accept')}
			</div>
			<div className="action-sheet-option" onClick={()=>handleStatus('tentativelyAccept')}>
				{t('common.tentative')}
			</div>
			<div className="action-sheet-option" onClick={()=>handleStatus('decline')}>
				{t('common.decline')}
			</div>
		</>
	);
};

export default BookingOptionsMenuForAttendee;
