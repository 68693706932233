// import {blueThemeColors} from '../constants/blueThemeColors';
import {defaultThemeColors} from '../constants/defaultThemeColors';
// import {redThemeColors} from '../constants/redThemeColors';

/*+
 * @deprecated use var(--color-main)' instead or the ThemeEngine.js
 * this function should be removed in the future
 */
function colorBasedOnTheme() {
	const theme = window.localStorage.getItem('colorTheme');
	switch (theme) {
		// case 'trainRed':
		// 	return redThemeColors;
		// case 'bellTheme':
		// 	return blueThemeColors;
		default:
			return defaultThemeColors;
	}
}

export default colorBasedOnTheme;
