import {createSlice} from '@reduxjs/toolkit';
import {getReportProblemCategories, getReportProblemTranslations, getReportsList} from '../api/reportProblem';
import sortAlphabetically from '../functions/sortAlphabetically';
import get from 'lodash/get';
import {DateTime} from 'luxon';

const slice = createSlice({
	name: 'reportProblem',
	initialState: {
		selectedArea: {
			areaId: null,
			areaName: null,
		},
		selectedProblemCategory: {
			name: null,
			category: null,
		},
		selectedDesk: {
			deskId: null,
			deskName: null,
		},
		selectedRoom: {
			roomId: null,
			roomName: null,
		},
		issueReports: [],
		selectedLocation: '',
		enteredComment: ' ',
		enteredSummary: '',
		reproductionSteps: ' ',
		imageArray: [],
		categoriesList: [],
		reportProblemTranslations: [],
		lastFetch: null,
	},
	reducers: {
		areaObjChanged: (reportProblem, action) => {
			reportProblem.selectedArea.areaId = action.payload.areaId;
			reportProblem.selectedArea.areaName = action.payload.areaName;
		},
		deskObjChanged: (reportProblem, action) => {
			reportProblem.selectedDesk.deskId = action.payload.deskId;
			reportProblem.selectedDesk.deskName = action.payload.deskName;
		},
		roomObjChanged: (reportProblem, action) => {
			reportProblem.selectedRoom.roomId = action.payload.roomId;
			reportProblem.selectedRoom.roomName = action.payload.roomName;
		},
		categoryProblemObjChanged: (reportProblem, action) => {
			reportProblem.selectedProblemCategory.name = action.payload.name;
			reportProblem.selectedProblemCategory.category = action.payload.category;
		},
		locationChanged: (reportProblem, action) => {
			reportProblem.selectedLocation = action.payload;
		},
		commentChanged: (reportProblem, action) => {
			reportProblem.enteredComment = action.payload;
		},
		summaryChanged: (reportProblem, action) => {
			reportProblem.enteredSummary = action.payload;
		},
		reproductionStepsChanged: (reportProblem, action) => {
			reportProblem.reproductionSteps = action.payload;
		},
		imageArrayUpdated: (reportProblem, action) => {
			reportProblem.imageArray = action.payload;
		},
		categoriesListUpdated: (reportProblem, action) => {
			reportProblem.categoriesList = action.payload;
		},
		reportProblemTranslationsReceived: (reportProblem, action) => {
			reportProblem.reportProblemTranslations = action.payload;
		},
		issueReportsChanged: (reportProblem, action) => {
			reportProblem.issueReports = action.payload.issueReports;
			reportProblem.lastFetch = action.payload.lastFetch;
		},
	},
});

export const {
	areaObjChanged,
	deskObjChanged,
	roomObjChanged,
	categoryProblemObjChanged,
	locationChanged,
	commentChanged,
	summaryChanged,
	reproductionStepsChanged,
	imageArrayUpdated,
	categoriesListUpdated,
	reportProblemTranslationsReceived,
	issueReportsChanged,
} = slice.actions;

export default slice.reducer;

export const changeArea = (areaId, areaName) => (dispatch, getState) => {
	if (areaId === getState().reportProblem.selectedArea.areaId && areaName === getState().reportProblem.selectedArea.areaName) return;
	dispatch({type: areaObjChanged.type, payload: {areaId, areaName}});
};

export const changeDesk = (deskId, deskName) => (dispatch, getState) => {
	if (deskId === getState().reportProblem.selectedDesk.deskId && deskName === getState().reportProblem.selectedDesk.deskName) return;
	dispatch({type: deskObjChanged.type, payload: {deskId, deskName}});
};

export const changeRoom = (roomId, roomName) => (dispatch, getState) => {
	if (roomId === getState().reportProblem.selectedRoom.roomId && roomName === getState().reportProblem.selectedRoom.roomName) return;
	dispatch({type: roomObjChanged.type, payload: {roomId, roomName}});
};

export const changeCategoryProblem = (name, category) => (dispatch, getState) => {
	if (name === getState().reportProblem.selectedProblemCategory.name && category === getState().reportProblem.selectedProblemCategory.category)
		return;
	dispatch({
		type: categoryProblemObjChanged.type,
		payload: {name, category},
	});
};

export const changeLocation = (location) => (dispatch, getState) => {
	if (location === getState().reportProblem.selectedLocation) return;
	dispatch({type: locationChanged.type, payload: location});
};

export const changeComment = (comment) => (dispatch, getState) => {
	if (comment === getState().reportProblem.enteredComment) return;
	dispatch({type: commentChanged.type, payload: comment});
};

export const changeSummary = (summary) => (dispatch, getState) => {
	if (summary === getState().reportProblem.enteredSummary) return;
	dispatch({type: summaryChanged.type, payload: summary});
};

export const changeReproductionSteps = (reproductionSteps) => (dispatch, getState) => {
	if (reproductionSteps === getState().reportProblem.reproductionSteps) return;
	dispatch({
		type: reproductionStepsChanged.type,
		payload: reproductionSteps,
	});
};

export const updateImageArray = (newImageObject) => (dispatch, getState) => {
	const currentImageArray = getState().reportProblem.imageArray;
	const arrayCopy = currentImageArray.slice();
	arrayCopy.push(newImageObject);

	dispatch({type: imageArrayUpdated.type, payload: arrayCopy});
};

export const removeItemImage = (imageName) => (dispatch, getState) => {
	const currentImageArray = getState().reportProblem.imageArray;
	let arrayCopy = currentImageArray.slice();
	arrayCopy = arrayCopy.filter((image) => image.name !== imageName);
	dispatch({type: imageArrayUpdated.type, payload: arrayCopy});
};

export const emptyReportProblemStore = () => (dispatch, getState) => {
	dispatch({
		type: areaObjChanged.type,
		payload: {areaId: null, areaName: null},
	});
	dispatch({
		type: deskObjChanged.type,
		payload: {deskId: null, deskName: null},
	});
	dispatch({
		type: roomObjChanged.type,
		payload: {roomId: null, roomName: null},
	});
	dispatch({
		type: categoryProblemObjChanged.type,
		payload: {name: null, category: null},
	});
	dispatch({type: locationChanged.type, payload: ''});
	dispatch({type: commentChanged.type, payload: ' '});
	dispatch({type: summaryChanged.type, payload: ''});
	dispatch({type: reproductionStepsChanged.type, payload: ' '});
	dispatch({type: imageArrayUpdated.type, payload: []});
};

export const updateCategoriesArray = (officeId) => (dispatch, getState) => {
	let newCategoriesArr = null;
	const reportProblemTranslations = getState().reportProblem.reportProblemTranslations;
	const selectedLanguage = getState().auth.data.userData?.language ? getState().auth.data.userData?.language?.toUpperCase() : 'EN';
	getReportProblemCategories(officeId)
		.then((response) => {
			newCategoriesArr = response.data;

			const newArray = newCategoriesArr.map((item) => {
				const labelTranslated = get(reportProblemTranslations[item?.name], selectedLanguage);
				return {
					value: item._id,
					label: labelTranslated ? labelTranslated : item.name,
					__t: item.__t,
				};
			});

			const sortedArray = sortAlphabetically(newArray, 'label');
			dispatch({type: categoriesListUpdated.type, payload: sortedArray});
		})
		.catch((error) => {
			dispatch({type: categoriesListUpdated.type, payload: []});
			console.log(error);
		});
};

export const addReportProblemTranslationsToStore = () => async (dispatch, getState) => {
	const currentReportProblemTranslations = getState().reportProblem.reportProblemTranslations;
	if (currentReportProblemTranslations.length > 0) return;

	try {
		const reportProblemTranslationsResponse = await getReportProblemTranslations();
		dispatch({
			type: reportProblemTranslationsReceived.type,
			payload: reportProblemTranslationsResponse.data,
		});
	} catch (error) {
		dispatch({type: reportProblemTranslationsReceived.type, payload: []});
	}
};

export const loadIssueReports = (forceReload) => async (dispatch, getState) => {
	if (!forceReload) {
		const lastFetchOfData = getState().reportProblem.lastFetch;
		if (lastFetchOfData && DateTime.now().toUTC() < DateTime.fromISO(lastFetchOfData).toUTC().plus({minutes: 1})) return;
	}
	try {
		const selectedOffice = getState().auth.data.selectedOffice;
		const issueReports = await getReportsList(selectedOffice?.id);
		dispatch({type: issueReportsChanged.type, payload: {issueReports: issueReports.data, lastFetch: DateTime.now().toISO()}});
	} catch (error) {
		console.log(error);
		return;
	}
};
