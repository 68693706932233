import {useEffect, useState} from 'react';
import {DateTime} from 'luxon';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';

/**
 *
 * @param {DateTime} date = date time object that represents the day
 * @param {function} onClick = callback when day was selected
 * @param {boolean} active = if day is selected or not
 * @returns a component that holds a day option used for the calendar view.
 */

function DayItem({date, onClick, active}) {
	const now = DateTime.now();
	const [cssClass, setCssClass] = useState();
	const dateFormat = useSelector((state) => state.auth.data.dateFormat);
	const {i18n} = useTranslation();
	const clickHandler = () => {
		onClick(date);
	};

	useEffect(() => {
		if (active) {
			if (date.toFormat(dateFormat) === now.toFormat(dateFormat)) {
				setCssClass('today');
			} else {
				setCssClass(date < now ? 'past' : 'future');
			}
		} else {
			if (cssClass) setCssClass();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [date, active]);

	return (
		<div className={'day-item-wrapper ' + (cssClass ? cssClass : '')} onClick={clickHandler}>
			<p className="day-header"> {date?.day} </p>
			<p className="day-abbreviation">{date?.setLocale(i18n.language === 'ru' ? 'fr' : i18n.language).toFormat('ccc')}</p>
			{date.toFormat(dateFormat) === now.toFormat(dateFormat) ? <div className="day-dot"></div> : null}
		</div>
	);
}

export default DayItem;
