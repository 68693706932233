import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {enableCard} from '../../../store/cards';
import Button from '../../Common/Button';

function TimeTrackingCard(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const inputRef = useRef();

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	const scanHandler = () => {
		dispatch(enableCard(false));
	};

	const uploadHandler = async () => {
		// dispatch(enableCard(true));
		// dispatch(updateCard(CARD.PLACEHOLDER));
		// let fileInput = inputRef.current;
		// let image = fileInput?.files[0];

		// if (!image) return;
		// let imageUrl = window.URL.createObjectURL(image);

		// qrcode
		// 	.scan(imageUrl)
		// 	.then((result) => {
		// 		dispatch(addDataToCard(result?.data));
		// 		dispatch(updateCard(CARD.CEYLON_PROCESS));
		// 	})
		// 	.catch((error) => {
		// 		dispatch(updateErrorCard(CARD.MAIN_ERROR, 'ceylon.errorCard.imageTypeFail', error));
		// 		console.log(error);
		// 	});
	};

	const openUpload = () => {
		// inputRef.current.click();
	};

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="ceylon-info-content">
				<p className="header">{t('ceylon.infoCard.header')}</p>
				<input className="hidden" type="file" onChange={uploadHandler} accept="image/*" ref={inputRef} />
				<div className="buttons-wrapper">
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						clickHandler={scanHandler}
						translationKey={'ceylon.infoCard.scanQR'}
					/>
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						clickHandler={openUpload}
						translationKey={'ceylon.infoCard.import'}
					/>
					<Button
						variant={'secondary'}
						height={'regular'}
						width={'full'}
						clickHandler={closeCard}
						translationKey={'ceylon.infoCard.show'}
					/>
				</div>
			</div>
		</div>
	);
}

export default TimeTrackingCard;
