import { t } from 'i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { ROUTES } from '../../constants/routes';

const UnscheduledInvitationsMenu = (props) => {
	const data = useSelector((state) => state.auth.data.actionSheetData);
	const history = useHistory();

	const redirectToAllUnscheduled = (e) => {
		e.stopPropagation();
		props.closeActionSheet();
		history.push({
			pathname: ROUTES.VISITOR.UNSCHEDULED_VISITORS,
			state: {
				unscheduledInvitation: data,
			},
		});
	};

	return (
		<div className="action-sheet-option" onClick={redirectToAllUnscheduled}>
			{t('visitorManagement.checkMeetingDetails')}
		</div>
	);
};

export default UnscheduledInvitationsMenu;
