import { getAreasFromOffice, getAspenTypes, getManyTeakFeatures, getManyTeakTypes } from '../api';
import { errorReceived } from '../store/auth';

/**
 * This function receives as parameters data that is already in store.
 * Its scope is to get new data for the new office and add it (together with what was already in) to the store.
 *
 * @param {function} dispatch = function used to dispatch actions
 * @param {Array} areasFromStore = array of areas already in store.
 * @param {Array} areasTreeListFromStore = tree structure of areas that are already in store.
 * @param {Array} officesFromStore = array of offices already in store.
 * @param {Array} teakTypeArrayFromStore = array of teak types already in store.
 * @param {Array} teakFeaturesArrayFromStore = array of teak features already in store.
 * @param {Array} aspenTypesFromStore = array of aspen types already in store.
 * @param {id} office
 * @returns
 */
async function getDataFromAPIForOffice(
	dispatch,
	areasFromStore,
	areasTreeListFromStore,
	officesFromStore,
	teakTypeArrayFromStore,
	teakFeaturesArrayFromStore,
	aspenTypesFromStore,
	office,
) {
	let areasList = areasFromStore.slice();
	let areasTreeList = areasTreeListFromStore.slice();
	const teakTypeArray = teakTypeArrayFromStore.slice();
	let teakFeaturesArray = teakFeaturesArrayFromStore.slice();
	let officesList = officesFromStore.slice();
	let aspenTypes = aspenTypesFromStore.slice();
	const newSelectedOfficeIndex = officesList.findIndex((element) => element._id === office._id);

	// Parallel API calls
	try {
		const [areaResponse, manyFeaturesForOfficeResponse, teakTypeResponse, aspenTypeResponse] = await Promise.all([
			getAreasFromOffice(office._id),
			getManyTeakFeatures(office._id),
			getManyTeakTypes(office._id),
			getAspenTypes(office._id),
		]);

		// Process areas
		if (areaResponse?.data?.length > 0) {
			areasTreeList.push({ officeId: office._id, areas: areaResponse.data });

			function getFlatAreaList(areas) {
				let areaFlatList = [];
				areas.forEach((area) => {
					areaFlatList.push(area);
					if (area?.children?.length > 0) {
						areaFlatList.push(...getFlatAreaList(area.children));
					}
				});
				return areaFlatList;
			}
			areasList.push(...getFlatAreaList(areaResponse.data));
		}

		// Process Teak Features
		if (manyFeaturesForOfficeResponse?.data?.length > 0) {
			manyFeaturesForOfficeResponse.data.forEach((feature) => {
				teakFeaturesArray.push({ ...feature, id: feature._id });
			});
		}

		// Process Teak Types
		if (teakTypeResponse?.data) {
			teakTypeArray.push({
				office: office._id,
				teakTypes: teakTypeResponse.data,
			});
		}

		// Process Aspen Types
		if (aspenTypeResponse?.data) {
			aspenTypes.push({
				officeId: office._id,
				types: aspenTypeResponse.data,
			});
		}

		// Mark office as fully loaded
		officesList[newSelectedOfficeIndex] = {
			...officesList[newSelectedOfficeIndex],
			hasAllData: true,
		};

		return {
			offices: officesList,
			areas: areasList,
			areasTreeList: areasTreeList,
			teakTypeArray: teakTypeArray,
			teakFeatures: teakFeaturesArray,
			aspenTypes,
		};

	} catch (error) {
		dispatch({
			type: errorReceived.type,
			payload: 'errorMessages.apiCalls.general',
		});
		console.error('Error fetching data:', error);
	}
}

export { getDataFromAPIForOffice };
