import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { enableCard } from '../../../../store/cards';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Button from '../../../Common/Button';
import { DateTime } from 'luxon';

function TimeTrackingWorkingSummaryCard(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth.data);
  const { enable12HourFormat, selectedOffice } = authData;

  const timeZone = selectedOffice?.timezone;
  const data = useSelector((state) => state.cards.data);
  const { trackedTimeRecords } = data;
  const [totalWorkedTime, setTotalWorkedTime] = useState("00:00");
  const [totalPause, setTotalPause] = useState("00:00");

  const closeCard = async () => {
    dispatch(enableCard(false));
  };

  useEffect(() => {
    calculateTimes(trackedTimeRecords);
  }, [trackedTimeRecords]);

  const formatDuration = (totalMillis) => {
    const totalMinutes = Math.floor(totalMillis / 60000); // Milliseconds to Minutes
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (enable12HourFormat) {
      const period = hours >= 12 ? 'PM' : 'AM';
      const adjustedHours = hours % 12 || 12;
      return `${adjustedHours}:${String(minutes).padStart(2, '0')} ${period}`;
    } else {
      return `${String(hours).padStart(2, '0')} Std. ${String(minutes).padStart(2, '0')} Min.`;
    }
  };

  const calculateTotalTime = (recordType) => {
    const totalMillis = trackedTimeRecords
      .filter(timeRecord => timeRecord.name === recordType)
      .map(tObj => {
        const start = DateTime.fromISO(tObj.start?.time).setZone(timeZone);
        const end = DateTime.fromISO(tObj.end?.time).setZone(timeZone);

        if (end && start) {
          return end.diff(start, 'milliseconds').milliseconds;
        }
        return 0;
      })
      .reduce((acc, curr) => acc + curr, 0); // Sum of all time differences of recordType

    console.log(`Total milliseconds for ${recordType}: ${totalMillis}`);
    return formatDuration(totalMillis);
  };

  const calculateTimes = (trackedTimeRecords) => {
    if (!trackedTimeRecords) return;

    setTotalWorkedTime(calculateTotalTime("work"));
    setTotalPause(calculateTotalTime("pause"));
  };

  return (
    <div className="tt-cr-card-backdrop" onClick={closeCard}>
      <div className="tt-cr-card-content centered-absolute working-summary-container">
        <Typography variant="h6" component="div" className="tt-cr-title">
          <b>{t('timeTracker.workSummary.title')}</b>
        </Typography>
        <Box className="tt-cr-details working-summary-container-list">
          <Box>
            <Typography variant="h6" color="textSecondary">
              {t(`timeTracker.workSummary.office`)}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {selectedOffice.name}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" color="textSecondary">
              {t(`timeTracker.workSummary.totalWorkedTime`)}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {totalWorkedTime}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" color="textSecondary">
              {t('timeTracker.workSummary.totalPause')}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              {totalPause}
            </Typography>
          </Box>
        </Box>
        <Button
          variant={'primary'}
          height={'regular'}
          width={'full'}
          translationKey={'timeTracker.workSummary.close'}
          clickHandler={closeCard}
        />
      </div>
    </div>
  );
}

export default TimeTrackingWorkingSummaryCard;
