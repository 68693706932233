import axios from 'axios';
import apiUrl from './index';

const source = axios.CancelToken.source();
let config = {cancelToken: source.token};

export async function getAreasFromOffice(officeId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.area + apiUrl.path.findMany;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				office: officeId,
			},
		},
		config,
	);
}

export async function getAreaById(id) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.area + '/' + id;

	return axios(
		{
			method: 'get',
			url: requestUrl,
		},
		config,
	);
}

export function getBannerForArea(areaId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.area + '/' + areaId + apiUrl.path.banner + '?variation=400';

	return axios(
		{
			method: 'get',
			url: requestUrl,
			responseType: 'arraybuffer',
		},
		config,
	);
}

export async function getMapOutline(areaId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.area + '/' + areaId + apiUrl.path.mapOutlines;

	return axios(
		{
			method: 'get',
			url: requestUrl,
			responseType: 'blob'
		},
		config,
	);
}

export async function getAreasInRange(officeId, teakType, range, colleague, isOnlineMeeting) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.area + apiUrl.path.availabilityView;

	return axios(
		{
			method: 'post',
			url: requestUrl,
			data: {
				office: officeId,
				teakType: teakType,
				range: range,
				colleague,
				isOnlineMeeting
			},
		},
		config,
	);
}
