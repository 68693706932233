import Icon from '../../Icon';
/**
 *
 * @param {*} props.cssClass = css class added to the button element
 * @param {*} props.disabled = if the button is disabled or not
 * @param {*} props.clickHandler = callback for button click
 * @returns
 */
function ButtonBack(props) {
	let cssClass = 'btn-back ';
	if (props.isAreaList) {
		//The back-button in the area list is placed further to the left because the circle-shaped background is not visible on the area list background. That is why a separate class is used for the area list only.
		cssClass = 'btn-back area-list';
	}

	if (props.cssClass !== undefined) {
		cssClass += props.cssClass;
	}

	return (
		<button onClick={props.clickHandler} className={cssClass}>
			<Icon cssClass={'icon-black'} iconName={'chevron-left'} size={props.iconSize ? props.iconSize : 'xs'}></Icon>
		</button>
	);
}

export default ButtonBack;
