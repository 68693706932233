import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';

import Icon from '../Icon';
import {DateTime} from 'luxon';

/**
 *
 * @param {*} timezone = timezone to disaply the time
 * @param {*} disabled = if time picker is disabled
 * @param {*} disabledMinutes = disabled hours that cannot be selected by user
 * @param {*} disabledHours = disabled hours that cannot be selected by user
 * @param {*} value = selected time
 * @param {*} changeValue = callback for when the time has changed
 * @param {*} minuteStep = steps in which the time options are displayed (usually 15 for 15 mins steps)
 * @param {*} timeLabel = time label shown above time picker
 * @param {*} clockstyle = enabling time picker with clock icon
 * @param {*} dropdownstyle = enabling time picker with angle up/down icon
 * @returns common time picker component. used by users to select a time
 */
function CommonTimePicker({
	timezone,
	disabled,
	disabledMinutes,
	disabledHours,
	value,
	changeValue,
	minuteStep,
	timeLabel,
	clockstyle,
	dropdownstyle,
}) {
	const {t} = useTranslation();
	const color = colorBasedOnTheme();
	const [focusedInput, setFocusedInput] = useState(false);
	const enable12HourFormat = useSelector((state) => state.auth.data.enable12HourFormat);

	let deskTimeClass = 'time-container';

	if (disabled) deskTimeClass += ' disabled';

	let format12hrs = enable12HourFormat;
	let format = format12hrs ? 'hh:mm a' : 'HH:mm';
	return (
		<div className={`common-time-wrapper ${focusedInput && 'focused-input'}`}>
			<label className={'time-label'}>{t(timeLabel)}</label>
			<div className={deskTimeClass}>
				{clockstyle && !dropdownstyle && <Icon cssClass={color.mainThemeIconColor} iconName="clock" />}
				<TimePicker
					showSecond={false}
					value={moment(value).tz(timezone)}
					onChange={(e) => {
						changeValue(DateTime.fromISO(e.toISOString()).setZone(timezone).toISO());
					}}
					format={format}
					use12Hours={format12hrs}
					inputReadOnly
					allowEmpty={false}
					disabled={disabled}
					disabledHours={disabledHours}
					disabledMinutes={disabledMinutes}
					getPopupContainer={(node) => node}
					minuteStep={minuteStep}
					onOpen={() => setFocusedInput(true)}
					onClose={() => setFocusedInput(false)}
				/>
				{dropdownstyle && !clockstyle && <Icon cssClass={color.datePickerIcon} iconName={focusedInput ? 'angle-up' : 'angle-down'} />}
			</div>
		</div>
	);
}

export default CommonTimePicker;
