import { getFeatureTranslation } from './getFeatureTranslation';

//this function populates the items in a teakEntities list with area object and teakFeatures objects
export function getTeakEntitiesFull(areas, featureTranslations, userData, teakFeatures, teakEntities) {
	const areasFromStore = areas;
	const teakFeaturesFromStore = teakFeatures;
	const selectedLanguage = userData?.language ? userData?.language?.toUpperCase() : 'EN';

	let teakEntitiesFull = [];
	teakEntities.forEach((entity) => {
		let entityCopy = { ...entity };
		const areaIdOfEntity = entityCopy?.area?._id ? entityCopy?.area?._id : entityCopy?.area;
		let areaFromStore = areasFromStore.find((area) => area._id === areaIdOfEntity);
		let parentAreaFromStore = areasFromStore.find((area) => area._id === areaFromStore?.parent);

		let featureNames = [];
		for (let index = 0; index < entityCopy?.teakFeatures?.length; index++) {
			const teakFeatureId = entityCopy?.teakFeatures[index]?._id ? entityCopy?.teakFeatures[index]?._id : entityCopy?.teakFeatures[index];
			const featureFromStore = teakFeaturesFromStore.find((feature) => feature._id === teakFeatureId);

			const featureNameTranslation = getFeatureTranslation(featureFromStore, featureTranslations, selectedLanguage);
			featureNames.push({
				icon: featureFromStore?.iconName,
				name: featureNameTranslation ? featureNameTranslation : featureFromStore?.name,
				id: featureFromStore?._id,
			});
		}
		teakEntitiesFull.push({
			...entityCopy,
			areaName: areaFromStore?.name,
			parentAreaName: parentAreaFromStore?.name,
			featureNames: featureNames,
		});
	});

	return teakEntitiesFull;
}
