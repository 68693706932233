import {t} from 'i18next';
import {useDispatch, useSelector} from 'react-redux';
import {deleteCedarChangeRequest} from '../../api/timeTracker';
import {enableCard, updateCardAndData, updateErrorCard, updateSuccessCard} from '../../store/cards';
import {CARD} from '../../constants/cards';
import {loadChangeRequests} from '../../store/timeTracking';

const ChangeRequestMenu = () => {
	const dispatch = useDispatch();
	const authData = useSelector((state) => state.auth.data);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const {actionSheetData} = authData;

	const handleShowDetailsButton = () => {
		dispatch(enableCard(true));
		dispatch(updateCardAndData(CARD.TIME_TRACKING_CHANGE_REQUEST, {changeRequest: actionSheetData}));
	};

	const handleDeleteButton = async () => {
		dispatch(enableCard(true));
		try {
			await deleteCedarChangeRequest(actionSheetData._id);
			await dispatch(loadChangeRequests(selectedOffice?._id));
			dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'dashboard.timeTracker.changeRequestDeleteSuccess'));
		} catch (error) {
			dispatch(updateErrorCard(CARD.MAIN_ERROR, 'dashboard.timeTracker.changeRequestDeleteError', error));
		}
	};

	return (
		<>
			<div className="action-sheet-option" onClick={handleShowDetailsButton}>
				{t('common.seeDetails')}
			</div>
			<div className="action-sheet-option" onClick={handleDeleteButton}>
				{t('common.deleteButton')}
			</div>
		</>
	);
};

export default ChangeRequestMenu;
