import { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
/**
 *
 * @param {*} maximumCharacterLimit = max chars that can be added in text area
 * @param {*} cssClass = classname used for textarea wrapper
 * @param {*} defaultValue = value of textarea
 * @param {*} labelKey = label text used for textarea label
 * @param {*} disabled = if textarea is disabled
 * @param {*} cols = columns number for textarea
 * @param {*} rows = rows number for textarea
 * @param {*} id = id of textarea input
 * @param {*} maxLength = max length  of textarea
 * @param {*} onFocus = callback used when textarea is focused
 * @param {*} onBlur = callback used when focus is moved from textarea
 * @param {*} required = if input is required
 * @param {*} changeHandler = callback when textarea value is changed
 * @param {*} placeholder = text shown as placeholder in textarea
 * @param {*} characterLimitPosition = max chars that can be shown on 'top' next to the header or on the 'bottom'
 * @param {*} characterLimitVersion = choosing a different max chars design
 * @returns Component that renders text area component
 */
export const Textarea = forwardRef(
	(
		{
			maximumCharacterLimit = '',
			cssClass = '',
			defaultValue,
			labelKey,
			disabled,
			cols,
			rows,
			id,
			maxLength,
			onFocus,
			onBlur,
			required,
			changeHandler,
			placeholder,
			characterLimitPosition = 'top',
			characterLimitVersion = 1
		},
		ref,
	) => {
		const { t } = useTranslation();

		let [numberOfCharsLeft, setNumberOfCharsLeft] = useState(
			defaultValue ? Math.abs(maximumCharacterLimit - defaultValue?.length) : maximumCharacterLimit,
		);
		let numberOfCharsText = t('characterHint');

		if (disabled) cssClass += ' disabled';

		let requiredMark = required ? '*' : '';

		const handleChange = (newValue) => {
			changeHandler(newValue);

			if (!maximumCharacterLimit) {
				return;
			}

			if (maximumCharacterLimit - newValue.length > 0) {
				return setNumberOfCharsLeft(maximumCharacterLimit - newValue.length);
			} else {
				return setNumberOfCharsLeft(0);
			}
		};

		return (
			<div id={labelKey} className={'textarea-holder ' + cssClass}>
				{labelKey ? <label className="textarea-label">{t(labelKey) + requiredMark}</label> : null}
				{maximumCharacterLimit && (characterLimitPosition === 'top') && (
					(characterLimitVersion === 1 &&
						<span className="primary-input-hint-text">{' (' + numberOfCharsLeft + ' ' + numberOfCharsText + ')'}</span>
					) || (characterLimitVersion === 2 &&
						<span className="primary-input-hint-text">{(maximumCharacterLimit - numberOfCharsLeft) + '/' + maximumCharacterLimit}</span>
					)
				)}
				<textarea
					className="textarea"
					placeholder={t(placeholder)}
					onChange={(e) => handleChange(e.target.value)}
					disabled={disabled}
					cols={cols}
					rows={rows}
					maxLength={maxLength}
					id={id}
					value={defaultValue}
					onFocus={onFocus}
					onBlur={onBlur}
					ref={ref}
				/>
				{maximumCharacterLimit && characterLimitPosition === 'bottom' && (
					(characterLimitVersion === 1 &&
						<span className="primary-input-hint-text">{' (' + numberOfCharsLeft + ' ' + numberOfCharsText + ')'}</span>
					) || (characterLimitVersion === 2 &&
						<span className="primary-input-hint-text">{(maximumCharacterLimit - numberOfCharsLeft) + '/' + maximumCharacterLimit}</span>
					)
				)}
			</div>
		);
	},
);
