import { useState, useEffect } from 'react';
import { ROUTES } from '../../constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { DateTime } from 'luxon';
import { enableBottomMenu } from '../../store/auth';
import SecondaryHeader from '../Common/Header/SecondaryHeader';
import DaySelection from '../Common/DaySelection/DaySelection';
import { ClipLoader } from 'react-spinners';
import Icon from '../Common/Icon';
import TimeTrackerOverviewItem from './TimeTrackerOverviewItem';
import Button from '../Common/Button';
import { findInRange } from '../../api/timeTracker';
import ChangeRequest from '../Dashboard/TimeTracker/ChangeRequest';
import { loadCedarOptions, loadChangeRequests } from '../../store/timeTracking';
import { enableCard, updateCardAndData } from '../../store/cards';
import { CARD } from '../../constants/cards';
import ContentSwitcher from '../Common/ContentSwitcher/ContentSwitcher';

const TimeTrackerOverview = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeDate, setActiveDate] = useState(DateTime.now());
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [trackedTimeRecords, setTrackedTimeRecords] = useState([]);
  const changeRequests = useSelector((state) => state.cedar.changeRequests);
  const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
  const cedarOptions = useSelector((state) => state.cedar.options);
  const selectedOfficeId = selectedOffice?._id;
  const timezone = selectedOffice?.timezone;
  const [overviewTabs, setOverviewTabs] = useState([
    {
      id: 'work-activities',
      onClick: () => setActiveTab('work-activities'),
      label: `Work Activities (${trackedTimeRecords?.length || 0})`,
    }
  ]);
  const [activeTab, setActiveTab] = useState(overviewTabs[0]?.id);
  useEffect(() => {
    dispatch(enableBottomMenu(false));
    dispatch(loadCedarOptions(selectedOffice?._id));
    dispatch(loadChangeRequests(selectedOffice?._id));
    changeActiveDate(activeDate);
  }, [dispatch, selectedOffice, activeDate]);

  useEffect(() => {
  const tabs = [
      {
        id: 'work-activities',
        onClick: () => setActiveTab('work-activities'),
        label: `Work Activities (${trackedTimeRecords?.length || 0})`,
      }
    ];

    if (cedarOptions?.changeRequestEnabled) {
      tabs.push({
        id: 'requests',
        onClick: () => setActiveTab('requests'),
        label: `Requests (${changeRequests?.length || 0})`,
      });
    }

    setOverviewTabs(tabs);

  }, [changeRequests, trackedTimeRecords, cedarOptions]);

  const previousPage = () => {
    history.goBack();
  };

  const handleActivityButton = () => {
    history.push(ROUTES.TIME_TRACKER.MAIN + ROUTES.TIME_TRACKER.ACTIVITY_NEW, {
      actionSheetData: {
        start: { time: activeDate.toISO() },
        end: { time: activeDate.toISO() },
      },
    });
  };

  const changeActiveDate = async (newActiveDate) => {
    setActiveDate(newActiveDate);
    setLoading(true);
    try {
      const startTime = newActiveDate.startOf('day').setZone(timezone);
      const endTime = newActiveDate.endOf('day').setZone(timezone);
      const timeRecordsRequest = await findInRange({ office: selectedOfficeId, startTime, endTime });
      const timeRecordsData = timeRecordsRequest.data;
      setTrackedTimeRecords(timeRecordsData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(t('errorMessages.general'));
    }
  };
  const handleUpsertCedarRecord = (updatedTimeRecord) => {
    setTrackedTimeRecords((currentTimeRecords) => {
      const recordExists = currentTimeRecords.some((record) => record._id === updatedTimeRecord._id);
      if (!recordExists) {
        //add end time to time record without endtime
        const updatedCurrentTimeRecords = currentTimeRecords.map((record) => {
          if (!record.endTime) {
            return {
              ...record,
              startTime: updatedTimeRecord.startTime,
            };
          }
          return record;
        });
        return [...updatedCurrentTimeRecords, updatedTimeRecord];
      }
      return currentTimeRecords.map((record) => (record._id === updatedTimeRecord._id ? updatedTimeRecord : record));
    });
  };

  const onStopClick = (updatedTimeRecord) => {
    handleUpsertCedarRecord(updatedTimeRecord);
  }

  const handleWorkSummaryBtn = () => {
    dispatch(enableCard(true));
    dispatch(updateCardAndData(CARD.TIME_TRACKING_WORKING_SUMMARY, { trackedTimeRecords }));
  }

  const generateWorkActivitiesContent = () => {
    if (loading) {
      return (
        <div className="time-tracker-overview-content-no-times">
          <ClipLoader size={70} />
        </div>
      );
    }
    const isCurrentAndPastDate = DateTime.fromISO(activeDate) <= DateTime.now().endOf('day').setZone(timezone);
    if (trackedTimeRecords?.length > 0 && isCurrentAndPastDate) {
      return (
        <>
          {trackedTimeRecords.reverse().map((timeRecord) => (
            <TimeTrackerOverviewItem
              key={timeRecord?.id}
              timeRecord={timeRecord}
              stopClicked={(updatedTimeRecord) => onStopClick(updatedTimeRecord)}
            />
          ))}
        </>
      );
    }

    return (
      <div className="time-tracker-overview-content-no-times">
        <Icon iconName={'magnifying-glass'} cssClass={'icon-gray'} />
        <p className="bold">{t('timeTracker.overview.noTimeTrack')}</p>
        <p>{t('timeTracker.overview.noTimeTrackInformation')}</p>
        {error && <p>{error}</p>}
      </div>
    );
  };

  const generateRequestsContent = () => {
    if (loading) {
      return (
        <div className="time-tracker-overview-content-no-times">
          <ClipLoader size={70} />
        </div>
      );
    }

    if (changeRequests?.length > 0 && cedarOptions?.changeRequestEnabled) {
      return (
        <>
          {changeRequests.map((changeRequest) => (
            <ChangeRequest key={changeRequest.id} changeRequest={changeRequest} />
          ))}
        </>
      );
    }

    return (
      <div className="time-tracker-overview-content-no-times">
        <Icon iconName={'magnifying-glass'} cssClass={'icon-gray'} />
        <p className="bold">{t('timeTracker.changeRequest.noChangeRequest')}</p>
        <p>{t('timeTracker.changeRequest.noChangeRequestInformation')}</p>
        {error && <p>{error}</p>}
      </div>
    );
  };

  const showContentOfActiveTab = () => {
    if (activeTab === 'work-activities') {
      return generateWorkActivitiesContent();
    }
    else if (activeTab === 'requests') {
      return generateRequestsContent();
    }
  }

  return (
    <>
      <div className="time-tracker-overview-page">
        <div className="time-tracker-overview-header">
          <SecondaryHeader
            headerTranslationKey={'timeTracker.overview.header'}
            backButtonClickHandler={previousPage}
            disableBoxShadow={true}
          />
          <DaySelection activeDate={activeDate} setActiveDate={changeActiveDate} />
        </div>
        <ContentSwitcher
          cssClass="switcher-container"
          tabsArray={overviewTabs}
          activeTab={activeTab}
          defautlTab={overviewTabs[0]}
        />
        <div className="time-tracker-overview-tabs">

          {showContentOfActiveTab()}
        </div>
      </div>
      <div className="button-fixed-at-bottom-static on-top">
        <Button
          variant={'primary'}
          height={'regular'}
          width={'full'}
          loading={loading}
          translationKey={'timeTracker.workSummary.btnTitle'}
          clickHandler={handleWorkSummaryBtn}
          disabled={DateTime.fromISO(activeDate) > DateTime.now().endOf('day').setZone(timezone)}
        />
        <Button
          variant={'primary'}
          height={'regular'}
          width={'full'}
          loading={loading}
          translationKey={'timeTracker.buttons.add'}
          clickHandler={handleActivityButton}
          disabled={DateTime.fromISO(activeDate) > DateTime.now().endOf('day').setZone(timezone)}
        />
      </div>
    </>
  );
};

export default TimeTrackerOverview;
