import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {CARD} from '../../../constants/cards';
import {enableCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import {reportProblemForTeakEntity} from '../../../api';
import Button from '../../Common/Button';

function ReportProblem(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const data = useSelector((state) => state.cards.data.entity);
	const [value, setValue] = useState('');

	const reportProblem = () => {
		reportProblemForTeakEntity(data._id, value)
			.then((response) => {
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'reportProblem.successText'));
			})
			.catch((error) => {
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'reportProblem.errorText', error));
			});
	};

	const closeCard = () => {
		dispatch(enableCard(false));
	};

	return (
		<div className="card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="report-problem-card">
				<div className="report-problem-text-wrapper">
					<p>{t('reportProblem.label')}</p>
					<textarea
						onChange={(e) => {
							setValue(e.target.value);
						}}
						cols={30}
						rows={12}
						id="outlined-multiline-flexible"></textarea>
				</div>
				<div className="buttons-wrapper">
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						clickHandler={reportProblem}
						translationKey={'reportProblem.submit'}
					/>
				</div>
			</div>
		</div>
	);
}

export default ReportProblem;
