// index.js

import React, { Suspense } from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { DateTime } from 'luxon';
import 'moment/min/locales'; 
import './scss/styles.scss';
import configureStore from './store/configureStore';
import './i18n';
import AppRoutes from './routes/routes';
import { ErrorBoundary } from './ErrorBoundary';
import Icon from './components/Common/Icon';
import { start } from './functions/logging.js'; // Importiere die Logging-Funktionen

const store = configureStore();

window.addEventListener('online', handleConnection);
window.addEventListener('offline', handleConnection);

// Funktion, um zu prüfen, ob der Server erreichbar ist
function isServerReacheable(url) {
	return fetch(url, { method: 'HEAD', mode: 'no-cors' })
		.then((resp) => resp && (resp.ok || resp.type === 'opaque'))
		.catch((err) => {
			console.warn('[connection to server failed]:', err);
		});
}

// Funktion, um die Verbindung zu behandeln
function handleConnection() {
	if (navigator.onLine) {
		isServerReacheable(process.env.REACT_APP_ENGINE_HOST_URL).then((online) => {
			if (online) {
				console.log('online');
				render(
					<Suspense
						fallback={
							<div className="loading-page">
								<div className="loading-logo-wrapper">
									<img
										className="logo"
										alt="logo"
										src={process.env.PUBLIC_URL + '/images/logo_text.svg'}
									/>
								</div>
								<div className="cgi-logo-wrapper">
									<p className="solutionBy">A solution by</p>
									<img
										className="cgi-logo"
										alt="logo"
										src={process.env.PUBLIC_URL + '/images/cgi_logo.svg'}
									/>
								</div>
								<p className="copyright-text">© {DateTime.now().year} Door James. All rights reserved.</p>
							</div>
						}>
						<Provider store={store}>
							<ErrorBoundary>
								<AppRoutes />
							</ErrorBoundary>
						</Provider>
					</Suspense>,
					document.getElementById('root'),
				);
			} else {
				console.log('no-server');
				render(
					<div className='loading-fallback'>
						<Icon iconColor={'#5236AB'} size={70} iconName={'wifi'}></Icon>
						<p>No Server Connection</p>
					</div>,
					document.getElementById('root'),
				);
			}
		});
	} else {
		console.log('offline');
		render(
			<div className='loading-fallback'>
				<Icon iconColor={'#5236AB'} size={70} iconName={'cloud'}></Icon>
				<p>No Network Connection!</p>
			</div>,
			document.getElementById('root'),
		);
	}
}

handleConnection();

// Starte das Logging
start();

// Workaround, um keinen Service Worker zu registrieren, wenn ein Safari-Browser auf einem Mobilgerät verwendet wird
var iOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
if ('serviceWorker' in navigator && !iOS) {
	navigator.serviceWorker
		.register('/sw.js')
		.then((reg) => {
			if (reg.installing) {
				console.log('Service worker installing');
			} else if (reg.waiting) {
				console.log('Service worker installed');
			} else if (reg.active) {
				console.log('Service worker active');
			}
		})
		.catch((error) => {
			// Registrierung fehlgeschlagen
			console.log('Registration failed with ' + error);
		});
}
