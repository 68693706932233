export const errors = {
	CheckInUnavailableError: 'errorMessages.checkInUnavailableError',
	CheckOutUnavailableError: 'errorMessages.checkOutUnavailableError',
	MemberTeakBookingConflictError: 'errorMessages.memberTeakBookingConflictError',
	TeakEntityTeakBookingConflictError: 'errorMessages.teakEntityTeakBookingConflictError',
	UntilNotLargerThanEndError: 'errorMessages.untilNotLargerThanEndError',
	StatusNotCheckedInError: 'errorMessages.statusNotCheckedInError',
	MaxDurationExceededError: 'errorMessages.maxDurationExceededError',
	TeakBookingConflictError: 'errorMessages.teakBookingConflictError',
	StatusMustNotBeCheckedInError: 'errorMessages.statusMustNotBeCheckedInError',
	onlyCheckInAllowed: 'errorMessages.onlyCheckInAllowed',
	invalidStartOrEndTime: 'errorMessages.invalidStartOrEndTime',
	covidCertificateRequired: 'errorMessages.covidCertificateRequired',
	expiredCovidCertificate: 'errorMessages.expiredCovidCertificate',
	invalidPlusTest: 'errorMessages.invalidPlusTest',
	minInAdvanceExceeded: 'errorMessages.minInAdvanceExceeded',
	maxInAdvanceExceeded: 'errorMessages.maxInAdvanceExceeded',
	maxDurationExceeded: 'errorMessages.maxDurationExceeded',
	entityNotAvailableForThisMemberToBook: 'errorMessages.entityNotAvailableForThisMemberToBook',
	conflictsWithOtherBookingsOfMember: {
		header: 'yourBooking.bookingConflictHeader',
		body: 'errorMessages.bookingConflictDateTimeError',
	},
	conflictsWithOtherBookingsOnTeakEntity: {
		header: 'yourBooking.bookingConflictHeader',
		body: 'errorMessages.conflictsWithOtherBookingsOnTeakEntity',
	},
	concurrentBookingsDifferentLocations: {
		header: 'yourBooking.bookingConflictHeader',
		body: 'errorMessages.concurrentBookingsDifferentLocations',
	},
	crisisActive: {
		header: 'yourBooking.oakCrisisCancelledBookingHeader',
		body: 'errorMessages.oakCrisisCancelledBooking',
	},
	commentNotPosted: {
		header: 'settings.reportProblem.myReports.historyItem.commentNotPosted',
		body: 'settings.reportProblem.myReports.historyItem.commentNotPostedInfo',
	},
	externalBookingFailed: {
		header: 'errorMessages.externalBookingFailed.header',
		body: 'errorMessages.externalBookingFailed.body',
	},
};
