
import {forwardRef} from 'react';
/*
variants => added in typoghraphy.scss
display-large
display-medium
display-small
headline-large
headline-medium
headline-small
title-large
title-medium
title-small
label-large
label-medium
label-small
body-large
body-medium
body-small
*/
export const Typography = forwardRef(({variant = 'body-large', className = '', children}, ref) => {
	return <p className={`${variant} ${className}`}>{children}</p>;
});
