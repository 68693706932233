import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router';

import {submitUserFeedback} from '../../../api';
import {postponeUserFeedback} from '../../../api/additionals';
import {CARD} from '../../../constants/cards';
import {ROUTES} from '../../../constants/routes';
import {enableCard, updateCard, updateErrorCard, updateSuccessCard} from '../../../store/cards';
import {enableUserReviewCard} from '../../../store/dashboard';
import Button from '../../Common/Button';
import Icon from '../../Common/Icon';
import PrimaryInput from '../../Common/Inputs/PrimaryInput';
import {Textarea} from '../../Common/Textareas/Textarea';

function UserReview(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const [feedbackText, setFeedbackText] = useState('');
	const [starsRating, setStarsRating] = useState(5);
	const [cssForHiddenDiv, setCssForHiddenDiv] = useState('-hidden'); //the div is used to make the content scrollable when the virtual keyboard is on
	const [followupCheckbox, setFollowUpCheckbox] = useState(false);
	const defaultEmail = useSelector((state) => state.auth.data.userData.email);
	const [emailValue, setEmailValue] = useState(defaultEmail);
	const [noRatingError, setNoRatingError] = useState(false);

	const submitFeedback = () => {
		if (starsRating === 0) {
			setNoRatingError(true);
			return;
		}

		dispatch(enableUserReviewCard(false));
		dispatch(updateCard(CARD.PLACEHOLDER));

		submitUserFeedback(starsRating, feedbackText, emailValue)
			.then((response) => {
				history.push(ROUTES.DEFAULT);
				dispatch(updateSuccessCard(CARD.MAIN_SUCCESS, 'userReviewCard.successSubmission'));
			})
			.catch((error) => {
				history.push(ROUTES.DEFAULT);
				dispatch(updateErrorCard(CARD.MAIN_ERROR, 'userReviewCard.failedSubmission', error));
			});
	};

	const closeCard = () => {
		dispatch(enableUserReviewCard(false));
		postponeUserFeedback();
		history.push(ROUTES.DEFAULT);
		dispatch(enableCard(false));
	};

	let starList = [];

	for (let i = 0; i < 5; i++) {
		const enabled = i < starsRating;
		starList.push(
			<div className="star-item">
				<Icon
					key={i}
					type={'far'}
					iconName={enabled ? 'fas fa-star' : 'far fa-star'}
					cssClass={'icon-purple'}
					clickHandler={() => setStarsRating(i + 1)}
				/>
			</div>,
		);
	}

	document.addEventListener('focusout', () => {
		setCssForHiddenDiv('-hidden');
	});

	const handleFocus = () => {
		setCssForHiddenDiv('');
	};

	const updateCheckbox = () => {
		setFollowUpCheckbox(!followupCheckbox);
	};

	const checkEmptyString = (value) => {
		const whiteSpaceRegex = /\s/g;
		if (value === '') return true;
		return whiteSpaceRegex.test(value);
	};

	const isValidEmail = (value) => {
		const emailRegex = /\S+@\S+\.\S+/;
		return emailRegex.test(value);
	};

	const checkEmailAddress = (value) => {
		if (checkEmptyString(value)) {
			return 'empty';
		} else if (isValidEmail(value)) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<div className="card user-review-card">
			<div className="close-card">
				<span className="close" onClick={closeCard}>
					&times;
				</span>
			</div>
			<div className="user-review-content">
				<p className="success-text header">{t('userReviewCard.header')}</p>
				<div className="stars-wrapper">{starList}</div>
				<div className="feedback-input">
					<Textarea
						placeholder={'userReviewCard.placeholder'}
						cols="30"
						rows="10"
						id="description"
						changeHandler={setFeedbackText}
						onFocus={handleFocus}
					/>
				</div>
				<div className="followup-allowed-check">
					<input className="input-checkbox" type="checkbox" id={'followup-checkbox'} value={followupCheckbox} onChange={updateCheckbox} />
					<label className="input-checkbox-label" htmlFor={'followup-checkbox'}>
						{t('userReviewCard.checkboxLabel')}
					</label>
				</div>
				{followupCheckbox ? (
					<PrimaryInput
						cssClass={' email-wrapper'}
						placeholderKey={t('common.inputPlaceholder.email')}
						clickHandler={(value) => setEmailValue(value)}
						defaultValue={emailValue}
						inputCheck={(value) => checkEmailAddress(value)}
						errorMessage={'errorMessages.invalidEmail'}
						required={true}
						handleFocus={handleFocus}
					/>
				) : null}
				<div className="buttons-wrapper">
					{noRatingError && <p className="no-rating">{t('userReviewCard.noRatingError')}</p>}
					<Button
						variant={'primary'}
						height={'regular'}
						width={'full'}
						disabled={(followupCheckbox === true && (emailValue === '' || !checkEmailAddress(emailValue))) || noRatingError}
						clickHandler={submitFeedback}
						translationKey={'userReviewCard.submitButton'}
					/>
				</div>
			</div>
			<div className={'placeholderForVirtualKeyboard' + cssForHiddenDiv}></div>
		</div>
	);
}

export default UserReview;
