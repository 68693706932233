/**
 *
 * @param {string} title = text to be show in the title
 * @param {string} className = css class
 * @returns Simple React component that holds the common section title
 */
function SectionTitle({title, className = ''}) {
	return <p className={`title ${className}`}>{title}</p>;
}

export default SectionTitle;
