import { createSlice } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
import { getNextBookingsAndCheckins } from '../functions/dashboard';
import { findMyColleagueCalendarView, findOtherColleaguesCalendarView } from '../api/additionals';

const slice = createSlice({
	name: 'dashboard',
	initialState: {
		data: {
			nextBookings: null,
			currentCheckins: null,
			lastFetch: null,
			badgeCode: null,
			questionnaireData: null,
			notificationSource: null,
			validCeylon: null,
			validCeylonUntil: null,
			expiredCeylonState: false,
			plusStatusLastCompleted: null,
			colleagueListForSpecificDate: null,
			otherColleagueListForSpecificDate: null,
			activeDateFindColleagueCalendar: DateTime.now().toISO(),
			skipOtherColleageus: 0,
		},
		ui: {
			showLoadingUI: null,
			showBadgeModal: false,
			showQuestionnairePage: false,
			showPurpleBar: false,
			enableHQ: false,
			showUserReviewCard: false,
			colleagueListSpecificDateLoading: false,
			hasMoreOtherColleagues: true,
		},
	},
	reducers: {
		dashboardDataReceived: (dashboard, action) => {
			dashboard.data.nextBookings = action.payload.nextBookings;
			dashboard.data.currentCheckins = action.payload.currentCheckins;
			dashboard.data.lastFetch = action.payload.lastFetch;
		},
		showQuestionnairePageReceived: (dashboard, action) => {
			dashboard.ui.showQuestionnairePage = action.payload.show;
			dashboard.data.questionnaireData = action.payload.data;
			dashboard.data.notificationSource = action.payload.source;
		},
		showPurpleBarReceived: (dashboard, action) => {
			dashboard.ui.showPurpleBar = action.payload;
		},
		enableHQReceived: (dashboard, action) => {
			dashboard.ui.enableHQ = action.payload;
		},
		showBadgeModalReceived: (dashboard, action) => {
			dashboard.ui.showBadgeModal = action.payload;
		},
		badgeCodeReceived: (dashboard, action) => {
			dashboard.data.badgeCode = action.payload;
		},
		showLoadingUIReceived: (dashboard, action) => {
			dashboard.ui.showLoadingUI = action.payload;
		},
		showUserReviewCardChanged: (dashboard, action) => {
			dashboard.ui.showUserReviewCard = action.payload;
		},
		errorReceived: (dashboard, action) => {
			dashboard.errorMessage = action.payload.message;
		},
		validCeylonReceived: (dashboard, action) => {
			dashboard.data.validCeylon = action.payload;
		},
		validCeylonUntilReceived: (dashboard, action) => {
			dashboard.data.validCeylonUntil = action.payload;
		},
		expiredCeylonStateReceived: (dashboard, action) => {
			dashboard.data.expiredCeylonState = action.payload;
		},
		nextBookingReceived: (dashboard, action) => {
			dashboard.data.nextBooking = action.payload;
		},
		plusStatusLastCompletedReceived: (dashboard, action) => {
			dashboard.data.plusStatusLastCompleted = action.payload;
		},
		colleagueListForSpecificDateReceived: (dashboard, action) => {
			dashboard.data.colleagueListForSpecificDate = action.payload;
		},
		colleagueListSpecificDateLoadingReceived: (dashboard, action) => {
			dashboard.ui.colleagueListSpecificDateLoading = action.payload;
		},
		otherColleagueListForSpecificDateReceived: (dashboard, action) => {
			dashboard.data.otherColleagueListForSpecificDate = action.payload.colleagues;
			dashboard.data.skipOtherColleageus = action.payload.skip;
		},
		activeDateFindColleagueCalendarChanged: (dashboard, action) => {
			dashboard.data.activeDateFindColleagueCalendar = action.payload;
		},
		hasMoreOtherColleaguesReceived: (dashboard, action) => {
			dashboard.ui.hasMoreOtherColleagues = action.payload;
		},
	},
});

export const {
	dashboardDataReceived,
	showBadgeModalReceived,
	showQuestionnairePageReceived,
	badgeCodeReceived,
	showLoadingUIReceived,
	showPurpleBarReceived,
	enableHQReceived,
	showUserReviewCardChanged,
	errorReceived,
	validCeylonReceived,
	validCeylonUntilReceived,
	expiredCeylonStateReceived,
	nextBookingReceived,
	plusStatusLastCompletedReceived,
	colleagueListForSpecificDateReceived,
	otherColleagueListForSpecificDateReceived,
	colleagueListSpecificDateLoadingReceived,
	activeDateFindColleagueCalendarChanged,
	hasMoreOtherColleaguesReceived,
} = slice.actions;

export default slice.reducer;

export const loadDashboardData = (forceReload) => async (dispatch, getState) => {
	if (!forceReload) {
		const lastFetchOfData = getState().dashboard.data.lastFetch;
		if (lastFetchOfData && DateTime.now().toUTC() < DateTime.fromISO(lastFetchOfData).toUTC().plus({ minutes: 1 })) return;
	}

	dispatch({ type: showLoadingUIReceived.type, payload: true });
	try {
		const authData = getState().auth.data;
		const validCeylon = getState().dashboard.data.validCeylon;
		const { nextBookings, currentCheckins } = await getNextBookingsAndCheckins(authData, dispatch, validCeylon);
		const lastFetch = DateTime.now().toISO();
		dispatch({
			type: dashboardDataReceived.type,
			payload: {
				nextBookings,
				currentCheckins,
				lastFetch,
			},
		});
		dispatch({ type: showLoadingUIReceived.type, payload: false });
	} catch (error) {
		dispatch({ type: errorReceived.type, payload: error.message });
		dispatch({ type: showLoadingUIReceived.type, payload: false });
	}
};

export const enableHQPurpleBar = (show) => async (dispatch, getState) => {
	if (show === getState().dashboard.ui.enableHQ) return;
	dispatch({ type: enableHQReceived.type, payload: show });
};

export const enableBadgeModal = (show) => async (dispatch, getState) => {
	if (show === getState().dashboard.ui.showBadgeModal) return;
	dispatch({ type: showBadgeModalReceived.type, payload: show });
};

export const enableQuestionnairePage = (show, data, source) => async (dispatch, getState) => {
	if (show === getState().dashboard.ui.showQuestionnairePage && data === getState().dashboard.data.questionnaireData) return;

	dispatch({
		type: showQuestionnairePageReceived.type,
		payload: { show, data, source },
	});
};

export const enablePurpleBar = (show) => async (dispatch, getState) => {
	if (show === getState().dashboard.ui.showPurpleBar) return;

	dispatch({ type: showPurpleBarReceived.type, payload: show });
};

export const addBadgeCode = (code) => async (dispatch, getState) => {
	const currentCode = getState().dashboard.data.badgeCode;

	if (currentCode === code) return;

	dispatch({ type: badgeCodeReceived.type, payload: code });
};

export const enableUserReviewCard = (show) => async (dispatch, getState) => {
	if (getState().dashboard.ui.showUserReviewCard === show) return;

	dispatch({ type: showUserReviewCardChanged.type, payload: show });
};

export const updateValidCeylon = (valid) => async (dispatch, getState) => {
	if (getState().dashboard.data.validCeylon === valid) return;

	dispatch({ type: validCeylonReceived.type, payload: valid });
};

export const updateValidCeylonUntil = (date) => async (dispatch, getState) => {
	if (getState().dashboard.data.validCeylonUntil === date) return;

	dispatch({ type: validCeylonUntilReceived.type, payload: date });
};

export const updateExpiredCeylonState = (state) => async (dispatch, getState) => {
	if (getState().dashboard.data.expiredCeylonState === state) return;

	dispatch({ type: expiredCeylonStateReceived.type, payload: state });
};

export const updatePlusStatus = (lastCompleted) => async (dispatch, getState) => {
	dispatch({
		type: plusStatusLastCompletedReceived.type,
		payload: lastCompleted,
	});
};

export const updateColleagueListForSpecificDate = (date, office) => async (dispatch, getState) => {
	dispatch({
		type: colleagueListSpecificDateLoadingReceived.type,
		payload: true,
	});
	const selectedOffice = office ?? getState().auth.data.selectedOffice;

	try {
		let startTimeRange = date.startOf('day');
		if (startTimeRange.toLocaleString(DateTime.DATE_SIMPLE) === DateTime.now().toLocaleString(DateTime.DATE_SIMPLE)) {
			startTimeRange = DateTime.now().toUTC().toISO();
		}
		const endTimeRange = date.endOf('day').toUTC().toISO();
		const colleaguesResponse = await findMyColleagueCalendarView(startTimeRange, endTimeRange, selectedOffice.id);

		const colleaguesForSpecificDate = colleaguesResponse.data.filter((colleagueObj) => colleagueObj?.bookings?.length > 0);

		dispatch({
			type: colleagueListForSpecificDateReceived.type,
			payload: colleaguesForSpecificDate,
		});
		dispatch({
			type: colleagueListSpecificDateLoadingReceived.type,
			payload: false,
		});
	} catch (error) {
		console.log(error);
		dispatch({
			type: colleagueListForSpecificDateReceived.type,
			payload: [],
		});
		dispatch({
			type: colleagueListSpecificDateLoadingReceived.type,
			payload: false,
		});
	}
};
export const updateOtherColleagueListForSpecificDate = (date, resetSkip, office) => async (dispatch, getState) => {
	const selectedOffice = office ?? getState().auth.data.selectedOffice;
	const skip = resetSkip ? 0 : getState().dashboard.data.skipOtherColleageus;
	const currentOtherColleagues = getState().dashboard.data.otherColleagueListForSpecificDate;
	const pageSize = 10;

	try {
		let startTimeRange = date.startOf('day');
		if (startTimeRange.toLocaleString(DateTime.DATE_SIMPLE) === DateTime.now().toLocaleString(DateTime.DATE_SIMPLE)) {
			startTimeRange = DateTime.now().toUTC().toISO();
		}
		const endTimeRange = date.endOf('day').toUTC().toISO();
		const colleaguesResponse = await findOtherColleaguesCalendarView(startTimeRange, endTimeRange, selectedOffice.id, skip, pageSize);
		dispatch({ type: hasMoreOtherColleaguesReceived.type, payload: colleaguesResponse?.data?.length > pageSize - 1 });

		const colleaguesForSpecificDate = colleaguesResponse.data;

		const newOtherColleagues = skip > 0 ? [...currentOtherColleagues, ...colleaguesForSpecificDate] : colleaguesForSpecificDate;
		dispatch({
			type: otherColleagueListForSpecificDateReceived.type,
			payload: { colleagues: newOtherColleagues, skip: skip + pageSize },
		});
	} catch (error) {
		console.log(error);
	}
};
export const changeActiveDateForFindColleagueCalendar = (activeDate) => (dispatch, getState) => {
	if (activeDate.toISO() === getState().dashboard.data.activeDateFindColleagueCalendar) return;
	dispatch(updateColleagueListForSpecificDate(activeDate));
	dispatch(updateOtherColleagueListForSpecificDate(activeDate, true));
	dispatch({
		type: activeDateFindColleagueCalendarChanged.type,
		payload: activeDate.toISO(),
	});
};
//selectors
