export const teakFeatures = {
    "monitor": {
      "iconName": "display",
      "translation": {
        "EN": "Monitor/Display",
        "DE": "Monitor/Anzeige",
        "FR": "Moniteur/Écran d'ordinateur",
        "IT": "Monitor/display",
        "NL": "Monitor/Display",
        "PT": "Monitor"
      }
    },
    "dualMonitor": {
      "iconName": "display",
      "translation": {
        "EN": "Dual Monitor",
        "DE": "Dualer Monitor",
        "FR": "Double écran",
        "IT": "Doppio monitor",
        "NL": "Dubbele monitor",
        "PT": "Monitor duplo"
      }
    },
    "keyboard": {
      "iconName": "keyboard",
      "translation": {
        "EN": "Keyboard",
        "DE": "Tastatur",
        "FR": "Clavier",
        "IT": "Tastiera",
        "NL": "Toetsenbord",
        "PT": "Teclado"
      }
    },
    "charging": {
      "iconName": "charging",
      "translation": {
        "EN": "Charging",
        "DE": "Aufladung",
        "FR": "Chargement",
        "IT": "Ricarica",
        "NL": "Opladen",
        "PT": "Carregamento"
      }
    },
    "phone": {
      "iconName": "phone",
      "translation": {
        "EN": "Phone",
        "DE": "Telefon",
        "FR": "Téléphone",
        "IT": "Telefono",
        "NL": "Telefoon",
        "PT": "Telefone"
      }
    },
    "conferencePhone": {
      "iconName": "phone",
      "translation": {
        "EN": "Conf/speaker phone",
        "DE": "Freisprecheinrichtung",
        "FR": "Téléphone de conférence/parleur",
        "IT": "Telefono per conferenze/altoparlanti",
        "NL": "Telefoon voor conferentie/luidspreker",
        "PT": "Telefone de conferência/porta-voz"
      }
    },
    "adjustableHeightDesk": {
      "iconName": "phone",
      "translation": {
        "EN": "Adjustable height-desk",
        "DE": "Höhenverstellbarer Schreibtisch",
        "FR": "Bureau à hauteur réglable",
        "IT": "Scrivania ad altezza regolabile",
        "NL": "Verstelbare hoogte-bureau",
        "PT": "Mesa ajustável em altura"
      }
    },
    "whiteboard": {
      "iconName": "whiteboard",
      "translation": {
        "EN": "Whiteboard",
        "DE": "Whiteboard",
        "FR": "Tableau blanc",
        "IT": "Lavagna bianca",
        "NL": "Whiteboard",
        "PT": "Quadro branco"
      }
    },
    "wifi": {
      "iconName": "wifi",
      "translation": {
        "EN": "Wifi",
        "DE": "Wifi",
        "FR": "Wifi",
        "IT": "Wifi",
        "NL": "Wifi",
        "PT": "Wifi"
      }
    },
    "ethernet": {
      "iconName": "ethernet",
      "translation": {
        "EN": "Ethernet",
        "DE": "Ethernet",
        "FR": "Ethernet",
        "IT": "Ethernet",
        "NL": "Ethernet",
        "PT": "Ethernet"
      }
    },
    "hdmi": {
      "iconName": "hdmiCables",
      "translation": {
        "EN": "HDMI",
        "DE": "HDMI",
        "FR": "HDMI",
        "IT": "HDMI",
        "NL": "HDMI",
        "PT": "HDMI"
      }
    },
    "usbC": {
      "iconName": "type1",
      "translation": {
        "EN": "USB-C",
        "DE": "USB-C",
        "FR": "USB-C",
        "IT": "USB-C",
        "NL": "USB-C",
        "PT": "USB-C"
      }
    },
    "presentation": {
      "iconName": "presentation",
      "translation": {
        "EN": "Presentation",
        "DE": "Präsentation",
        "FR": "Présentation",
        "IT": "Presentazione",
        "NL": "Presentatie",
        "PT": "Apresentação"
      }
    },
    "printer": {
      "iconName": "printer",
      "translation": {
        "EN": "Printer",
        "DE": "Drucker",
        "FR": "Imprimante",
        "IT": "Stampante",
        "NL": "Printer",
        "PT": "Impressora"
      }
    },
    "webcam": {
      "iconName": "webcam",
      "translation": {
        "EN": "Webcam",
        "DE": "Webkamera",
        "FR": "Webcaméra",
        "IT": "Telecamera web",
        "NL": "Webcam",
        "PT": "Câmara Web"
      }
    },
    "vga": {
      "iconName": "hdmiCables",
      "translation": {
        "EN": "VGA",
        "DE": "VGA",
        "FR": "VGA",
        "IT": "VGA",
        "NL": "VGA",
        "PT": "VGA"
      }
    },
    "scanner": {
      "iconName": "scanner",
      "translation": {
        "EN": "Scanner",
        "DE": "Scanner",
        "FR": "Scanner",
        "IT": "Scanner",
        "NL": "Scanner",
        "PT": "Scanner"
      }
    },
    "speakers": {
      "iconName": "speakers",
      "translation": {
        "EN": "Speaker",
        "DE": "Lautsprecher",
        "FR": "Haut-parleur",
        "IT": "Altoparlante",
        "NL": "Spreker",
        "PT": "auto-falante"
      }
    },
    "paperSchredder": {
      "iconName": "paperShredder",
      "translation": {
        "EN": "Paper Shredder",
        "DE": "Aktenvernichter",
        "FR": "Déchiqueteuse de papier",
        "IT": "Tritacarte",
        "NL": "Papiervernietiger",
        "PT": "Fragmentadora de papel"
      }
    },
    "jabraSpeaker": {
      "iconName": "jabraSpeaker",
      "translation": {
        "EN": "Jabra Speaker",
        "DE": "Jabra-Lautsprecher",
        "FR": "Haut-parleur Jabra",
        "IT": "Altoparlante Jabra",
        "NL": "Jabra Luidspreker",
        "PT": "auto-falante Jabra"
      }
    },
    "projector": {
      "iconName": "projector",
      "translation": {
        "EN": "Projector",
        "DE": "Projektor",
        "FR": "Projecteur",
        "IT": "Proiettore",
        "NL": "Projector",
        "PT": "Projector"
      }
    },
    "eChargers": {
      "iconName": "eChargers",
      "translation": {
        "EN": "E-Charger",
        "DE": "E-Ladestation",
        "FR": "Chargeur électronique",
        "IT": "Caricatore elettronico",
        "NL": "E-lader",
        "PT": "E-Charger"
      }
    },
    "type1": {
      "iconName": "type1",
      "translation": {
        "EN": "Type 1",
        "DE": "Typ 1",
        "FR": "Type 1",
        "IT": "Tipo 1",
        "NL": "Type 1",
        "PT": "Tipo 1"
      }
    },
    "type2": {
      "iconName": "type2",
      "translation": {
        "EN": "Type 2",
        "DE": "Typ 2",
        "FR": "Type 2",
        "IT": "Tipo 2",
        "NL": "Type 2",
        "PT": "Tipo 2"
      }
    },
    "commando": {
      "iconName": "commando",
      "translation": {
        "EN": "Commando",
        "DE": "Commando",
        "FR": "Commando",
        "IT": "Commando",
        "NL": "Commando",
        "PT": "Commando"
      }
    },
    "cc2": {
      "iconName": "cc2",
      "translation": {
        "EN": "CC2",
        "DE": "CC2",
        "FR": "CC2",
        "IT": "CC2",
        "NL": "CC2",
        "PT": "CC2"
      }
    },
    "CHAdeMO": {
      "iconName": "CHAdeMO",
      "translation": {
        "EN": "CHAdeMO",
        "DE": "CHAdeMO",
        "FR": "CHAdeMO",
        "IT": "CHAdeMO",
        "NL": "CHAdeMO",
        "PT": "CHAdeMO"
      }
    },
    "headset": {
      "iconName": "headset",
      "translation": {
        "EN": "Headset",
        "DE": "Headset",
        "FR": "Casque d'écoute",
        "IT": "Cuffia",
        "NL": "Headset",
        "PT": "Fones de ouvido"
      }
    },
    "volumeAdapter": {
      "iconName": "volumeAdapter",
      "translation": {
        "EN": "Volume adapter",
        "DE": "Lautstärke-Adapter",
        "FR": "Adaptateur de volume",
        "IT": "Adattatore di volume",
        "NL": "Volume adapter",
        "PT": "Adaptador de volume"
      }
    },
    "polycom": {
      "iconName": "polycom",
      "translation": {
        "EN": "Polycom",
        "DE": "Polycom",
        "FR": "Polycom",
        "IT": "Polycom",
        "NL": "Polycom",
        "PT": "Polycom"
      }
    },
    "adjustableHeightMonitor": {
      "iconName": "display",
      "translation": {
        "EN": "Adjustable height-monitor",
        "DE": "Höhenverstellbarer Monitor",
        "FR": "Moniteur à hauteur réglable",
        "IT": "Monitor regolabile in altezza",
        "NL": "Verstelbare hoogte-monitor",
        "PT": "Monitor de altura ajustável"
      }
    },
    "tv": {
      "iconName": "display",
      "translation": {
        "EN": "TV",
        "DE": "TV",
        "FR": "TV",
        "IT": "TV",
        "NL": "TV",
        "PT": "TV"
      }
    },
    "macMonitor": {
      "iconName": "display",
      "translation": {
        "EN": "Mac Monitor",
        "DE": "Mac Monitor",
        "FR": "Moniteur Mac",
        "IT": "Monitor Mac",
        "NL": "Mac Monitor",
        "PT": "Monitor Mac"
      }
    },
    "ac": {
      "iconName": "ac",
      "translation": {
        "EN": "AC",
        "DE": "Klimaanlage",
        "FR": "Climatisation",
        "IT": "Climatizzazione dell'aria",
        "NL": "AC",
        "PT": "Ar condicionado"
      }
    },
    "wc": {
      "iconName": "toilet",
      "translation": {
        "EN": "WC",
        "DE": "WC",
        "FR": "WC",
        "IT": "WC",
        "NL": "WC",
        "PT": "WC"
      }
    },
    "clock": {
      "iconName": "clock",
      "translation": {
        "EN": "Clock",
        "DE": "Uhr",
        "FR": "Horloge",
        "IT": "Orologio",
        "NL": "Klok",
        "PT": "Relógio"
      }
    },
    "drawer": {
      "iconName": "drawer",
      "translation": {
        "EN": "Drawer",
        "DE": "Kommode",
        "FR": "Tiroir",
        "IT": "Cassetto",
        "NL": "Lade",
        "PT": "Gaveta"
      }
    },
    "lamp": {
      "iconName": "lamp",
      "translation": {
        "EN": "Lamp",
        "DE": "Lampe",
        "FR": "Lampe",
        "IT": "Lampada",
        "NL": "Lamp",
        "PT": "Lâmpada"
      }
    },
    "phoneBox": {
      "iconName": "phoneBox",
      "translation": {
        "EN": "Phone box",
        "DE": "Telefonzelle",
        "FR": "Cabine téléphonique",
        "IT": "Box telefonico",
        "NL": "Telefooncel",
        "PT": "Caixa telefónica"
      }
    },
    "boothSpace": {
      "iconName": "phoneBox",
      "translation": {
        "EN": "Booth Space",
        "DE": "Kabinenplatz",
        "FR": "Espace stand",
        "IT": "Spazio per la cabina",
        "NL": "Standruimte",
        "PT": "Cabina"
      }
    },
    "cleaningUtensils": {
      "iconName": "cleaning",
      "translation": {
        "EN": "Cleaning utensils",
        "DE": "Reinigungsutensilien",
        "FR": "Ustensiles de nettoyage",
        "IT": "Utensili per la pulizia",
        "NL": "Schoonmaakgerei",
        "PT": "Utensílios de limpeza"
      }
    },
    "vacuumCleaner": {
      "iconName": "vacuumCleaner",
      "translation": {
        "EN": "Vacuum Cleaner",
        "DE": "Staubsauger",
        "FR": "Aspirateur",
        "IT": "Aspirapolvere",
        "NL": "Stofzuiger",
        "PT": "Aspirador"
      }
    },
    "mailRoom": {
      "iconName": "mailRoom",
      "translation": {
        "EN": "Mail Room",
        "DE": "Poststelle",
        "FR": "Salle du courrier",
        "IT": "Sala della posta",
        "NL": "Postkamer",
        "PT": "Sala do correio"
      }
    },
    "workshopSupplies": {
      "iconName": "mailRoom",
      "translation": {
        "EN": "Workshop Supplies",
        "DE": "Workshop-Zubehör",
        "FR": "Fournitures d'atelier",
        "IT": "Forniture per l'officina",
        "NL": "Werkplaatsbenodigdheden",
        "PT": "Materiais para Oficinas"
      }
    },
    "flipchart": {
      "iconName": "mailRoom",
      "translation": {
        "EN": "Flipchart",
        "DE": "Flipchart",
        "FR": "Tableau à feuilles mobiles",
        "IT": "Lavagna a fogli mobili",
        "NL": "Flipchart",
        "PT": "Flipchart"
      }
    },
    "papers": {
      "iconName": "papers",
      "translation": {
        "EN": "Paper",
        "DE": "Papier",
        "FR": "Papier",
        "IT": "Carta",
        "NL": "Papier",
        "PT": "Papel"
      }
    },
    "scissors": {
      "iconName": "scissors",
      "translation": {
        "EN": "Scissor",
        "DE": "Schere",
        "FR": "Ciseaux",
        "IT": "Forbice",
        "NL": "Schaar",
        "PT": "Tesoura"
      }
    },
    "pens": {
      "iconName": "pens",
      "translation": {
        "EN": "Pen",
        "DE": "Stift",
        "FR": "Stylo",
        "IT": "Penna",
        "NL": "Pen",
        "PT": "Caneta"
      }
    },
    "notebooks": {
      "iconName": "notebooks",
      "translation": {
        "EN": "Notebook",
        "DE": "Notizbuch",
        "FR": "Carnet de notes",
        "IT": "Taccuino",
        "NL": "Notebook",
        "PT": "Caderno de notas"
      }
    },
    "elevator": {
      "iconName": "elevator",
      "translation": {
        "EN": "Elevator",
        "DE": "Aufzug",
        "FR": "Ascenseur",
        "IT": "Ascensore",
        "NL": "Lift",
        "PT": "Elevador"
      }
    },
    "touchdown": {
      "iconName": "touchdown",
      "translation": {
        "EN": "Touchdown",
        "DE": "Touchdown",
        "FR": "Touchdown",
        "IT": "Touchdown",
        "NL": "Touchdown",
        "PT": "Touchdown"
      }
    },
    "meetingRoom": {
      "iconName": "TeakTypeMeetingRoom",
      "translation": {
        "EN": "Meeting/Conference Room",
        "DE": "Besprechungsraum",
        "FR": "Salle de réunion/conférence",
        "IT": "Sala riunioni/conferenze",
        "NL": "Vergadering/conferentiezaal",
        "PT": "Sala de reuniões/conferências"
      }
    },
    "vcRoom": {
      "iconName": "vcRoom",
      "translation": {
        "EN": "VC Room",
        "DE": "VC Raum",
        "FR": "Salle VC",
        "IT": "Sala VC",
        "NL": "VC kamer",
        "PT": "Sala VC"
      }
    },
    "collaborationSpace": {
      "iconName": "collaborationSpace",
      "translation": {
        "EN": "Collaboration Space",
        "DE": "Kollaborationsraum",
        "FR": "Espace de collaboration",
        "IT": "Spazio di collaborazione",
        "NL": "Samenwerkingsruimte",
        "PT": "Espaço de Colaboração"
      }
    },
    "itServiceRoom": {
      "iconName": "itServiceRoom",
      "translation": {
        "EN": "IT Service Room",
        "DE": "IT-Service-Raum",
        "FR": "Salle de service informatique",
        "IT": "Sala servizi IT",
        "NL": "IT-servicekamer",
        "PT": "Sala de Serviço de TI"
      }
    },
    "entertainmentRoom": {
      "iconName": "entertainmentRoom",
      "translation": {
        "EN": "Entertainment Room",
        "DE": "Unterhaltungsraum",
        "FR": "Salle de divertissement",
        "IT": "Sala di intrattenimento",
        "NL": "Entertainment kamer",
        "PT": "Sala de Entretenimento"
      }
    },
    "loungeArea": {
      "iconName": "lounge",
      "translation": {
        "EN": "Lounge Area",
        "DE": "Lounge-Bereich",
        "FR": "Espace salon",
        "IT": "Area lounge",
        "NL": "Zitkamer",
        "PT": "Área de Lounge"
      }
    },
    "storageRoom": {
      "iconName": "lounge",
      "translation": {
        "EN": "Storage Room",
        "DE": "Abstellraum",
        "FR": "Salle de stockage",
        "IT": "Ripostiglio",
        "NL": "Opslagruimte",
        "PT": "Armazém"
      }
    },
    "silentRoom": {
      "iconName": "silentRoom",
      "translation": {
        "EN": "Silent Room",
        "DE": "Ruheraum",
        "FR": "Salle silencieuse",
        "IT": "Camera silenziosa",
        "NL": "Stille Kamer",
        "PT": "Sala Silenciosa"
      }
    },
    "cabin": {
      "iconName": "cabin",
      "translation": {
        "EN": "Cabin",
        "DE": "Kabine",
        "FR": "Cabine",
        "IT": "Cabina",
        "NL": "Kajuit",
        "PT": "Cabine"
      }
    },
    "library": {
      "iconName": "library",
      "translation": {
        "EN": "Library",
        "DE": "Bibliothek",
        "FR": "Bibliothèque",
        "IT": "Biblioteca",
        "NL": "Bibliotheek",
        "PT": "Biblioteca"
      }
    },
    "desk": {
      "iconName": "TeakTypeWorkplace",
      "translation": {
        "EN": "Desk",
        "DE": "Schreibtisch",
        "FR": "Bureau",
        "IT": "Scrivania",
        "NL": "Bureau",
        "PT": "Secretária"
      }
    },
    "chair": {
      "iconName": "chair",
      "translation": {
        "EN": "Chair",
        "DE": "Stuhl",
        "FR": "Chaise",
        "IT": "Sedia",
        "NL": "Stoel",
        "PT": "Cadeira"
      }
    },
    "footRest": {
      "iconName": "footRest",
      "translation": {
        "EN": "Foot rest",
        "DE": "Fußstütze",
        "FR": "Repose-pieds",
        "IT": "Poggiapiedi",
        "NL": "Voetsteun",
        "PT": "Descanso para os pés"
      }
    },
    "fireExtinguisher": {
      "iconName": "fireExtinguisher",
      "translation": {
        "EN": "Fire Extinguisher",
        "DE": "Feuerlöscher",
        "FR": "Extincteur d'incendie",
        "IT": "Estintore",
        "NL": "Brandblusser",
        "PT": "Extintor de incêndio"
      }
    },
    "fireSafety": {
      "iconName": "fireSafety",
      "translation": {
        "EN": "Fire Safety",
        "DE": "Brandsicherheit",
        "FR": "Sécurité incendie",
        "IT": "Sicurezza antincendio",
        "NL": "Brandveiligheid",
        "PT": "Segurança contra Incêndios"
      }
    },
    "emergencyExit": {
      "iconName": "nearestFireExit",
      "translation": {
        "EN": "Emergency Exit",
        "DE": "Notausgang",
        "FR": "Sortie de secours",
        "IT": "Uscita di emergenza",
        "NL": "Nooduitgang",
        "PT": "Saída de emergência"
      }
    },
    "firstAidRoom": {
      "iconName": "firstAid",
      "translation": {
        "EN": "First Aid Room",
        "DE": "Erste-Hilfe-Raum",
        "FR": "Salle de premiers secours",
        "IT": "Sala di primo soccorso",
        "NL": "Eerste Hulp Kamer",
        "PT": "Sala de Primeiros Socorros"
      }
    },
    "firstAidKit": {
      "iconName": "firstAidKit",
      "translation": {
        "EN": "First Aid Kit",
        "DE": "Erste-Hilfe-Kasten",
        "FR": "Kit de premiers secours",
        "IT": "Kit di primo soccorso",
        "NL": "Eerste Hulp Kit",
        "PT": "Kit de Primeiros Socorros"
      }
    },
    "heartStarter": {
      "iconName": "heartStarter",
      "translation": {
        "EN": "Heart Starter",
        "DE": "Herz-Defibrillator",
        "FR": "Défibrillateur",
        "IT": "Defibrillatore",
        "NL": "Hart Starter",
        "PT": "Arrancador cardíaco"
      }
    },
    "daylight": {
      "iconName": "daylight",
      "translation": {
        "EN": "Daylight",
        "DE": "Tageslicht",
        "FR": "Lumière du jour",
        "IT": "Luce del giorno",
        "NL": "Daglicht",
        "PT": "Luz do dia"
      }
    },
    "quiet": {
      "iconName": "quiet",
      "translation": {
        "EN": "Quiet",
        "DE": "Ruhig",
        "FR": "Calme",
        "IT": "Tranquillo",
        "NL": "Quiet",
        "PT": "Silêncio"
      }
    },
    "accessible": {
      "iconName": "accessible",
      "translation": {
        "EN": "ADA/for wheelchairs/Accessible",
        "DE": "ADA/für Rollstühle/zugänglich",
        "FR": "ADA/Pour les fauteuils roulants/Accessible",
        "IT": "ADA/per sedie a rotelle/accessibile",
        "NL": "ADA/voor rolstoelen/toegankelijk",
        "PT": "ADA/para cadeiras de rodas/acessível"
      }
    },
    "s": {
      "iconName": "s",
      "translation": {
        "EN": "S",
        "DE": "S",
        "FR": "S",
        "IT": "S",
        "NL": "S",
        "PT": "S"
      }
    },
    "m": {
      "iconName": "m",
      "translation": {
        "EN": "M",
        "DE": "M",
        "FR": "M",
        "IT": "M",
        "NL": "M",
        "PT": "M"
      }
    },
    "l": {
      "iconName": "l",
      "translation": {
        "EN": "L",
        "DE": "L",
        "FR": "L",
        "IT": "L",
        "NL": "L",
        "PT": "L"
      }
    },
    "default": {
      "iconName": "default",
      "translation": {
        "EN": "Default",
        "DE": "Standard",
        "FR": "Défaut",
        "IT": "Predefinito",
        "NL": "Standaard",
        "PT": "Predefinição"
      }
    },
    "guestSeating": {
      "iconName": "loungeSeat",
      "translation": {
        "EN": "Guest seating",
        "DE": "Sitzplätze für Gäste",
        "FR": "Sièges pour les invités",
        "IT": "Posti a sedere per gli ospiti",
        "NL": "Gast zitplaatsen",
        "PT": "Lugares de hóspedes"
      }
    },
    "motorcycle": {
      "iconName": "motorcycle",
      "translation": {
        "EN": "Motorcycle",
        "DE": "Motorrad",
        "FR": "Moto",
        "IT": "Moto",
        "NL": "Motorfiets",
        "PT": "Motocicleta"
      }
    },
    "info": {
      "iconName": "info",
      "translation": {
        "EN": "Info",
        "DE": "Information",
        "FR": "Information",
        "IT": "Informazioni",
        "NL": "Infomatie",
        "PT": "Informação"
      }
    },
    "reception": {
      "iconName": "reception",
      "translation": {
        "EN": "Reception",
        "DE": "Rezeption",
        "FR": "Réception",
        "IT": "Ricezione",
        "NL": "Receptie",
        "PT": "Recepção"
      }
    },
    "kidsCare": {
      "iconName": "kidsCare",
      "translation": {
        "EN": "Kids Care",
        "DE": "Kinderbetreuung",
        "FR": "Soins aux enfants",
        "IT": "Cura dei bambini",
        "NL": "Kids Care",
        "PT": "Cuidados infantis"
      }
    },
    "playground": {
      "iconName": "playground",
      "translation": {
        "EN": "Playground",
        "DE": "Spielplatz",
        "FR": "Terrain de jeux",
        "IT": "Parco giochi",
        "NL": "Speeltuin",
        "PT": "Parque infantil"
      }
    },
    "coffee": {
      "iconName": "coffee",
      "translation": {
        "EN": "Coffee",
        "DE": "Kaffee",
        "FR": "Café",
        "IT": "Caffè",
        "NL": "Koffie",
        "PT": "Café"
      }
    },
    "foodAndDrink": {
      "iconName": "foodAndDrink",
      "translation": {
        "EN": "Food and Drinks",
        "DE": "Essen und Trinken",
        "FR": "Nourriture et boisson",
        "IT": "Cibo e bevande",
        "NL": "Eten en drinken",
        "PT": "Comida e bebida"
      }
    },
    "water": {
      "iconName": "water",
      "translation": {
        "EN": "Water",
        "DE": "Wasser",
        "FR": "Eau",
        "IT": "Acqua",
        "NL": "Water",
        "PT": "Água"
      }
    },
    "vendingMachine": {
      "iconName": "vendingMachine",
      "translation": {
        "EN": "Vending Machine",
        "DE": "Verkaufsautomat",
        "FR": "Distributeur automatique",
        "IT": "Distributore automatico",
        "NL": "Verkoopautomaat",
        "PT": "Máquina de venda automática"
      }
    },
    "kitchen": {
      "iconName": "kitchen",
      "translation": {
        "EN": "Kitchen/Cafeteria/Canteen",
        "DE": "Küche/Cafeteria/Kantine",
        "FR": "Cuisine/Cafétéria/Cantine",
        "IT": "Cucina/Caffetteria/Mensa",
        "NL": "Keuken/Cafetaria/Kantine",
        "PT": "Cozinha/Cafetaria/Cantina"
      }
    },
    "spa": {
      "iconName": "spa",
      "translation": {
        "EN": "Spa/Wellness",
        "DE": "Spa/Wellness",
        "FR": "Spa/Bien-être",
        "IT": "Spa/Benessere",
        "NL": "Spa/Wellness",
        "PT": "Spa/Wellness"
      }
    },
    "tableTennis": {
      "iconName": "tableTennis",
      "translation": {
        "EN": "Table Tennis",
        "DE": "Tischtennis",
        "FR": "Tennis de table",
        "IT": "Tennis da tavolo",
        "NL": "Tafeltennis",
        "PT": "Ténis de mesa"
      }
    },
    "kicker": {
      "iconName": "kicker",
      "translation": {
        "EN": "Kicker",
        "DE": "Kicker",
        "FR": "Kicker",
        "IT": "Calciatore",
        "NL": "Kicker",
        "PT": "Kicker"
      }
    },
    "billard": {
      "iconName": "billiard",
      "translation": {
        "EN": "Billard",
        "DE": "Billard",
        "FR": "Billard",
        "IT": "Billard",
        "NL": "Billard",
        "PT": "Billard"
      }
    },
    "gameCourt": {
      "iconName": "gameCourt",
      "translation": {
        "EN": "Game Court",
        "DE": "Spielfeld",
        "FR": "Terrain de jeu",
        "IT": "Campo da gioco",
        "NL": "Spel rechter",
        "PT": "Campo de jogos"
      }
    },
    "sportsArea": {
      "iconName": "sportsArea",
      "translation": {
        "EN": "Sports Area/Court",
        "DE": "Sportbereich/Feld",
        "FR": "Aire de sport/Court",
        "IT": "Area/Campo sportivo",
        "NL": "Sportterrein/veld",
        "PT": "Espaço Desportivo/Tribunal"
      }
    },
    "gym": {
      "iconName": "gym",
      "translation": {
        "EN": "Gym",
        "DE": "Fitnessstudio",
        "FR": "Centre de fitness",
        "IT": "Centro di fitness",
        "NL": "fitnesscentrum",
        "PT": "Ginásio"
      }
    },
    "showers": {
      "iconName": "showers",
      "translation": {
        "EN": "Shower",
        "DE": "Dusche",
        "FR": "Douche",
        "IT": "Doccia",
        "NL": "Douche",
        "PT": "Chuveiro"
      }
    },
    "multiCarParking": {
      "iconName": "multi_level_car_parking",
      "translation": {
        "EN": "Multi-level Car Parking"
      }
    },
    "locker": {
      "iconName": "locker",
      "translation": {
        "EN": "Locker",
        "DE": "Schließfach",
        "FR": "Casier",
        "IT": "Armadietto",
        "NL": "Kluisje",
        "PT": "Armário"
      }
    }
  };