export const ACTIONSHEET = {
	PLACEHOLDER: 'placeholder',
	BOOKING_OPTIONS_MENU: 'bookingOptionsMenu',
	BOOKING_OPTIONS_MENU_FOR_ATTENDEE: 'bookingOptionsMenuForAttendee',
	NEW_BOOKING_MENU: 'newBookingMenu',
	UNSCHEDULED_INVITATIONS_MENU: 'unscheduledInvitationsMenu',
	VISITOR_MEETING_MENU: 'visitorMeetingMenu',
	QR_CODE_SCANNER_MENU: 'qrCodeScannerMenu',
	SESSION_MENU: 'sessionMenu',
	AGENDA_ITEM: 'agendaItemMenu',
	SHARE_ASSET: 'shareAssetMenu',
	FAVORITES_MENU: 'favoritesMenu',
	TIME_TRACK_EDIT_MENU: 'timeTrackEditMenu',
	CHANGE_REQUEST_MENU: 'changeRequestMenu',
	CHECK_PROPOSAL: 'checkProposal'
};
