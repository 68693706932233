import {createSlice} from '@reduxjs/toolkit';
import {getPersonalizedStats} from '../api';
import {DateTime} from 'luxon';

const slice = createSlice({
	name: 'personalizedStats',
	initialState: {
		personalizedStatsData: {},
		lastUpdated: null,
	},
	reducers: {
		personalizedStatsChanged: (personalizedStats, action) => {
			personalizedStats.personalizedStatsData = action.payload;
			personalizedStats.lastUpdated = DateTime.now().toUTC().toISO();
		},
	},
});

export const {personalizedStatsChanged} = slice.actions;

export default slice.reducer;

export const updatePersonalizedStats = () => async (dispatch, getState) => {
	try {
		const userData = getState().auth.data.userData;
		if (!userData?._id) return;
		const data = (await getPersonalizedStats(userData?._id)).data;
		dispatch({type: personalizedStatsChanged.type, payload: data});
	} catch (error) {
		console.log(error);
	}
};
