import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
	.use(HttpApi)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		backend: {
			loadPath: '/locales/{{lng}}.json',
		},
		detection: {
			order: ['localStorage', 'htmlTag', 'navigator'],
			lookupLocalStorage: 'defaultLanguage',
			caches: ['localStorage'],
		},
		ns: ['language', 'common'],
		defaultNS: 'language',
		fallbackLng: 'en',
		cleanCode: true,
		load: 'languageOnly',
		interpolation: {
			escapeValue: false,
		},
		debug: false,
	});


export default i18n;
