import {createSlice} from '@reduxjs/toolkit';
import {getDataPrivacySettings} from '../api/member';

const slice = createSlice({
	name: 'settings',
	initialState: {
		colleagueFind: {
			checkedList: null,
			ui: {
				colleagueListLoading: true,
			},
		},
		dataPrivacy: {
			isLoading: true,
			bookingDataStorage: false,
		},
		companionApp: {
			authKey: null,
		},
	},
	reducers: {
		startDataPrivacyLoading: (settings) => {
			settings.dataPrivacy.isLoading = true;
		},
		checkedListReceived: (settings, action) => {
			settings.colleagueFind.checkedList = action.payload;
		},
		colleagueListLoadingReceived: (settings, action) => {
			settings.colleagueFind.ui.colleagueListLoading = action.payload;
		},
		bookOnBehalfRoomRedirectChanged: (settings, action) => {
			settings.colleagueFind.bookOnBehalfRoomRedirect = action.payload;
		},
		dataPrivacyDookingDataStorageChanged: (settings, action) => {
			settings.dataPrivacy.bookingDataStorage = action.payload;
			settings.dataPrivacy.isLoading = false;
		},
	},
});

export const {
	colleagueFindSearchEnabledChanged,
	addAttendeeToRoomRedirectChanged,
	checkedListReceived,
	colleagueListLoadingReceived,
	bookOnBehalfRoomRedirectChanged,
	dataPrivacyDookingDataStorageChanged,
	startDataPrivacyLoading,
} = slice.actions;

export default slice.reducer;

export const updateCheckedList = (list) => (dispatch) => {
	dispatch({type: checkedListReceived.type, payload: list});
};

export const processItemInList =
	(colleague, singleOption = false) =>
	(dispatch, getState) => {
		const checkedList = getState().settings.colleagueFind.checkedList;
		if (!checkedList) {
			dispatch({type: checkedListReceived.type, payload: [colleague]});
			return;
		}
		if (singleOption) {
			dispatch({type: checkedListReceived.type, payload: [colleague]});
			return;
		}
		let currentList = checkedList.slice();
		const index = currentList.findIndex((item) => item.id === colleague.id);

		if (index > -1) {
			currentList.splice(index, 1);
		} else if (typeof colleague === 'object') {
			currentList = [...currentList, colleague];
		}

		dispatch({type: checkedListReceived.type, payload: currentList});
	};

export const updateColleagueListLoading = (enable) => (dispatch, getState) => {
	if (getState().settings.colleagueFind.ui.colleagueListLoading === enable) return;
	dispatch({type: colleagueListLoadingReceived.type, payload: enable});
};

export const updateColleagueListRedirect = (redirect) => (dispatch, getState) => {
	if (redirect === 'addAttendeeToRoom')
		dispatch({
			type: addAttendeeToRoomRedirectChanged.type,
			payload: true,
		});

	if (!redirect)
		dispatch({
			type: addAttendeeToRoomRedirectChanged.type,
			payload: false,
		});
};

export const updateBookOnBehalfRoomRedirect = (payload) => (dispatch, getState) => {
	dispatch({
		type: bookOnBehalfRoomRedirectChanged.type,
		payload: payload,
	});
};

export const dataPrivacyBookingDataStorageUpdate = () => async (dispatch, getState) => {
	dispatch(startDataPrivacyLoading());
	const userData = getState().auth.data.userData;
	if (!userData?._id) return;

	const data = (await getDataPrivacySettings(userData?._id)).data;
	dispatch({
		type: dataPrivacyDookingDataStorageChanged.type,
		payload: data.enabled,
	});
};
