import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useHistory } from 'react-router-dom';

import { ROUTES } from '../../../constants/routes';
import { updateCheckedList } from '../../../store/settings';

import ColleagueItem from '../../ProfilePage/ColleagueList/ColleagueItem';
import Icon from '../../Common/Icon';
import { getColleaguesStatus } from '../../../api/member';
import AddPeopleSection from '../../Common/AddPeopleSection';
import { updateConfirmationCard } from '../../../store/cards';
import { confirmActionsMap } from '../../Cards/Common/ConfirmAction/confirmActions';
import { Typography } from '../../Common/Typography';

function Proposals(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const selectedStartTime = useSelector((state) => state.entitiesBooking.selectedStartTime);
    const selectedEndTime = useSelector((state) => state.entitiesBooking.selectedEndTime);
    const userData = useSelector((state) => state.auth.data.userData);
    const attendeeList = useSelector((state) => state.entitiesBooking.attendeeList);
    const hasMSOutlook = useSelector((state) => state.bookings.detailsBookingData.booking.microsoft.onlineMeeting.joinUrl);
    const hasNewProposal = attendeeList?.some(item => item?.proposedNewTime !== undefined);
    console.log("attendeeList", attendeeList);
    const renderFilteredAttendeeList = () => {
        return (
            <div className={'proposals-list'}>
                {attendeeList?.map((item, index) => (
                    (item?.proposedNewTime &&
                        <ColleagueItem
                            listLength={attendeeList?.length}
                            index={index}
                            key={item._id}
                            data={item?.member ? item?.member : item}
                            enableCheck={false}
                            checkedIn={undefined}
                            invitationResponse={undefined}
                            hasRoomTypeMSOutlook={hasMSOutlook}
                            hasNewProposal={item?.proposedNewTime}
                            proposedNewTime={item?.proposedNewTime}
                        />
                    ))
                )}
            </div>

        );
    };
    const getSectionContent = () => {
        return (
            <>
                 <p className="title">{t('proposals.title')}</p>
                 <p className="subtitle">{t('proposals.subtitle')}</p>
            </>
        )

    };
    const renderSectionWithContent = () => {
        if (hasMSOutlook && hasNewProposal) {
            return (
                <div className="proposals-section">
                    {getSectionContent()}
                    {renderFilteredAttendeeList()}
                </div>
            );
        }
        return <></>
    };
    return (
        <>
            {renderSectionWithContent()}
        </>
    );
}

export default Proposals;
