import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ROUTES } from '../../constants/routes';
import { updateConfirmationCard } from '../../store/cards';
import { confirmActionsMap } from '../Cards/Common/ConfirmAction/confirmActions';
import useCustomNavigation from '../../hooks/useCustomNavigation';
import {
	addAvailableAreasWithEntities,
	changeSelectedTeakType,
	changeShowEntityList,
	showEntityOnMap,
} from '../../store/bookingProcess/entitiesBooking';
import { BOTTOM_MAP_CARDS } from '../../constants/bottomCardTypes';

const ShareAssetMenu = (props) => {
	const dispatch = useDispatch();
	const userData = useSelector((state) => state.auth.data.userData);
	const organizationData = useSelector((state) => state.auth.data.organizationData);
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);
	const data = useSelector((state) => state.auth.data.actionSheetData);
	const teakEntity = data?.teakEntity;
	const isTeakEntityShared = teakEntity?.teakSharedOptions?.whitelist?.members?.list
		? teakEntity.teakSharedOptions.whitelist.members.list.filter((whitelistEntry) => whitelistEntry.member?._id !== userData._id)?.length > 0
		: false;
	const navigation = useCustomNavigation();

	function stopSharing() {
		dispatch(
			updateConfirmationCard(
				confirmActionsMap.STOP_SHARING_ASSET,
				{
					headerKey: 'ownAssets.stopSharing.header',
					infoKey: 'ownAssets.stopSharing.info',
					confirmButtonKey: 'ownAssets.stopSharing.confirm',
				},
				{
					teakEntity,
					organizationData,
					selectedOffice,
					email: userData?.email,
				},
			),
		);
	}

	function share() {
		navigation.push(ROUTES.PROFILE_PAGE + ROUTES.SHARE_ASSET, { teakEntity });
	}

	function showItemOnMap() {
		props.closeActionSheet();
		navigation.push(ROUTES.BOOKING_PAGE);
		dispatch(changeSelectedTeakType(undefined, teakEntity.teakType));
		dispatch(
			addAvailableAreasWithEntities((areasWithAvailableEntities, availableEntitiesFromAreaListFull) => {
				dispatch(changeShowEntityList(true));
				const entity = availableEntitiesFromAreaListFull?.find((entity) => entity?._id === teakEntity?._id) ?? teakEntity;
				dispatch(showEntityOnMap(entity, BOTTOM_MAP_CARDS.entityData, areasWithAvailableEntities, availableEntitiesFromAreaListFull));
			}),
		);
	}
	return (
		<>
			{isTeakEntityShared ? (
				<>
					<div className="action-sheet-option" onClick={share}>
						{t('ownAssets.actionMenu.edit')}
					</div>
					<div className="action-sheet-option" onClick={stopSharing}>
						{t('ownAssets.actionMenu.stopSharing')}
					</div>
				</>
			) : (
				<div className="action-sheet-option" onClick={share}>
					{t('ownAssets.actionMenu.share')}
				</div>
			)}
			<div className="action-sheet-option" onClick={showItemOnMap}>
				{t('ownAssets.actionMenu.showOnMap')}
			</div>
		</>
	);
};

export default ShareAssetMenu;
