import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BOTTOM_MAP_CARDS } from '../../constants/bottomCardTypes';
import { ROUTES } from '../../constants/routes';

import {
	addAvailableAreasWithEntities,
	changeSelectedTeakType,
	changeShowEntityList,
	showEntityOnMap,
	updateSelectedEntity,
} from '../../store/bookingProcess/entitiesBooking';
import { enableCard } from '../../store/cards';
import { getTeakEntitiesFull } from '../../functions/getTeakEntitiesFull';
import { getAreaNameBasedOnId, processFavoriteAspenItemObject, processTeakEntityObjToFavorites } from '../../store/auth';
import { processTeakEntityToFavorites } from '../../api';
import { changeArea, changeDesk, changeRoom } from '../../store/reportProblem';
import { TEAK_TYPE_NAMES } from '../../constants/teakTypeNames';
import useCustomNavigation from '../../hooks/useCustomNavigation';

const FavoritesMenu = (props) => {
	const dispatch = useDispatch();
	const navigation = useCustomNavigation();
	const authData = useSelector((state) => state.auth.data);
	const { userData, areas, featureTranslations, teakFeatures, actionSheetData } = authData;
	const teakEntityData = actionSheetData;
	const teakTypeObj = teakEntityData?.teakType;
	const history = useHistory();
	const areaId = teakEntityData?.area?._id ? teakEntityData?.area._id : teakEntityData?.area;
	const areaName = useSelector(getAreaNameBasedOnId(areaId)) || teakEntityData?.area?.name;
	const handleShowOnMap = () => {
		props.closeActionSheet();
		dispatch(changeShowEntityList(true));
		navigation.push(ROUTES.BOOKING_PAGE);
		dispatch(changeSelectedTeakType(undefined, teakEntityData.teakType?._id));
		dispatch(
			addAvailableAreasWithEntities((areasWithAvailableEntities, availableEntitiesFromAreaListFull) => {
				const entity = availableEntitiesFromAreaListFull?.find((teakEntity) => teakEntity?._id === teakEntityData?._id);
				dispatch(showEntityOnMap(entity, BOTTOM_MAP_CARDS.entityData, areasWithAvailableEntities, availableEntitiesFromAreaListFull));
			}),
		);
	};
	const handleBook = () => {
		//if favorite item clicked is a service item, navigate to favorites service booking page
		if (teakEntityData?.aspenType) {
			history.push(ROUTES.FAVORITES_BOOKING_PAGE, { defaultToAspen: true });
			return;
		}
		const entityWithData = getTeakEntitiesFull(areas, featureTranslations, userData, teakFeatures, [teakEntityData])[0];
		dispatch(updateSelectedEntity(entityWithData));
		dispatch(changeSelectedTeakType(undefined, teakEntityData?.teakType?._id));
		history.push(ROUTES.BOOKING_CONFIRM, { showCalendarFirst: true });
	};
	const handleUnfavorite = () => {
		//if favorite item clickes is a service item, use another api call
		if (teakEntityData?.aspenType) {
			dispatch(processFavoriteAspenItemObject(teakEntityData));
			return;
		}
		processTeakEntityToFavorites(teakEntityData?._id);
		dispatch(processTeakEntityObjToFavorites(teakEntityData));
	};
	const handleReportProblem = () => {
		dispatch(enableCard(false));
		if (teakTypeObj?.__t === TEAK_TYPE_NAMES.DESK) {
			dispatch(changeDesk(teakEntityData._id, teakEntityData?.name));
			dispatch(changeArea(areaId, areaName));
			history.push(ROUTES.REPORT_PROBLEM + ROUTES.DESK_PROBLEM_DETAILS);
		} else if (teakTypeObj?.__t === TEAK_TYPE_NAMES.ROOM) {
			dispatch(changeRoom(teakEntityData._id, teakEntityData?.name));
			dispatch(changeArea(areaId, areaName));
			history.push(ROUTES.REPORT_PROBLEM + ROUTES.MEETING_ROOM_PROBLEM_DETAILS);
		} else {
			dispatch(changeArea(areaId, areaName));
			history.push(ROUTES.REPORT_PROBLEM + ROUTES.OTHER_PROBLEM);
		}
	};

	return (
		<>
			<div className="action-sheet-option" onClick={handleBook}>
				{t('common.bookNow')}
			</div>
			<div className="action-sheet-option" onClick={handleUnfavorite}>
				{t('favorites.deleteFromFavorites')}
			</div>
			{!teakEntityData?.aspenType && (
				<div className="action-sheet-option" onClick={handleShowOnMap}>
					{t('common.showOnMap')}
				</div>
			)}
			<div className="action-sheet-option" onClick={handleReportProblem}>
				{t('common.reportProblem')}
			</div>
		</>
	);
};

export default FavoritesMenu;
