import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {enableBottomMenu} from '../../store/auth';
import {ROUTES} from '../../constants/routes';
import {useHistory} from 'react-router';
import AddCertificate from '../Common/AddCertificate';
import SecondaryHeader from '../Common/Header/SecondaryHeader';

function NoCertificateForCheckin(props) {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const selectedOffice = useSelector((state) => state.auth.data.selectedOffice);

	const closePage = () => {
		history.push(ROUTES.DEFAULT);
	};

	useEffect(() => {
		dispatch(enableBottomMenu(false));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="noCertificateForCheckin-page">
			<SecondaryHeader backButtonClickHandler={closePage} headerTranslationKey={t('common.checkin')} />
			<div className="checkInQuestionnaire-page-content">
				<div className="checkInQuestionnaire-page-info">
					<p>{t('profile.message.noCertificate')}</p>
					<p className="bold-text">
						{selectedOffice?.ceylon?.rule === '2G' ? t('profile.message.certificateInfo2G') : t('profile.message.certificateInfo')}
					</p>
				</div>

				<AddCertificate redirectToDashboard={true} />
			</div>
		</div>
	);
}

export default NoCertificateForCheckin;
