import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {ROUTES} from './../constants/routes';
import {useSelector} from 'react-redux';

export default function useBadgeNotification() {
	const history = useHistory();
	const badgesData = useSelector((state) => state.badges.badgesData);
	const eventBadgesData = useSelector((state) => state.badges.eventBadgesData);
	const selectedEvent = useSelector((state) => state.events.selectedEvent);
	const attendeeData = useSelector((state) => state.events.attendeeData);
	const bookingDataStorage = useSelector((state) => state.settings.dataPrivacy.bookingDataStorage);

	const showEventAchievements = selectedEvent?.achievements
		? Object.values(selectedEvent?.achievements).some((achievement) => achievement.enabled)
		: false;

	useEffect(() => {
		if (showEventAchievements && selectedEvent) {
			const achievementsMap = new Map(Object.entries(selectedEvent?.achievements));
			eventBadgesData?.find((badge) => badge.notification && achievementsMap.get(badge.name)?.enabled && goToNotification(badge));
		} else if (bookingDataStorage) {
			badgesData?.find((badge) => badge.notification && goToNotification(badge));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [badgesData, eventBadgesData]);

	function goToNotification(badge) {
		history.push(ROUTES.PROFILE_PAGE + ROUTES.UNLOCK_NOTIFICATION, {badge, wengeAttendee: attendeeData?._id, wengeEvent: selectedEvent?._id});
	}
}
