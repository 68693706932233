import {useTranslation} from 'react-i18next';
import Select from 'react-select';
import colorBasedOnTheme from '../../../functions/colorBasedOnTheme';
/**
 *
 * @param {Object} options = options shown in the dropdown
 * @param {Object} value = current value selected -> needs to be one of the options
 * @param {string} labelKey = translation key for label
 * @param {boolean} isSearchable = if the dropdown is searchable
 * @param {function} onChange = callback when a new option is selected
 * @param {string} placeholder = translation key for the placeholder => only if there is no preselected value
 * @param {boolean} disabled = if the dropdown is disabled or not
 * @param {id} id = id added to the wrapper of the dropdown
 * @param {string} className = className added to the wrapper of the dropdown
 * @param {boolean} required = if the * is shown after label
 * @returns
 */
function Dropdown({
	options,
	value,
	labelKey,
	isSearchable = false,
	onChange,
	placeholder = '',
	disabled = false,
	id = '',
	className = '',
	required = false,
}) {
	const {t} = useTranslation();
	return (
		<div className={`dropdown-wrapper ${className}`} id={id}>
			{labelKey && (
				<label className="dropdown-label">
					{t(labelKey)}
					{required && '*'}
				</label>
			)}
			<Select
				id={'dropdown-input'}
				className="dropdown-input"
				options={options}
				value={value}
				isSearchable={isSearchable}
				onChange={onChange}
				placeholder={placeholder}
				controlWidth={'100%'}
				menuWidth={'100%'}
				containerWidth={'100%'}
				containerMargin={'0 auto'}
				noOptionsMessage={() => t('common.noOptionsMessage')}
				isDisabled={disabled}
				styles={dropdownStyle}
			/>
		</div>
	);
}

export default Dropdown;

const colors = colorBasedOnTheme();
// overriding default component according to current design system
const dropdownStyle = {
	indicatorContainer: (styles) => ({
		...styles,
		padding: '6px 12px',
	}),
	indicatorsContainer: (styles) => ({
		...styles,
		position: 'absolute',
		right: 0,
		'pointer-events': 'none',
	}),

	valueContainer: (styles) => ({
		...styles,
		paddingRight: '35px',
		position: 'absolute',
		width: '100%',
		zIndex: '0',
	}),

	container: (styles) => ({
		...styles,
	}),

	control: (styles, state) => ({
		...styles,
		borderRadius: '5px',
		backgroundColor: state.isDisabled ? '#ececec' : 'white',
		width: '100%',
		position: 'relative',
		minHeight: '38px',
		':hover': {
			...styles[':hover'],
			outline: '0!important',
			borderColor: 'black',
			boxShadow: 'none',
		},
		':focus': {
			...styles[':focus'],
			outline: '0!important',
			borderColor: 'black',
			boxShadow: 'none',
		},
		':active': {
			...styles[':active'],
			outline: '0!important',
			borderColor: 'black',
			boxShadow: 'none',
		},
	}),

	menu: (provided, state) => ({
		...provided,
		padding: 0,
		borderRadius: '10px',
		zIndex: '10',
		width: '100%',
	}),

	menuList: (provided, state) => ({
		...provided,
		padding: 0,
		marginTop: 0,
		marginBottom: 0,
		maxHeight: '220px',
		overflowY: 'auto',
		borderRadius: '10px',
	}),

	dropdownIndicator: (provided, state) => ({
		...provided,
		transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
		padding: '8px 12px',
	}),
	indicatorSeparator: (provided, state) => ({
		...provided,
		display: 'none',
	}),
	option: (styles, {isDisabled, isFocused, isSelected}) => {
		const whiteColor = '#fff';
		const blackColor = '#000';
		const greyColor = colors.colorNeutral50;

		return {
			...styles,

			backgroundColor: isDisabled ? null : isSelected ? whiteColor : isFocused ? whiteColor : null,

			color: isDisabled ? greyColor : isSelected ? blackColor : greyColor,

			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled && (isSelected ? whiteColor : whiteColor),
			},

			':hover': {
				...styles[':hover'],
				backgroundColor: !isDisabled && (isSelected ? colors.colorNeutral95 : colors.colorNeutral95),
			},
		};
	},
};
