import axios from 'axios';
import apiUrl from './index';

const notificationsUrls = {
	notifications: '/notifications',
	read: '/read',
	remove: '/remove',
};

export async function getAllNotifications(memberId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + notificationsUrls.notifications;

	return axios({
		method: 'get',
		url: requestUrl,
	});
}

export async function readNotification(notificationId, memberId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + notificationsUrls.notifications + '/' + notificationId + notificationsUrls.read;

	return axios({
		method: 'put',
		url: requestUrl,
	});
}

export async function deleteNotification(notificationIds, memberId) {
	const satelliteUrl = window.localStorage.getItem('satelliteUrl');
	const hostUrl = satelliteUrl ? satelliteUrl : process.env.REACT_APP_ENGINE_HOST_URL;
	let requestUrl = hostUrl + apiUrl.path.member + '/' + memberId + notificationsUrls.notifications + notificationsUrls.remove;

	return axios({
		method: 'delete',
		url: requestUrl,
		data: {
			notifications: notificationIds,
		},
	});
}
